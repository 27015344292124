var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: _vm.componentId },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": ["anti-shift", _vm.componentId],
            scrollable: "",
          },
          on: {
            hidden: function ($event) {
              return _vm.$emit("update:show", false)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c("h5", { staticClass: "custom-modal-title" }, [
                    _vm._v(" " + _vm._s(_vm.labelTitle) + " "),
                  ]),
                  _vm.exists
                    ? [
                        _c(
                          "div",
                          { staticClass: "history-button lock-container" },
                          [
                            _vm.isLockVisible
                              ? [
                                  _c("div", { staticClass: "ml-1 mr-1" }, [
                                    _vm._v(_vm._s(_vm.$t("lock"))),
                                  ]),
                                  _c("b-form-checkbox", {
                                    attrs: {
                                      disabled: _vm.isLockReadOnly,
                                      switch: "",
                                    },
                                    model: {
                                      value: _vm.customer.readOnly,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.customer, "readOnly", $$v)
                                      },
                                      expression: "customer.readOnly",
                                    },
                                  }),
                                ]
                              : _vm._e(),
                            _c(
                              "b-button",
                              {
                                attrs: { variant: "secondary", size: "sm" },
                                on: {
                                  click: function ($event) {
                                    _vm.state.historyShow = true
                                  },
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "clock-rotate-left"] },
                                }),
                                _vm._v(
                                  " " + _vm._s(_vm.$t("button.history")) + " "
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "close custom-modal-close",
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v("×")]
                  ),
                ]
              },
            },
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  !_vm.isAccessDenied && (_vm.canEdit() || !_vm.exists)
                    ? [
                        _vm.state.isSubmitting
                          ? _c(
                              "b-button",
                              {
                                attrs: {
                                  size: "sm",
                                  variant: "success",
                                  disabled: "",
                                },
                              },
                              [
                                _c("b-spinner", {
                                  attrs: { small: "", type: "grow" },
                                }),
                                _vm._v(_vm._s(_vm.$t("button.saving")) + " "),
                              ],
                              1
                            )
                          : _c(
                              "b-button",
                              {
                                attrs: {
                                  size: "sm",
                                  variant: "success",
                                  disabled: _vm.disableOk,
                                },
                                on: { click: _vm.ok },
                              },
                              [_vm._v(_vm._s(_vm.$t("button.ok")))]
                            ),
                      ]
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.modalShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "modalShow", $$v)
            },
            expression: "state.modalShow",
          },
        },
        [
          _vm.isAccessDenied
            ? [
                _c("div", { staticClass: "modal-message-overlay" }, [
                  _c("span", { staticClass: "grid-overlay" }, [
                    _vm._v(
                      _vm._s(
                        _vm.restrictedRequiredField != null
                          ? _vm.$t(
                              "entity_selector.error.insufficient_permission_to_add_entity_with_reason",
                              [
                                _vm.$t("customer.title").toLowerCase(),
                                _vm.restrictedRequiredField,
                              ]
                            )
                          : _vm.$t(
                              "entity_selector.error.insufficient_permission_to_add_entity",
                              [_vm.$t("customer.title").toLowerCase()]
                            )
                      )
                    ),
                  ]),
                ]),
              ]
            : [
                _vm.isAvatarBannerVisible
                  ? _c("AvatarBanner", {
                      attrs: {
                        readOnly: _vm.isAvatarBannerReadOnly,
                        baseAvatarIcon: ["fad", "user-tie"],
                      },
                      on: { status: _vm.avatarBannerStatus },
                      model: {
                        value: _vm.avatarBanner,
                        callback: function ($$v) {
                          _vm.avatarBanner = $$v
                        },
                        expression: "avatarBanner",
                      },
                    })
                  : _vm._e(),
                _c(
                  "b-alert",
                  {
                    attrs: {
                      variant: _vm.successMsg ? "success" : "danger",
                      dismissible: "",
                      show: _vm.showError,
                    },
                    on: { dismissed: _vm.dismissAlert },
                  },
                  [
                    _vm.successMsg != null
                      ? _c("font-awesome-icon", {
                          attrs: { icon: ["far", "check"] },
                        })
                      : _c("font-awesome-icon", {
                          attrs: { icon: ["fas", "triangle-exclamation"] },
                        }),
                    _vm._v(
                      "   " +
                        _vm._s(_vm.successMsg ? _vm.successMsg : _vm.alertMsg) +
                        " "
                    ),
                    _c(
                      "ul",
                      {
                        staticClass: "mb-0",
                        attrs: { show: _vm.showErrorDetail },
                      },
                      [
                        _vm._l(_vm.alertMsgDetails, function (item, index) {
                          return [
                            _c("li", { key: index }, [_vm._v(_vm._s(item))]),
                          ]
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "container pl-0" },
                  [
                    _c(
                      "b-row",
                      [
                        _vm.isNameVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("customer.field.name"),
                                      "label-for": "name",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "name",
                                            type: "text",
                                            "data-vv-as": _vm.$t(
                                              "customer.field.name"
                                            ),
                                            "data-vv-name": "customer.name",
                                            maxlength: _vm.maxNameLength,
                                            "data-vv-delay": "500",
                                            readonly: _vm.isNameReadOnly,
                                            trim: "",
                                          },
                                          model: {
                                            value: _vm.customer.name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.customer,
                                                "name",
                                                $$v
                                              )
                                            },
                                            expression: "customer.name",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        staticClass:
                                          "alert-danger form-field-alert",
                                        class: { "d-block": _vm.showNameError },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: ["far", "circle-exclamation"],
                                          },
                                        }),
                                        _vm._v(
                                          "  " +
                                            _vm._s(
                                              _vm.errors.first("customer.name")
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["name"] != null
                          ? _vm._l(
                              _vm.customFieldMap["name"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "name" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.customer[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.customer,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "customer[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isIdentifierVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("field.identifier"),
                                      "label-for": "identifier",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "identifier",
                                            type: "text",
                                            "data-vv-as":
                                              _vm.$t("field.identifier"),
                                            "data-vv-name":
                                              "customer.identifier",
                                            maxlength: _vm.maxIdentifierLength,
                                            readonly: _vm.isIdentifierReadOnly,
                                            trim: "",
                                          },
                                          model: {
                                            value: _vm.customer.identifier,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.customer,
                                                "identifier",
                                                $$v
                                              )
                                            },
                                            expression: "customer.identifier",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["identifier"] != null
                          ? _vm._l(
                              _vm.customFieldMap["identifier"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "identifier" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.customer[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.customer,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "customer[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.customFieldMap["default"] != null
                          ? _vm._l(
                              _vm.customFieldMap["default"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.customer[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.customer,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "customer[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isNickNameVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("customer.field.nickName"),
                                      "label-for": "nickName",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "nickName",
                                            type: "text",
                                            readonly: _vm.isNickNameReadOnly,
                                            trim: "",
                                          },
                                          model: {
                                            value: _vm.customer.nickName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.customer,
                                                "nickName",
                                                $$v
                                              )
                                            },
                                            expression: "customer.nickName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isColorVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "color-container" },
                                  [
                                    _c("Color", {
                                      attrs: {
                                        disabled: _vm.isColorReadOnly,
                                        update: _vm.updatedColor,
                                      },
                                      model: {
                                        value: _vm.customer.color,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.customer, "color", $$v)
                                        },
                                        expression: "customer.color",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.customFieldMap["nickName"] != null
                          ? _vm._l(
                              _vm.customFieldMap["nickName"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "nickName" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.customer[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.customer,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "customer[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _vm.isDescriptionVisible
                  ? _c(
                      "b-form-group",
                      {
                        attrs: {
                          label: _vm.$t("customer.field.description"),
                          "label-for": "description",
                        },
                      },
                      [
                        _c("b-form-textarea", {
                          attrs: {
                            id: "description",
                            placeholder: _vm.isDescriptionReadOnly
                              ? ""
                              : _vm.$t("customer.placeholder.description"),
                            "max-rows": 6,
                            readonly: _vm.isDescriptionReadOnly,
                            trim: "",
                            rows: 3,
                          },
                          model: {
                            value: _vm.customer.description,
                            callback: function ($$v) {
                              _vm.$set(_vm.customer, "description", $$v)
                            },
                            expression: "customer.description",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.customFieldMap["description"] != null
                  ? [
                      _c(
                        "div",
                        { staticClass: "container pl-0" },
                        [
                          _c(
                            "b-row",
                            _vm._l(
                              _vm.customFieldMap["description"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "description" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.customer[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.customer,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "customer[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
                _vm.isTagVisible
                  ? _c(
                      "b-form-group",
                      [
                        _c("TagList", {
                          attrs: {
                            holderId: _vm.id,
                            tags: _vm.tags,
                            readOnly: _vm.isTagReadOnly,
                          },
                          on: { modified: _vm.tagsModified },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.customFieldMap["tags"] != null
                  ? [
                      _c(
                        "div",
                        { staticClass: "container pl-0" },
                        [
                          _c(
                            "b-row",
                            _vm._l(
                              _vm.customFieldMap["tags"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "tags" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.customer[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.customer,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "customer[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
                _vm.isLocationVisible
                  ? _c(
                      "b-form-group",
                      [
                        _c("label", { staticClass: "mr-1" }, [
                          _vm._v(_vm._s(_vm.$t("customer.field.location"))),
                        ]),
                        !_vm.isLocationReadOnly
                          ? _c(
                              "button",
                              {
                                staticClass: "btn-action",
                                attrs: { id: "LOCATION_ADD" },
                                on: { click: _vm.locSelectorToggle },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "plus"] },
                                }),
                                _c("b-popover", {
                                  attrs: {
                                    target: "LOCATION_ADD",
                                    placement: "top",
                                    triggers: "hover",
                                    content: _vm.$t(
                                      "customer.button.location_add"
                                    ),
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("BadgeGroup", {
                          attrs: { readOnly: _vm.isLocationReadOnly },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var item = ref.item
                                  var index = ref.index
                                  return [
                                    _c("Badge", {
                                      key: index,
                                      attrs: {
                                        text: item.name,
                                        variant: "primary",
                                        pillable: !!item.pillable,
                                        readOnly: _vm.isLocationReadOnly,
                                      },
                                      on: {
                                        badgeRemove: function ($event) {
                                          return _vm.locationBadgeRemove(index)
                                        },
                                        badgeClick: function ($event) {
                                          return _vm.locationBadgeClick(
                                            item.uuId
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1048688413
                          ),
                          model: {
                            value: _vm.locations,
                            callback: function ($$v) {
                              _vm.locations = $$v
                            },
                            expression: "locations",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.customFieldMap["location"] != null
                  ? [
                      _c(
                        "div",
                        { staticClass: "container pl-0" },
                        [
                          _c(
                            "b-row",
                            _vm._l(
                              _vm.customFieldMap["location"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "location" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.customer[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.customer,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "customer[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
                _vm.isWebsitesVisible
                  ? _c(
                      "b-form-group",
                      { staticClass: "mb-0" },
                      [
                        _c("label", { staticClass: "mr-1" }, [
                          _vm._v(_vm._s(_vm.$t("customer.field.websites"))),
                        ]),
                        _vm._l(
                          _vm.customer.websites,
                          function (website, index) {
                            return _c(
                              "b-row",
                              { key: index },
                              [
                                _c(
                                  "b-col",
                                  { attrs: { lg: "" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        _c(
                                          "b-input-group",
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "text",
                                                id: "website" + index,
                                                readonly:
                                                  _vm.isWebsitesReadOnly,
                                                trim: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.customer.websites[index],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.customer.websites,
                                                    index,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "customer.websites[index]",
                                              },
                                            }),
                                            !_vm.isWebsitesReadOnly
                                              ? [
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          attrs: {
                                                            id:
                                                              "WEBSITE_BTN_ADD_" +
                                                              _vm.id +
                                                              "_" +
                                                              index,
                                                            variant:
                                                              "secondary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.fieldItemAdd(
                                                                "websites",
                                                                index,
                                                                null
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: [
                                                                  "far",
                                                                  "plus",
                                                                ],
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              "WEBSITE_BTN_ADD_" +
                                                              _vm.id +
                                                              "_" +
                                                              index,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "button.add"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          attrs: {
                                                            id:
                                                              "WEBSITE_BTN_REMOVE_" +
                                                              _vm.id +
                                                              "_" +
                                                              index,
                                                            variant:
                                                              "secondary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.fieldItemRemove(
                                                                "websites",
                                                                index,
                                                                null
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: [
                                                                  "far",
                                                                  "trash-can",
                                                                ],
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              "WEBSITE_BTN_REMOVE_" +
                                                              _vm.id +
                                                              "_" +
                                                              index,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "button.remove"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.customFieldMap["websites"] != null
                  ? [
                      _c(
                        "div",
                        { staticClass: "container pl-0" },
                        [
                          _c(
                            "b-row",
                            _vm._l(
                              _vm.customFieldMap["websites"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "websites" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.customer[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.customer,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "customer[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
                _vm.isSocialsVisible
                  ? _c(
                      "b-form-group",
                      { staticClass: "mb-0" },
                      [
                        _c("label", { staticClass: "mr-1" }, [
                          _vm._v(_vm._s(_vm.$t("customer.field.socials"))),
                        ]),
                        _vm._l(_vm.customer.socials, function (social, index) {
                          return _c(
                            "b-row",
                            { key: index },
                            [
                              _c(
                                "b-col",
                                { attrs: { lg: "" } },
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      _c(
                                        "b-input-group",
                                        [
                                          !_vm.isSocialsReadOnly
                                            ? _c(
                                                "b-input-group-prepend",
                                                [
                                                  _c(
                                                    "b-form-select",
                                                    {
                                                      attrs: {
                                                        options:
                                                          _vm.optionSocials,
                                                        disabled:
                                                          _vm.isSocialsReadOnly,
                                                      },
                                                      model: {
                                                        value: social.kind,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            social,
                                                            "kind",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "social.kind",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "first" },
                                                        [
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                disabled: "",
                                                              },
                                                              domProps: {
                                                                value: null,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "customer.select_socialnet_type"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c("b-form-input", {
                                            attrs: {
                                              type: "text",
                                              readonly: _vm.isSocialsReadOnly,
                                              trim: "",
                                            },
                                            model: {
                                              value: social.data,
                                              callback: function ($$v) {
                                                _vm.$set(social, "data", $$v)
                                              },
                                              expression: "social.data",
                                            },
                                          }),
                                          !_vm.isSocialsReadOnly
                                            ? [
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        attrs: {
                                                          id:
                                                            "SOCIAL_BTN_ADD_" +
                                                            _vm.id +
                                                            "_" +
                                                            index,
                                                          variant: "secondary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.fieldItemAdd(
                                                              "socials",
                                                              index,
                                                              {
                                                                kind: null,
                                                                data: null,
                                                              }
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "font-awesome-icon",
                                                          {
                                                            attrs: {
                                                              icon: [
                                                                "far",
                                                                "plus",
                                                              ],
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-popover",
                                                      {
                                                        attrs: {
                                                          target:
                                                            "SOCIAL_BTN_ADD_" +
                                                            _vm.id +
                                                            "_" +
                                                            index,
                                                          triggers: "hover",
                                                          placement: "top",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "button.add"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        attrs: {
                                                          id:
                                                            "SOCIAL_BTN_REMOVE_" +
                                                            _vm.id +
                                                            "_" +
                                                            index,
                                                          variant: "secondary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.fieldItemRemove(
                                                              "socials",
                                                              index,
                                                              {
                                                                kind: null,
                                                                data: null,
                                                              }
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "font-awesome-icon",
                                                          {
                                                            attrs: {
                                                              icon: [
                                                                "far",
                                                                "trash-can",
                                                              ],
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-popover",
                                                      {
                                                        attrs: {
                                                          target:
                                                            "SOCIAL_BTN_REMOVE_" +
                                                            _vm.id +
                                                            "_" +
                                                            index,
                                                          triggers: "hover",
                                                          placement: "top",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "button.remove"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.customFieldMap["socials"] != null
                  ? [
                      _c(
                        "div",
                        { staticClass: "container pl-0" },
                        [
                          _c(
                            "b-row",
                            _vm._l(
                              _vm.customFieldMap["socials"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "socials" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.customer[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.customer,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "customer[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
                _vm.isContactVisible
                  ? _c(
                      "b-form-group",
                      [
                        _c("ContactList", {
                          attrs: {
                            holderId: _vm.id,
                            contacts: _vm.contacts,
                            readOnly: _vm.isContactReadOnly,
                          },
                          on: { success: _vm.contactSuccess },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.customFieldMap["contacts"] != null
                  ? [
                      _c(
                        "div",
                        { staticClass: "container pl-0" },
                        [
                          _c(
                            "b-row",
                            _vm._l(
                              _vm.customFieldMap["contacts"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "contacts" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.customer[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.customer,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "customer[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
                _vm.isStorageFileVisible
                  ? _c(
                      "b-form-group",
                      [
                        _c("label", { staticClass: "mr-1 mt-1" }, [
                          _vm._v(_vm._s(_vm.$t("customer.field.files"))),
                        ]),
                        !_vm.isStorageFileReadOnly
                          ? _c(
                              "button",
                              {
                                staticClass: "btn-action",
                                attrs: { id: "FILE_ADD" },
                                on: { click: _vm.fileSelectorToggle },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "plus"] },
                                }),
                                _c("b-popover", {
                                  attrs: {
                                    target: "FILE_ADD",
                                    placement: "top",
                                    triggers: "hover",
                                    content: _vm.$t("customer.button.file_add"),
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("BadgeGroup", {
                          attrs: { readOnly: _vm.isStorageFileReadOnly },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var item = ref.item
                                  var index = ref.index
                                  return [
                                    _c("Badge", {
                                      key: index,
                                      attrs: {
                                        text: _vm.labelFilename(
                                          item.name,
                                          item.type
                                        ),
                                        variant: "primary",
                                        pillable: !!item.pillable,
                                        readOnly: _vm.isStorageFileReadOnly,
                                        enableClickWhenReadOnly: "",
                                      },
                                      on: {
                                        badgeRemove: function ($event) {
                                          return _vm.fileBadgeRemove(index)
                                        },
                                        badgeClick: function ($event) {
                                          return _vm.fileBadgeClick(item)
                                        },
                                      },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1579555038
                          ),
                          model: {
                            value: _vm.files,
                            callback: function ($$v) {
                              _vm.files = $$v
                            },
                            expression: "files",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.customFieldMap["files"] != null
                  ? [
                      _c(
                        "div",
                        { staticClass: "container pl-0" },
                        [
                          _c(
                            "b-row",
                            _vm._l(
                              _vm.customFieldMap["files"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "files" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.customer[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.customer,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "customer[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
                _vm.isNoteVisible
                  ? _c(
                      "b-form-group",
                      [
                        _c("NoteList", {
                          attrs: {
                            readOnly: _vm.isNoteReadOnly,
                            notes: _vm.notes,
                          },
                          on: {
                            add: _vm.addNote,
                            edit: _vm.editNote,
                            toRemove: _vm.removeNote,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.customFieldMap["notes"] != null
                  ? [
                      _c(
                        "div",
                        { staticClass: "container pl-0" },
                        [
                          _c(
                            "b-row",
                            _vm._l(
                              _vm.customFieldMap["notes"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "notes" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.customer[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.customer,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "customer[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
              ],
        ],
        2
      ),
      _vm.exists
        ? [
            _vm.state.historyShow
              ? _c("GenericHistoryModal", {
                  attrs: {
                    show: _vm.state.historyShow,
                    id: _vm.id,
                    entityType: "CUSTOMER",
                    customFields: _vm.customFields,
                    links: "CONTACT,LOCATION,NOTE,STORAGE_FILE,TAG",
                  },
                  on: {
                    "update:show": function ($event) {
                      return _vm.$set(_vm.state, "historyShow", $event)
                    },
                  },
                })
              : _vm._e(),
            _vm.state.noteShow
              ? _c("NoteModal", {
                  attrs: { show: _vm.state.noteShow, note: _vm.note },
                  on: {
                    "update:show": function ($event) {
                      return _vm.$set(_vm.state, "noteShow", $event)
                    },
                    toAdd: _vm.toAddNote,
                    toUpdate: _vm.toUpdateNote,
                  },
                })
              : _vm._e(),
          ]
        : _vm._e(),
      _vm.state.locSelectorShow
        ? _c("GenericSelectorModalForAdmin", {
            attrs: {
              show: _vm.state.locSelectorShow,
              entityService: _vm.locationUtil,
              entity: "LOCATION",
              preselected: _vm.locEditId,
              nonAdmin: "",
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "locSelectorShow", $event)
              },
              ok: _vm.locSelectorOk,
            },
          })
        : _vm._e(),
      _vm.state.fileSelectorShow
        ? _c("FileSelectorModal", {
            attrs: { show: _vm.state.fileSelectorShow },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "fileSelectorShow", $event)
              },
              ok: _vm.fileSelectorOk,
            },
          })
        : _vm._e(),
      _vm.downloadProgressShow
        ? _c("DownloadProgressModal", {
            attrs: {
              show: _vm.downloadProgressShow,
              downloadPercentage: _vm.downloadPercentage,
            },
            on: {
              "update:show": function ($event) {
                _vm.downloadProgressShow = $event
              },
              cancel: _vm.downloadCancel,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }