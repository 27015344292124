var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("ProjectBadgeGroupModal", {
    attrs: { show: _vm.modalShow, projectList: _vm.projectList },
    on: {
      "update:show": function ($event) {
        _vm.modalShow = $event
      },
      ok: _vm.projectSelectorOk,
      cancel: _vm.projectSelectorCancel,
      projectDeleted: _vm.projectSelectorDeleted,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }