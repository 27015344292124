var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("CustomerBadgeGroupModal", {
    attrs: {
      show: _vm.modalShow,
      customerList: _vm.customerList,
      isOptional: _vm.isOptional,
    },
    on: {
      "update:show": function ($event) {
        _vm.modalShow = $event
      },
      ok: _vm.customerSelectorOk,
      cancel: _vm.customerSelectorCancel,
      customerDeleted: _vm.customerSelectorDeleted,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }