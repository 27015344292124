var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: _vm.componentId },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": [_vm.componentId],
          },
          on: { ok: _vm.modalOk, hidden: _vm.modalCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function ({ cancel }) {
                return [
                  _c("h5", { staticClass: "custom-modal-title" }, [
                    _vm._v(" " + _vm._s(_vm.labelTitle) + " "),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "close custom-modal-close",
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v("×")]
                  ),
                ]
              },
            },
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  !_vm.isAccessDenied &&
                  !_vm.isReadOnly &&
                  ((_vm.exists && _vm.canEdit()) ||
                    (!_vm.exists && _vm.canAdd()))
                    ? _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "success" },
                          on: { click: _vm.modalOk },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.ok")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.modalShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "modalShow", $$v)
            },
            expression: "state.modalShow",
          },
        },
        [
          _vm.isAccessDenied
            ? [
                _c("div", { staticClass: "modal-message-overlay" }, [
                  _c("span", { staticClass: "grid-overlay" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "entity_selector.error.insufficient_permission_to_add_entity",
                          [_vm.$t("webhook.title").toLowerCase()]
                        )
                      )
                    ),
                  ]),
                ]),
              ]
            : [
                _c(
                  "b-alert",
                  {
                    attrs: {
                      variant: "danger",
                      dismissible: "",
                      show: _vm.showError,
                    },
                    on: { dismissed: _vm.dismissAlert },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fas", "triangle-exclamation"] },
                    }),
                    _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
                    _c(
                      "ul",
                      {
                        staticClass: "mb-0",
                        attrs: { show: _vm.showErrorDetail },
                      },
                      [
                        _vm._l(_vm.alertMsgDetails, function (item, index) {
                          return [
                            _c("li", { key: index }, [_vm._v(_vm._s(item))]),
                          ]
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "container pl-0" },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { staticClass: "pr-0", attrs: { cols: "6" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  id: "entity",
                                  label: _vm.$t("webhook.field.entity"),
                                  "label-for": "entity",
                                },
                              },
                              [
                                _c("multiselect", {
                                  staticClass:
                                    "custom-dropdown-options enable-option-icon",
                                  attrs: {
                                    "max-height": 300,
                                    options: _vm.entityOptions.map(
                                      (i) => i.value
                                    ),
                                    "custom-label": _vm.getEntityOptionLabel,
                                    placeholder: "",
                                    searchable: false,
                                    "allow-empty": false,
                                    showLabels: false,
                                    disabled: _vm.isReadOnly,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "option",
                                      fn: function (props) {
                                        return [
                                          _vm.webhook.entity == props.option
                                            ? _c("font-awesome-icon", {
                                                staticClass:
                                                  "selected-option-icon",
                                                attrs: {
                                                  icon: ["far", "check"],
                                                },
                                              })
                                            : _vm._e(),
                                          _c(
                                            "span",
                                            { staticClass: "option__title" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getEntityOptionLabel(
                                                    props.option
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.webhook.entity,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.webhook, "entity", $$v)
                                    },
                                    expression: "webhook.entity",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { staticClass: "pr-0", attrs: { cols: "6" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  id: "action",
                                  label: _vm.$t("webhook.field.action"),
                                  "label-for": "action",
                                },
                              },
                              [
                                _c("multiselect", {
                                  staticClass:
                                    "custom-dropdown-options enable-option-icon",
                                  attrs: {
                                    "max-height": 300,
                                    options: _vm.actionOptions.map(
                                      (i) => i.value
                                    ),
                                    "custom-label": _vm.getActionOptionLabel,
                                    placeholder: "",
                                    searchable: false,
                                    "allow-empty": false,
                                    showLabels: false,
                                    disabled: _vm.isReadOnly,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "option",
                                      fn: function (props) {
                                        return [
                                          _vm.webhook.action == props.option
                                            ? _c("font-awesome-icon", {
                                                staticClass:
                                                  "selected-option-icon",
                                                attrs: {
                                                  icon: ["far", "check"],
                                                },
                                              })
                                            : _vm._e(),
                                          _c(
                                            "span",
                                            { staticClass: "option__title" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getActionOptionLabel(
                                                    props.option
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.webhook.action,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.webhook, "action", $$v)
                                    },
                                    expression: "webhook.action",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { staticClass: "pr-0", attrs: { cols: "12" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                class: { "mb-0": _vm.showUrlError },
                                attrs: {
                                  label: _vm.$t("webhook.field.url"),
                                  "label-for": "url",
                                },
                              },
                              [
                                _c(
                                  "b-input-group",
                                  [
                                    _c("b-form-input", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: { required: true },
                                          expression: "{ required: true}",
                                        },
                                      ],
                                      attrs: {
                                        id: "url",
                                        type: "text",
                                        "data-vv-as":
                                          _vm.$t("webhook.field.url"),
                                        "data-vv-name": "webhook.url",
                                        state:
                                          _vm.fieldValidateUtil.stateValidate(
                                            _vm.isReadOnly,
                                            _vm.veeFields,
                                            _vm.errors,
                                            "webhook.url"
                                          ),
                                        readonly: _vm.isReadOnly,
                                        trim: "",
                                      },
                                      model: {
                                        value: _vm.webhook.url,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.webhook, "url", $$v)
                                        },
                                        expression: "webhook.url",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-form-invalid-feedback",
                                  {
                                    staticClass:
                                      "alert-danger form-field-alert",
                                    class: { "d-block": _vm.showUrlError },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: {
                                        icon: ["far", "circle-exclamation"],
                                      },
                                    }),
                                    _vm._v(
                                      "  " +
                                        _vm._s(
                                          _vm.errors.first("webhook.url")
                                        ) +
                                        " "
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }