var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "edit-cell" },
    [
      _c("span", [_vm._v(_vm._s(_vm.label))]),
      _c("SkillLevelEditorModal", {
        attrs: { show: _vm.show, skillLevels: _vm.skillLevels },
        on: {
          "update:show": function ($event) {
            _vm.show = $event
          },
          ok: _vm.ok,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }