<template>
  <div :id="componentId" style="height: 100%, width: 100%">
    <b-modal v-model="state.modalShow" size="lg" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow" :modal-class="['anti-shift', componentId]"
      @hidden="$emit('update:show', false)"
      scrollable
    >

      <template #modal-header="{ cancel }">
        <h5 class="custom-modal-title">
          {{ labelTitle }}
        </h5>
        <template v-if="exists">
          
          <div class="history-button lock-container">
            <template v-if="isLockVisible">
              <div class="ml-1 mr-1">{{ $t('lock') }}</div>
              <b-form-checkbox :disabled="isLockReadOnly" switch v-model="customer.readOnly"/>
            </template>
            <b-button variant="secondary" size="sm" @click="state.historyShow = true">
              <font-awesome-icon :icon="['far', 'clock-rotate-left']"/>
              {{ $t('button.history') }}
            </b-button>
          </div>
        </template>
        <button class="close custom-modal-close" @click="cancel()">×</button>
      </template>

      <template v-if="isAccessDenied">
        <div class="modal-message-overlay">
        <span class="grid-overlay">{{ 
          restrictedRequiredField != null
            ? $t('entity_selector.error.insufficient_permission_to_add_entity_with_reason', [$t('customer.title').toLowerCase(), restrictedRequiredField])
            : $t('entity_selector.error.insufficient_permission_to_add_entity', [$t('customer.title').toLowerCase()])
          }}</span>
        </div>
      </template>
      <template v-else>

        <AvatarBanner 
          v-if="isAvatarBannerVisible" 
          v-model="avatarBanner" 
          :readOnly="isAvatarBannerReadOnly"
          :baseAvatarIcon="['fad', 'user-tie']" @status="avatarBannerStatus"
        />
        <b-alert :variant="successMsg?'success':'danger'" dismissible :show="showError" @dismissed="dismissAlert">
          <font-awesome-icon :icon="['far', 'check']" v-if="successMsg != null"/>
          <font-awesome-icon :icon="['fas', 'triangle-exclamation']" v-else />
          &nbsp;&nbsp;{{ successMsg? successMsg : alertMsg }}
          <ul :show="showErrorDetail" class="mb-0">
            <template v-for="(item, index) in alertMsgDetails">
              <li :key="index">{{ item }}</li>
            </template>
          </ul>
        </b-alert>

        <div class="container pl-0">
          <b-row>
            <b-col v-if="isNameVisible" cols="12" md="8" class="pr-0">
              <b-form-group :label="$t('customer.field.name')" label-for="name">
                <b-input-group>
                  <b-form-input id="name" type="text"
                    :data-vv-as="$t('customer.field.name')"
                    data-vv-name="customer.name"
                    :maxlength="maxNameLength"
                    data-vv-delay="500"
                    v-model="customer.name" 
                    :readonly="isNameReadOnly"
                    trim
                  />
                </b-input-group>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showNameError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('customer.name') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['name'] != null">
              <b-col v-for="(field, index) in customFieldMap['name']" :key="'name'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="customer[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
          
            <b-col v-if="isIdentifierVisible" cols="12" md="4" class="pr-0">
              <b-form-group :label="$t('field.identifier')" label-for="identifier">
                <b-input-group>
                  <b-form-input id="identifier" type="text"
                    :data-vv-as="$t('field.identifier')"
                    data-vv-name="customer.identifier"
                    :maxlength="maxIdentifierLength"
                    v-model="customer.identifier" 
                    :readonly="isIdentifierReadOnly"
                    trim>
                  </b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['identifier'] != null">
              <b-col v-for="(field, index) in customFieldMap['identifier']" :key="'identifier'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="customer[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <template v-if="customFieldMap['default'] != null">
              <b-col v-for="(field, index) in customFieldMap['default']" :key="index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="customer[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
                        
            <b-col v-if="isNickNameVisible" cols="12" md="8" class="pr-0">
              <b-form-group :label="$t('customer.field.nickName')" label-for="nickName">
                <b-input-group>
                  <b-form-input id="nickName" type="text"
                    v-model="customer.nickName" 
                    :readonly="isNickNameReadOnly"
                    trim/>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col v-if="isColorVisible" cols="12" md="4" class="pr-0">
              <div class="color-container">
                <Color :disabled="isColorReadOnly" v-model="customer.color" :update="updatedColor"/>
              </div>
            </b-col>

            <template v-if="customFieldMap['nickName'] != null">
              <b-col v-for="(field, index) in customFieldMap['nickName']" :key="'nickName'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="customer[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
          </b-row>
        </div>

        <b-form-group v-if="isDescriptionVisible" :label="$t('customer.field.description')" label-for="description">
          <b-form-textarea id="description" 
            :placeholder="isDescriptionReadOnly? '' : $t('customer.placeholder.description')"
            v-model="customer.description"
            :max-rows="6"
            :readonly="isDescriptionReadOnly"
            trim
            :rows="3"/>
        </b-form-group>

        <template v-if="customFieldMap['description'] != null">
          <div class="container pl-0">
            <b-row>
              <b-col v-for="(field, index) in customFieldMap['description']" :key="'description'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="customer[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </template>

        <b-form-group v-if="isTagVisible">
          <TagList :holderId="id" :tags="tags" @modified="tagsModified" :readOnly="isTagReadOnly" />
        </b-form-group>

        <template v-if="customFieldMap['tags'] != null">
          <div class="container pl-0">
            <b-row>
              <b-col v-for="(field, index) in customFieldMap['tags']" :key="'tags'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="customer[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </template>
        
        <b-form-group v-if="isLocationVisible">
          <label class="mr-1">{{ $t(`customer.field.location`) }}</label>
          <button v-if="!isLocationReadOnly" id="LOCATION_ADD" class="btn-action" @click="locSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
            <b-popover
              target="LOCATION_ADD"
              placement="top"
              triggers="hover"
              :content="$t('customer.button.location_add')">
            </b-popover>
          </button>
          <BadgeGroup v-model="locations" :readOnly="isLocationReadOnly">
            <template v-slot:default="{ item, index }">
              <Badge @badgeRemove="locationBadgeRemove(index)" @badgeClick="locationBadgeClick(item.uuId)"
                :text="item.name" 
                variant="primary" 
                :pillable="!!item.pillable" :key="index"
                :readOnly="isLocationReadOnly" />
              </template>
          </BadgeGroup>
        </b-form-group>

        <template v-if="customFieldMap['location'] != null">
          <div class="container pl-0">
            <b-row>
              <b-col v-for="(field, index) in customFieldMap['location']" :key="'location'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="customer[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </template>

        <b-form-group v-if="isWebsitesVisible" class="mb-0">
          <label class="mr-1">{{ $t(`customer.field.websites`) }}</label>
          <b-row v-for="(website, index) in customer.websites" v-bind:key="index">
            <b-col lg>
              <b-form-group>
                <b-input-group>
                  <b-form-input type="text" :id='`website`+index' v-model="customer.websites[index]" :readonly="isWebsitesReadOnly" trim></b-form-input>
                  <template v-if="!isWebsitesReadOnly">
                    <b-input-group-append>
                      <b-button :id="`WEBSITE_BTN_ADD_${id}_${index}`" variant="secondary" @click="fieldItemAdd('websites', index, null)" ><font-awesome-icon :icon="['far', 'plus']"/></b-button>
                      <b-popover :target="`WEBSITE_BTN_ADD_${id}_${index}`" triggers="hover" placement="top">
                        {{ $t('button.add') }}
                      </b-popover>
                    </b-input-group-append>
                    <b-input-group-append>
                      <b-button :id="`WEBSITE_BTN_REMOVE_${id}_${index}`" variant="secondary" @click="fieldItemRemove('websites', index, null)"><font-awesome-icon :icon="['far', 'trash-can']"/></b-button>
                      <b-popover :target="`WEBSITE_BTN_REMOVE_${id}_${index}`" triggers="hover" placement="top">
                        {{ $t('button.remove') }}
                      </b-popover>
                    </b-input-group-append>
                  </template>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form-group>

        <template v-if="customFieldMap['websites'] != null">
          <div class="container pl-0">
            <b-row>
              <b-col v-for="(field, index) in customFieldMap['websites']" :key="'websites'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="customer[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </template>

        <b-form-group v-if="isSocialsVisible" class="mb-0">
          <label class="mr-1">{{ $t(`customer.field.socials`) }}</label>
          <b-row v-for="(social, index) in customer.socials" v-bind:key="index">
            <b-col lg>
              <b-form-group>
                <b-input-group>
                  <b-input-group-prepend v-if="!isSocialsReadOnly">
                  <b-form-select v-model="social.kind" :options="optionSocials"
                    :disabled="isSocialsReadOnly"
                  >
                      <template slot="first">
                        <option :value="null" disabled>{{ $t('customer.select_socialnet_type') }}</option>
                      </template>
                  </b-form-select>
                  </b-input-group-prepend>
                  <b-form-input type="text"
                    v-model="social.data"
                    :readonly="isSocialsReadOnly"
                    trim></b-form-input>
                  <template v-if="!isSocialsReadOnly">
                    <b-input-group-append>
                      <b-button :id="`SOCIAL_BTN_ADD_${id}_${index}`" variant="secondary" @click="fieldItemAdd('socials', index, {kind: null, data: null})"><font-awesome-icon :icon="['far', 'plus']"/></b-button>
                      <b-popover :target="`SOCIAL_BTN_ADD_${id}_${index}`" triggers="hover" placement="top">
                        {{ $t('button.add') }}
                      </b-popover>
                    </b-input-group-append>
                    <b-input-group-append>
                      <b-button :id="`SOCIAL_BTN_REMOVE_${id}_${index}`" variant="secondary" @click="fieldItemRemove('socials', index, {kind: null, data: null})"><font-awesome-icon :icon="['far', 'trash-can']"/></b-button>
                      <b-popover :target="`SOCIAL_BTN_REMOVE_${id}_${index}`" triggers="hover" placement="top">
                        {{ $t('button.remove') }}
                      </b-popover>
                    </b-input-group-append>
                  </template>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form-group>

        <template v-if="customFieldMap['socials'] != null">
          <div class="container pl-0">
            <b-row>
              <b-col v-for="(field, index) in customFieldMap['socials']" :key="'socials'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="customer[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </template>
        
        <b-form-group v-if="isContactVisible">
          <ContactList :holderId="id" :contacts="contacts" @success="contactSuccess" :readOnly="isContactReadOnly"/>
        </b-form-group>

        <template v-if="customFieldMap['contacts'] != null">
          <div class="container pl-0">
            <b-row>
              <b-col v-for="(field, index) in customFieldMap['contacts']" :key="'contacts'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="customer[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </template>

        <b-form-group v-if="isStorageFileVisible">
          <label class="mr-1 mt-1">{{ $t(`customer.field.files`) }}</label>
          <button v-if="!isStorageFileReadOnly" id="FILE_ADD" class="btn-action" @click="fileSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
            <b-popover
              target="FILE_ADD"
              placement="top"
              triggers="hover"
              :content="$t('customer.button.file_add')">
            </b-popover>
          </button>
          <BadgeGroup v-model="files" :readOnly="isStorageFileReadOnly">
            <template v-slot:default="{ item, index }">
              <Badge @badgeRemove="fileBadgeRemove(index)" @badgeClick="fileBadgeClick(item)"
                :text="labelFilename(item.name, item.type)" 
                variant="primary" 
                :pillable="!!item.pillable" :key="index" 
                :readOnly="isStorageFileReadOnly"
                enableClickWhenReadOnly
                 />
              </template>
          </BadgeGroup>
        </b-form-group>

        <template v-if="customFieldMap['files'] != null">
          <div class="container pl-0">
            <b-row>
              <b-col v-for="(field, index) in customFieldMap['files']" :key="'files'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="customer[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </template>
        
        <b-form-group v-if="isNoteVisible">
          <NoteList :readOnly="isNoteReadOnly" :notes="notes" @add="addNote" @edit="editNote" @toRemove="removeNote" />
        </b-form-group>

        <template v-if="customFieldMap['notes'] != null">
          <div class="container pl-0">
            <b-row>
              <b-col v-for="(field, index) in customFieldMap['notes']" :key="'notes'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="customer[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </template>
      </template>

      <template v-slot:modal-footer="{ cancel }">
        <template v-if="!isAccessDenied && (canEdit() || !exists)">
          <b-button size="sm" variant="success" disabled v-if="state.isSubmitting">
            <b-spinner small type="grow" />{{ $t('button.saving') }}
          </b-button>
          <b-button size="sm" variant="success" :disabled="disableOk" v-else @click="ok">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <template v-if="exists">
      <GenericHistoryModal v-if="state.historyShow" :show.sync="state.historyShow" :id="id" entityType="CUSTOMER" :customFields="customFields" links="CONTACT,LOCATION,NOTE,STORAGE_FILE,TAG" />
      <NoteModal v-if="state.noteShow" :show.sync="state.noteShow" :note="note" @toAdd="toAddNote" @toUpdate="toUpdateNote"/>
    </template>
    
    <!-- location selector -->
    <GenericSelectorModalForAdmin v-if="state.locSelectorShow"
      :show.sync="state.locSelectorShow" 
      :entityService="locationUtil" 
      entity="LOCATION"
      :preselected="locEditId"
      nonAdmin
      @ok="locSelectorOk"
    />
    
    <FileSelectorModal v-if="state.fileSelectorShow" :show.sync="state.fileSelectorShow" @ok="fileSelectorOk"/>
    <DownloadProgressModal v-if="downloadProgressShow" :show.sync="downloadProgressShow" :downloadPercentage="downloadPercentage" @cancel="downloadCancel"/>
  </div>
</template>

<script>
import { persistNotes } from '@/components/Note/script/crud-util';
import { updateTags } from '@/components/Tag/script/crud-util';
import { updateFiles } from '@/helpers/file';
import { updateLocation } from '@/helpers/location';
import { getCustomFieldInfo, customFieldValidate } from '@/helpers/custom-fields';
import { cloneDeep } from 'lodash';
import { strRandom, forceFileDownload, labelFilename, processRegExp } from '@/helpers';
import { socialTypes } from '@/selectOptions';
import { fieldValidateUtil } from '@/script/helper-field-validate';
import { customerService, customerLinkLocationService, customerLinkFileService,
         customerLinkTagService } from '@/services';
import { removeDeniedProperties } from '@/views/management/script/common';
import { getAppendAfterObjectWithTopDownRelationship } from '@/components/modal/script/field';
import { locationUtil } from '@/views/management/script/location';

export default {
  name: 'CustomerModal',
  components: {
    AvatarBanner: () => import('@/components/AvatarBanner/AvatarBanner'),
    BadgeGroup: () => import('@/components/BadgeGroup/BadgeGroup.vue'),
    Badge: () => import('@/components/BadgeGroup/components/Badge'),
    ContactList: () => import('@/components/Contact/ContactList.vue'),
    FileSelectorModal: () => import('@/components/modal/FileSelectorModal'),
    DownloadProgressModal: () => import('@/components/modal/DownloadProgressModal'),
    GenericHistoryModal: () => import('@/components/modal/GenericHistoryModal'),
    NoteList: () => import('@/components/Note/NoteList.vue'),
    NoteModal: () => import('@/components/modal/NoteModal.vue'),
    TagList: () => import('@/components/Tag/TagList.vue'),
    Color: () => import('@/components/Color/Color.vue'),
    CustomField: () => import('@/components/CustomField.vue'),
    GenericSelectorModalForAdmin : () => import('@/components/modal/GenericSelectorModalForAdmin')
  },
  props: {
    id:        { type: String,   default: `CUSTOMER_NEW_${strRandom(5)}` },
    title:     { type: String,   default: null },
    readOnly:  { type: Boolean,  default: false },
    show:      { type: Boolean, required: true }
  },
  data() {
    return {
      permissionName: "CUSTOMER",
      modelInfo: null,
      alertMsg: null,
      successMsg: null,
      alertMsgDetails: [],
      state: {
        editable:            false,
        isSubmitting:        false,
        modalShow:           false,
        locSelectorShow:     false,
        fileSelectorShow:    false,
        historyShow:         false,
        noteShow:            false
      },
      avatarBanner: {
        avatarId: null,
        bannerId: null
      },
      customer: {
        uuId:               null,
        name:               null,
        nickName:           null,
        description:        null,
        websites:         [null],
        socials:       [{ kind: null, data: null }],
        identifier:         null,
        color:              null,
        readOnly:           false
      },
      locations: [
        // { uuId: "1", name: "Melbourne Office" }
      ],
      locEditId: null,
      contacts: [],
      notes: [],
      note: {
        uuId: null,
        text: null,
        identifier: null
      },
      files: [],
      
      downloadProgressShow: false,
      downloadPercentage: 0,
      downloadCancelTokenSource: null,
      downloadCancelled: false,
      tags: [],
      updatedColor: null,
      isAccessDenied: false,
      
      customFields: [],
      customFieldMap: {},
      
      restrictedRequiredField: null
    }
  },
  created() {
    this.getModelInfo();
    this.fieldValidateUtil = fieldValidateUtil;
    this.originLocations = [];
    this.originContacts = [];
    this.optionSocials = socialTypes;
    if(this.exists) {
      this.customerGet(this.id);
    }
    this.original = {
      readOnly: false
    }
    this.originFiles = [];
    this.originCustomer = null;
    this.originNotes = [];
    this.originTags = [];
    this.locationUtil = locationUtil;
  },
  mounted() {
    if (this.show) {
      this.state.editable =  (!this.exists && this.canAdd(this.permissionName)) || (this.exists && this.canEdit(this.permissionName));
    }
    this.state.modalShow = this.show;
  },
  beforeDestroy() {
    this.fieldValidateUtil = null;
    this.originLocations = null;
    this.originContacts = null;
    this.optionSocials = null;
    this.originFiles = null;
    this.originCustomer = null;
    this.originNotes = null;
    this.originTags = null;
    this.locationUtil = null;
  },
  computed: {
    customFieldsFiltered() {
      return this.customFields.filter(f => this.canView(this.permissionName, [f.name]) && ((!this.exists && this.canAdd(this.permissionName, [f.name]))
      || this.exists));
    },
    componentId() {
      return `CUSTOMER_FORM_${this.id}`;
    },
    isReadOnly() {
      return !this.state.editable || this.readOnly || this.customer.readOnly;
    },
    showError() {
      return this.alertMsg != null || this.successMsg != null;
    },
    showErrorDetail() {
      return this.alertMsgDetails != null && this.alertMsgDetails.length > 0;
    },
    showNameError() {
      return fieldValidateUtil.hasError(this.errors, 'customer.name');
    },
    labelTitle() {
      return this.title? this.title: this.$t('customer.title_new');
    },
    exists() {
      return this.id && !this.id.startsWith('CUSTOMER_NEW_');
    },
    maxNameLength() {
      var values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "name";
      });
      return values.length !== 0 ? values[0].max : 200;
    },
    maxIdentifierLength() {
      const values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "identifier";
      });
      return values.length !== 0 ? values[0].max : 200;
    },
    isAvatarBannerVisible() {
      return this.canView('STORAGE_FILE') && this.canView(this.permissionName, ['avatarRef', 'bannerRef']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['avatarRef', 'bannerRef'])) || this.exists);
    },
    isAvatarBannerReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['avatarRef', 'bannerRef']));
    },
    isNameVisible() {
      //Name is mandatory field so checking against canAdd() can be skipped
      return this.canView(this.permissionName, ['name'])
    },
    isNameReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['name']))
    },
    isIdentifierVisible() {
      return this.canView(this.permissionName, ['identifier']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['identifier'])) || this.exists)
    },
    isIdentifierReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['identifier']))
    },
    isColorVisible() {
      return this.canView(this.permissionName, ['color']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['color'])) || this.exists)
    },
    isColorReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['color']))
    },
    isLocationVisible() {
      //Link creation requires main entity's edit permission
      return this.canView('LOCATION') && this.canView(this.permissionName, ['LOCATION']) 
      && ((!this.exists && this.canEdit(this.permissionName, ['LOCATION'])) || this.exists)
    },
    isLocationReadOnly() {
      return this.isReadOnly || !this.canEdit(this.permissionName, ['LOCATION'])
    },
    isTagVisible() {
      //Tag field is only visible on existing entity. Therefore, skip checking canEdit for new entity creation flow.
      return this.exists && this.canView('TAG') && this.canView(this.permissionName, ['TAG'])
    },
    isTagReadOnly() {
      return this.isReadOnly || !this.canAdd('TAG') || !this.canEdit('TAG') || !this.canEdit(this.permissionName, ['TAG'])
    },
    isNoteVisible() {
      //Note field is only visible on existing entity. Therefore, skip checking canEdit for new entity creation flow.
      return this.exists && this.canView('NOTE') && this.canView(this.permissionName, ['NOTE'])
    },
    isNoteReadOnly() {
      return this.isReadOnly || !this.canEdit(this.permissionName, ['NOTE'])
    },
    isNickNameVisible() {
      return this.canView(this.permissionName, ['nickName']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['nickName']))  || this.exists)
    },
    isNickNameReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['nickName']))
    },
    isStorageFileVisible() {
      //Link creation to check against canEdit()
      return this.canView(this.permissionName, ['STORAGE_FILE']) 
      && ((!this.exists && this.canEdit(this.permissionName, ['STORAGE_FILE'])) || this.exists)
    },
    isStorageFileReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['STORAGE_FILE']))
    },
    isWebsitesVisible() {
      return this.canView(this.permissionName, ['websites']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['websites'])) || this.exists)
    },
    isWebsitesReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['websites']))
    },
    isSocialsVisible() {
      return this.canView(this.permissionName, ['socials'])
      && ((!this.exists && this.canAdd(this.permissionName, ['socials'])) || this.exists)
    },
    isSocialsReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['socials']))
    },
    isDescriptionVisible() {
      return this.canView(this.permissionName, ['desription'])
      && ((!this.exists && this.canAdd(this.permissionName, ['description'])) || this.exists)
    },
    isDescriptionReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['description']))
    },
    isContactVisible() {
      return this.canView(this.permissionName, ['CONTACT']) && this.canView('CONTACT') 
      && (this.exists && this.canEdit(this.permissionName, ['CONTACT']))
    },
    isContactReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['CONTACT']))
    },
    disableOk() {
      return (this.original.readOnly && this.customer.readOnly) || this.state.isSubmitting;
    },
    isLockVisible() {
      return this.canView(this.permissionName, ['readOnly'])
      && ((!this.exists && this.canAdd(this.permissionName, ['readOnly'])) || this.exists)
    },
    isLockReadOnly() {
      return !this.state.editable || this.readOnly || (this.exists && !this.canEdit(this.permissionName, ['readOnly']))
    }
  },
  watch: {
    show(newValue) {
      this.processWhenShowModal(newValue);
    }
  },
  methods: {
    async processWhenShowModal(newValue) {
      if(newValue != this.state.modalShow) {
        this.state.modalShow = newValue;
        await getCustomFieldInfo(this, 'CUSTOMER');
        if (this.customFields.length == 0) {
          this.customFieldMap = {};
        } else {
          this.customFieldMap =  getAppendAfterObjectWithTopDownRelationship(this.customFields, this.allowViewFunc);
        }
        this.state.noteShow = false;
        this.originNotes = [];
        this.notes = [];
        this.tags = [];
        this.alertMsg = null;
        this.originFiles = [];
        this.files = [];
        this.state.editable =  (!this.exists && this.canAdd(this.permissionName)) || (this.exists && this.canEdit(this.permissionName));
        this.restrictedRequiredField = null;
        this.resetCustomerProperties();
        if(this.exists) {
          this.customerGet(this.id);
        } else {
          const requiredFields = ['name']
          const requiredCustomFields = this.customFields.filter(i => i.notNull == true).map(i => i.name);
          if (requiredCustomFields.length > 0) {
            requiredFields.push(...requiredCustomFields);
          }
          let result = this.canView2(this.permissionName, requiredFields);
          if (result.val) {
            result = this.canAdd2(this.permissionName, requiredFields)
          } 
          
          if (result.restrictedProp != null) {
            this.restrictedRequiredField = this.getDisplayNameOfProperty(result.restrictedProp);
          }

          if (result.val) {
            this.isAccessDenied = false;
          } else {
            this.isAccessDenied = true;
          }
        }
      }
    },
    getDisplayNameOfProperty(val) {
      const found = this.customFields.find(i => i.name == val);
      if (found != null) {
        return found.displayName;
      }
      return  this.$t(`customer.field.${val}`);
    },
    getModelInfo() {
      const self = this;
      this.$store.dispatch('data/info', {type: "api", object: "CUSTOMER"}).then(value => {
        self.modelInfo = value.CUSTOMER.properties;
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    },
    labelFilename(name, type) {
      return labelFilename(name, type);
    },
    async customerGet(id) {
      let data = await customerService.query([{ uuId: id}], ['LOCATION', 'CONTACT', 'STORAGE_FILE', 'NOTE', 'TAG'])
      .then((response) => {
        return response.data[response.data.jobCase] || [];
      })
      .catch(e => {
        if (e != null && e.response != null && e.response.status == 403) {
          this.isAccessDenied = true;
          return [];
        }
        this.httpAjaxError(e);
        return [];
      });
      if (data.length > 0) {
        this.digestResponse(data[0]);
      }
    },
    digestResponse(data) {
      const c = this.customer;
      for (const key of Object.keys(c)) {
        c[key] = data[key] || null;
      }

      this.original.readOnly = data.readOnly;
      
      for (const field of this.customFields) {
        if (typeof data[field.name] !== 'undefined') {
          c[field.name] = data[field.name];
        }
      }
      
      if(c.websites == null || c.websites.length < 1) {
        c.websites = [null];
      }
      if(c.socials == null || c.socials.length < 1) {
        c.socials = [{ kind: null, data: null }];
      }

      c.uuId = data.uuId;
      //Setup avatarBanner data
      const ab = this.avatarBanner;
      if (data.avatarRef || data.bannerRef) {
        ab.avatarId = data.avatarRef;
        ab.bannerId = data.bannerRef;
        if (ab.avatarId == '00000000-0000-0000-0000-000000000000') {
          ab.avatarId = null;
        }
        if (ab.bannerId == '00000000-0000-0000-0000-000000000000') {
          ab.bannerId = null;
        }
      }

      //Setup Location data
      if (data.locationList && data.locationList.length > 0) {
        const list = data.locationList.map(i => { return { uuId: i.uuId, name: i.name }})
        this.originLocations.splice(0, this.originLocations.length, ...list);
        this.locations.splice(0, this.locations.length, ...cloneDeep(list));
      }

      //Setup Tags data
      if (data.tagList && data.tagList.length > 0) {
        const list = typeof data.tagList !== 'undefined' ? data.tagList : [];
        this.originTags.splice(0, this.originTags.length, ...list);
        this.tags.splice(0, this.tags.length, ...cloneDeep(list));
      }
      
      //Setup Files data
      if (data.storageFileList && data.storageFileList.length > 0) {
        const list = data.storageFileList.map(i => { return { uuId: i.uuId, name: i.name, type: i.type }})
        this.originFiles.splice(0, this.originFiles.length, ...list);
        this.files.splice(0, this.files.length, ...cloneDeep(list));
      }

      //Setup Contact data
      if (data.contactList && data.contactList.length > 0) {
        const list = typeof data.contactList !== 'undefined' ? data.contactList : [];
        this.originContacts.splice(0, this.originContacts.length, ...list);
        this.contacts.splice(0, this.contacts.length, ...cloneDeep(list));
      }

       //Setup Comment data
      this.notes = typeof data.noteList !== 'undefined' ? data.noteList : [];
      this.notes.sort((a, b) => {
        return b.modified - a.modified;
      });
      this.originNotes = cloneDeep(this.notes);
      if (data.noteList && data.noteList.length > 0) {
        const container = this.$refs['comments'];
        if (typeof container !== 'undefined') {
          container.scrollTop = container.scrollHeight;
        }
      }

      this.originCustomer = cloneDeep(c);
      this.originCustomer.avatarRef = data.avatarRef;
      this.originCustomer.bannerRef = data.bannerRef;
      this.originCustomer.websites = data.websites != null? cloneDeep(data.websites) : null;
      this.originCustomer.socials = data.socials != null? cloneDeep(data.socials) : null;
    },
    ok() {
      const customFields = this.customFieldsFiltered;
      for (const field of customFields) {
        if (!customFieldValidate(field, this.customer[field.name])) {
          field.showError = true;
          return;  
        }
      }
      
      this.errors.clear();
      this.alertMsg = null;
      this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
      if(!this.customer.name || this.customer.name && this.customer.name.trim().length < 1) {
        this.errors.add({
          field: 'customer.name',
          msg: this.$t('validation.messages.required', [this.$t('customer.field.name')])
        });
      }

      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length < 1) {
          
          this.customerSubmit();
        } else {
          this.alertMsg = this.$t('error.attention_required');
          this.scrollToTop();
        }
      });
    },
    customerSubmit() {
      const data = cloneDeep(this.customer);
      data['avatarRef'] = this.avatarBanner.avatarId;
      data['bannerRef'] = this.avatarBanner.bannerId;
      if (data['avatarRef'] == null) {
        data['avatarRef'] = '00000000-0000-0000-0000-000000000000'
      }
      if (data['bannerRef'] == null) {
        data['bannerRef'] = '00000000-0000-0000-0000-000000000000'
      }
      
      // don't send null values for websites and socials
      if (data['websites'].length > 0 && data['websites'][0] === null || data['websites'][0].trim().length == 0) {
        data['websites'] = [];
      }

      const socials = data['socials'];
      if (socials && socials.length > 0 && 
        (socials[0].kind === null || socials[0].data === null || socials[0].data === '')) {
        socials.splice(0, socials.length);
      }

      let mode = 'update';
      if(!this.exists) {
        delete data['uuId'];
        mode = 'create';
      }
      this.customerPost(mode, data);
    },
    async customerPost(method, data) {
      this.state.isSubmitting = true;
      let customerId = null;
      
      //Skip updating customer if there is no change in customer properties.
      let hasChanged = false;
      if (method != 'create') {
        hasChanged = this.removeUnchangedCustomerProperties(data);
      }

      if (method == 'create' || hasChanged) {
        let result = await this.updateCustomer(method, data);
        if(result.hasError) {
          this.alertMsg = result.msg;
          this.state.isSubmitting = false;
          return;
        }
        customerId = result.customerId;
      } else {
        customerId = data.uuId;
      }

      let hasError = false;
      
      // save the color in the profile
      this.updatedColor = data.color;
      if (!this.isLocationReadOnly) {
        const locationResult = await updateLocation(customerId, customerLinkLocationService, this.originLocations, this.locations);
        if (locationResult.hasError) {
          hasError = true;
          this.alertMsg = this.$t(`customer.${method}_partial`);
          if (locationResult.errorCodes.length > 0) {
            if (locationResult.errorCodes.includes(403)) {
              this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('location.title').toLowerCase()]))
            } else {
              this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('location.title').toLowerCase()]))
            }
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('location.title').toLowerCase()]))
          }
        }
      }
      
      if (!this.isTagReadOnly) {
        const tagResult = await updateTags(customerId, customerLinkTagService, this.originTags, this.tags);
        if (tagResult.hasError) {
          hasError = true;
          this.alertMsg = this.$t(`customer.${method}_partial`);
          if (tagResult.errors.filter(i => i.response != null && i.response.status == 403).length > 0) {
            this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('tag.title').toLowerCase()]))
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('tag.title').toLowerCase()]))
          }
        }
      }
      
      if (!this.isStorageFileReadOnly) {
        const fileResult = await updateFiles(customerId, customerLinkFileService, this.originFiles, this.files)
        if (fileResult.hasError) {
          hasError = true;
          this.alertMsg = this.$t(`customer.${method}_partial`);
          if (fileResult.errorCodes.length > 0) {
            if (fileResult.errorCodes.includes(403)) {
              this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('file.title.list').toLowerCase()]))
            } else {
              this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('file.title.list').toLowerCase()]))
            }
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('file.title.list').toLowerCase()]))
          }
        }
      }

      //Notes
      if (!this.isNoteReadOnly) {
        //Remove uuId of new notes before saving
        const notes = cloneDeep(this.notes);
        for (let i = 0, len = notes.length; i < len; i++) {
          if (notes[i].uuId != null && notes[i].uuId.startsWith('NEW_NOTE')) {
            delete notes[i].uuId;
          }
        }
        const noteResult = await persistNotes(customerId, this.originNotes, notes);
        if (noteResult.errors.length > 0 || noteResult.errorCodes.length > 0) {
          hasError = true;
          this.alertMsg = this.$t(`customer.${method}_partial`);
          if (noteResult.errorCodes.length > 0) {
            if (noteResult.errorCodes.includes(403)) {
              this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('notes').toLowerCase()]))
            } else {
              this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('notes').toLowerCase()]))
            }
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('notes').toLowerCase()]))
          }
        }
      }
      

      this.state.isSubmitting = false;
      if (!hasError) {
        this.$emit('update:show', false);
        this.$emit('success', { msg: this.$t(`customer.${method}`), value: { uuId: customerId, name: data.name, color: data.color } });
      } else {
        this.scrollToTop();
      }
    },
    async updateCustomer(method, data) {
      const result = {
        hasError: false,
        msg: this.$t(`customer.${method}`)
      }

      removeDeniedProperties(this.permissionName, data, this.exists? 'EDIT':'ADD');
      
      let customerId = await customerService[method]([data])
      .then(response => {
        const data = response.data;
        return data[data.jobCase][0].uuId;
      }).catch(e => {
        result.hasError = true;
        result.msg = this.$t(`customer.error.failed_to_${method}_customer`);
        if(e.response && 422 == e.response.status) {
          const list = e.response.data[e.response.data.jobCase];
          let hasMissingArgument = false;
          for(let i = 0, len = list.length; i < len; i++) {
            if('Missing_argument' === list[i].clue) {
              this.errors.add({
                field: `customer.${list[i].args[0]}`,
                msg: this.$t('error.missing_argument', [this.$t(`customer.field.${list[i].args[0]}`)])
              });
              hasMissingArgument = true;
            }
          }
          if(hasMissingArgument) {
            result.msg = this.$t(`error.attention_required`);
          }
        }
      });
      result.customerId = customerId;
      return result;
    },
    httpAjaxError(e) {
      const response = e.response;
      if (response && 403 === response.status) {
        this.alertMsg = this.$t('error.authorize_action');
        
      } else if (response && 422 === response.status) {
        const feedback = response.data[response.data.jobCase][0];
        if(feedback.spot) {
          this.alertMsg = this.$t('error.attention_required');
          this.errors.add({
            field: `customer.${feedback.spot}`,
            msg: this.$t(`error.${feedback.clue}`, feedback.args)
          })
        } else {
          this.alertMsg = this.$t('error.internal_server');
        }
      } else {
        this.alertMsg = this.$t('error.internal_server');
      }
      this.scrollToTop();
    },
    scrollToTop() {
      setTimeout(() => {
        let elem = document.querySelector(`.${this.componentId}`);
        elem = elem != null? elem.querySelector('.modal-body') : null;
        elem = elem != null? elem.firstChild : null;
        if (elem != null && elem.scrollIntoView) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    },
    dismissAlert() {
      this.alertMsg = null;
      this.successMsg = null;
    },
    resetCustomerProperties() {
      const keys = Object.keys(this.customer);
      this.errors.clear();
      this.$validator.reset();
      const c = this.customer;
      for(let i = 0, len = keys.length; i < len; i++) {
        if(keys[i] === 'uuId') {
          continue;
        }
        
        let customField = this.customFields.find(f => f.name === keys[i])
        if (customField) {
          if (customField.def) {
            c[keys[i]] = customField.def;
            continue;
          }
        }
        c[keys[i]] = null;
      }
      if(c.websites == null) {
        c.websites = [null];
      }
      if(c.socials == null) {
        c.socials = [{ kind: null, data: null }];
      }

      this.avatarBanner.avatarId = null;
      this.avatarBanner.bannerId = null;
      this.locations = [];
      this.originLocations = [];
      this.contacts = [];
      this.originContacts = [];
      this.originCustomer = null;
      this.tags = [];
      this.originTags = [];
    },
    fieldItemAdd(key, index, _default) {
      this.customer[key].splice(index+1, 0, _default);
    },
    fieldItemRemove(key, index, _default) {
      this.customer[key].splice(index, 1);
      if (this.customer[key].length == 0) {
        this.customer[key].push(_default);
      }
    },
    locationBadgeRemove: function(index) {
      this.locations.splice(index,1)
    },
    locationBadgeClick(id) {
      this.locEditId = id;
      this.state.locSelectorShow = true;
    },
    locSelectorToggle() {
      this.locEditId = null;
      this.state.locSelectorShow = true;
    },
    locSelectorOk({ details }) {
      const locations = JSON.parse(JSON.stringify(this.locations));
      
      if (details.length > 0) {
        const newLocationsToReplacePreselected = [];
        for (const r of details) {
          const foundIndex = locations.findIndex(i => i.uuId == r.uuId);
          if (foundIndex > -1) {
            const location = locations[foundIndex];
            location.uuId = r.uuId;
            location.name = r.name;
            newLocationsToReplacePreselected.push(location);
            locations.splice(foundIndex, 1);
          } else {
            newLocationsToReplacePreselected.push({ uuId: r.uuId, name: r.name })
          }
        }

        if (this.locEditId != null) {
          const foundIndex = locations.findIndex(i => i.uuId == this.locEditId);
          if (foundIndex != -1) {
            locations.splice(foundIndex, 1, ...newLocationsToReplacePreselected);
          } else {
            locations.push(...newLocationsToReplacePreselected);
          }
        } else {
          locations.push(...newLocationsToReplacePreselected);
        }
      } else if (this.locEditId != null) {
        //When no selection is made, remove the preselected one from the existing locations
        locations.splice(locations.findIndex(j => j.uuId === this.locEditId), 1);
      }
      this.locations.splice(0, this.locations.length, ...locations);

      this.locEditId = null;
    },
    avatarBannerStatus({ alertMsg }) {
      if(alertMsg) {
        this.alertMsg = alertMsg;
      }
    },
    contactSuccess() {
      this.loadContact();
    },
    contactError({ msg }) {
      this.alertMsg = msg;
    },
    async loadContact() {
      let data = await customerService.query([{ uuId: this.id}], ['CONTACT'])
      .then((response) => {
        return response.data[response.data.jobCase] || [];
      })
      .catch((e) => {
        this.httpAjaxError(e);
        return [];
      });
      if (data.length > 0) {
        this.contacts.splice(0, this.contacts.length, ...(data[0].contactList || []));
        const container = this.$refs['contacts'];
        if (typeof container !== 'undefined') {
          container.scrollTop = container.scrollHeight;
        }
        
      }
    },
    fileSelectorToggle() {
      this.state.fileSelectorShow = true;
    },
    fileSelectorOk(details) {
      const currentIds = this.files.map(i => i.uuId);
      const newFiles = details.filter(i => !currentIds.includes(i.uuId));

      for(const c of newFiles) {
        this.files.push( {uuId: c.uuId, name: c.name, type: c.type} );
      }
    },
    downloadCancel() {
      if(this.downloadCancelTokenSource) {
        this.downloadCancelled = true;
        this.downloadCancelTokenSource.cancel();
      }
    },
    fileBadgeRemove: function(index) {
      this.files.splice(index,1);
    },
    fileBadgeClick(item) {
      // file download
      this.downloadProgressShow = true;
      forceFileDownload([{ uuId: item.uuId, name: labelFilename(item.name, item.type), type: item.type }], this);
    },
    addNote() {
      this.note = {
        text: null,
        identifier: null
      }
      this.state.noteShow = true;
    },
    editNote(id) {
      const found = this.notes.find(i => i.uuId == id);
      if (found != null) {
        this.note = cloneDeep(found);
        this.state.noteShow = true;
      } else {
        this.alertMsg = this.$t('unable_to_open_detail', ['entityType.NOTE']);
      }
    },
    removeNote(id) {
      const index = this.notes.findIndex(i => i.uuId == id);
      if (index != -1) {
        this.notes.splice(index, 1);
      }
    },
    toAddNote(payload) {
      payload.uuId = `NEW_NOTE_${strRandom(5)}`;
      this.notes.unshift(payload);
    },
    toUpdateNote(payload) {
      const found = this.notes.find(i => i.uuId == payload.uuId);
      if (found != null) {
        for (const key of Object.keys(payload)) {
          found[key] = payload[key];
        }
      }
    },
    removeUnchangedCustomerProperties(data) {
      //Remove those properties whose value is not changed in provided data against original customer.
      //Assuming all properties are string type.
      //Property with data type other than string needs dedicated comparison logic.
      const originalCustomer = this.originCustomer;
      const keys = Object.keys(data).filter(i => i != 'uuId');
      let hasChanged = false;
      
      const hasArrayChanged = (oValue, cValue) => {
        if (oValue == null && cValue == null) {
          return false;
        }
        if (oValue == null || cValue == null) {
          return true;
        }
        return JSON.stringify(oValue) != JSON.stringify(cValue);
      }

      for (const key of keys) {
        if (originalCustomer[key] === data[key]) {
          delete data[key];
          continue;
        } else if ('websites' == key || 'socials' == key) {
          if (!hasArrayChanged(originalCustomer[key], data[key])) {
            delete data[key];
            continue;
          }
        }
        if (!hasChanged) {
          hasChanged = true;
        }
      }
      return hasChanged;
    },
    tagsModified({tags}) {
      this.tags = tags;
    },
    allowViewFunc(fieldName) {
      return this.canView(this.permissionName, [fieldName]) 
              && ((!this.exists && this.canAdd(this.permissionName, [fieldName]) || this.exists))
    }
  }
}
</script>
<style>
.color-container {
  position: relative;
}
</style>