<template>
  <div :id="elemId" style="height: 100%; width: 100%">
    <b-modal v-model="selectorShow" size="xl" :title="selectorTitleLabel" footer-class="footerClass"
      no-close-on-backdrop  :modal-class="[elemId]"
      :no-close-on-esc="isCellEditing || editorShow"
      content-class="shadow" @ok="ok" @hidden="hidden">
      
      <AlertFeedback v-if="alertObj.msg != null" 
        :msg="alertObj.msg" 
        :details="alertObj.msgDetails.list" 
        :detailTitle="alertObj.msgDetails.title" 
        :alertState="alertObj.state" 
        @resetAlert="resetAlert"
      />

      <b-form-checkbox v-if="entity == 'RESOURCE' && projectIds.length !== 0" 
        class="bookings-switch" 
        v-model="bookings" 
        id="bookings-switch" 
        name="bookings-switch" 
        switch @change="onBookings">{{ $t('staff.project_bookings') }}</b-form-checkbox>

      <PriorityNavigation ref="generic-selector-grid-toolbar" class="grid-toolbar border" v-if="allowManage && entity != null"
        :dropDownStayOpened="badgeFilterModalOpened != 'close'" 
        :closeDropdown.sync="closePriorityNavDropdown" 
        @[priorityNavMouseOverEvent].native="onPriorityNavMouseOverOrTouchEnd"
        @[priorityNavTouchEndEvent].native="onPriorityNavMouseOverOrTouchEnd"
        :forceRefresh.sync="forcePriorityNavRefresh"
      >
        <li v-if="canAdd(entity)">
          <span :id="`BTN_ADD_${elemId}`">
            <b-btn @click="openEditor(true)"><font-awesome-icon :icon="['far', 'plus']" :style="{ color: 'var(--grid-toolbar-button)' }"/></b-btn>
          </span>
          <b-popover :target="`BTN_ADD_${elemId}`" triggers="hover" placement="top">
            {{ $t('button.add') }}
          </b-popover>
        </li>
        <li v-if="canView(entity)">
          <span :id="`BTN_EDIT_${elemId}`">
            <b-btn :disabled="disableEdit" @click="openEditor(false)"><font-awesome-icon :icon="['far', 'pen-to-square']"/></b-btn>
          </span>
          <b-popover :target="`BTN_EDIT_${elemId}`" triggers="hover" placement="top">
            {{ $t('button.edit') }}
          </b-popover>
        </li>
        <li v-if="canAdd(entity) && entity != 'WEBHOOK'">
          <span :id="`BTN_DUPLICATE_${elemId}`">
            <b-btn :disabled="disableDuplicate" @click="showDuplicateDialog"><font-awesome-icon :icon="['far','clone']"/></b-btn>  
          </span>
          <b-popover :target="`BTN_DUPLICATE_${elemId}`" triggers="hover" placement="top">
            {{ $t('button.duplicate') }}
          </b-popover>
        </li>
        <li v-if="entity == 'TAG' && canDelete(entity) && canAdd(entity)">
          <span :id="`BTN_MERGE_TAG_${elemId}`">
            <b-btn @click="mergeTag"><font-awesome-icon :icon="['far', 'merge']"/></b-btn>
          </span>
          <b-popover :target="`BTN_MERGE_TAG_${elemId}`" triggers="hover" placement="top">
            {{ $t('tag.button.merge') }}
          </b-popover>
        </li>
        <li v-if="canDelete(entity)">
          <span :id="`BTN_DELETE_${elemId}`">
            <b-btn :disabled="disableDelete" @click="rowDelete"><font-awesome-icon :icon="['far', 'trash-can']"/></b-btn>
          </span>
          <b-popover :target="`BTN_DELETE_${elemId}`" triggers="hover" placement="top">
            {{ $t('button.delete') }}
          </b-popover>
        </li>
        <li v-if="entity != 'USER' && entity != 'ACCESS_POLICY' && entity != 'WEBHOOK'">
          <template v-if="canAdd(entity)">
            <span :id="`BTN_IMPORT_DOCUMENT_${elemId}`">
              <b-btn @click="fileImport"><font-awesome-icon :icon="['far', 'inbox-in']"/></b-btn>
            </span>
            <b-popover :target="`BTN_IMPORT_DOCUMENT_${elemId}`" triggers="hover" placement="top">
              {{ $t(`entity_selector.${formattedEntity}_import_document`) }}
            </b-popover>
          </template>
          <span :id="`BTN_EXPORT_DOCUMENT_${elemId}`">
            <b-btn @click="fileExport"><font-awesome-icon :icon="['far', 'inbox-out']"/></b-btn>
          </span>
          <b-popover :target="`BTN_EXPORT_DOCUMENT_${elemId}`" triggers="hover" placement="top">
            {{ $t(`entity_selector.${formattedEntity}_export_document`) }}
          </b-popover>
        </li>
        <li v-if="entity != 'WEBHOOK' && canView(entity)" @[filterMouseEnterEvent]="onFilterOver" @mouseleave="onFilterLeave">
          <b-dropdown v-if="!filterPinned" :id="`BTN_FILTER_${elemId}`" ref="filter"
            class="action-bar-dropdown text-filter" 
            toggle-class="text-decoration-none" no-caret
            @hide="onFilterTextDropdownHide"
          >
            <template #button-content>
              <font-awesome-icon :class="filterText !== '' ? 'active' : ''" :icon="['far', 'file-magnifying-glass']"/>
            </template>
            
            <b-dropdown-form @submit.stop.prevent class="filter-padding">
              <b-input-group class="search-input">
                <b-form-input v-model="filterText" @focus="filterTextFocus = true" @blur="filterTextFocus = false" :placeholder="$t('task.filter')"  @keypress.enter="onFilterSubmit"></b-form-input>
                <b-input-group-append>
                  <b-btn :id="`BTN_CLEAR_FILTER_${elemId}`" @click="onFilterClear" class="search-append search-append-bg" size="sm"><font-awesome-icon :icon="['far', 'xmark']"/></b-btn>
                  <b-popover :target="`BTN_CLEAR_FILTER_${elemId}`" triggers="hover" placement="top">
                    {{ $t('button.clear') }}
                  </b-popover>
                </b-input-group-append>
                <b-input-group-append>
                  <b-btn :id="`BTN_SEARCH_FILTER_${elemId}`" @click="onFilterSubmit" class="search-append search-append-bg" size="sm"><font-awesome-icon :icon="['far', 'magnifying-glass']"/></b-btn>
                  <b-popover :target="`BTN_SEARCH_FILTER_${elemId}`" triggers="hover" placement="top">
                    {{ $t('button.search') }}
                  </b-popover>
                </b-input-group-append>
                <b-input-group-append>
                  <b-btn :id="`BTN_PIN_FILTER_${elemId}`" @click="onPinFilter" class="search-append search-append-bg" size="sm"><font-awesome-icon :icon="['far', 'thumbtack']"/></b-btn>
                  <b-popover :target="`BTN_PIN_FILTER_${elemId}`" triggers="hover" placement="top">
                    {{ $t('button.pinOnToolbar') }}
                  </b-popover>
                </b-input-group-append>
                
              </b-input-group>
            </b-dropdown-form>
            
          </b-dropdown>
        </li>
        <li>
          <b-input-group class="search-input" v-if="filterPinned">
            <b-form-input v-model="filterText" @focus="filterTextFocus = true" @blur="filterTextFocus = false" :placeholder="$t('task.filter')"  @keypress.enter="onFilterSubmit"></b-form-input>
            <b-input-group-append>
              <b-btn :id="`BTN_CLEAR_FILTER_${elemId}`" @click="onFilterClear" class="search-append search-append-bg" size="sm"><font-awesome-icon icon="times"/></b-btn>
              <b-popover :target="`BTN_CLEAR_FILTER_${elemId}`" triggers="hover" placement="top">
                {{ $t('button.clear') }}
              </b-popover>
            </b-input-group-append>
            <b-input-group-append>
              <b-btn :id="`BTN_SEARCH_FILTER_${elemId}`" @click="onFilterSubmit" class="search-append search-append-bg" size="sm"><font-awesome-icon :icon="['far', 'magnifying-glass']"/></b-btn>
              <b-popover :target="`BTN_SEARCH_FILTER_${elemId}`" triggers="hover" placement="top">
                {{ $t('button.search') }}
              </b-popover>
            </b-input-group-append>
            <b-input-group-append>
              <b-btn :id="`BTN_UNPIN_FILTER_${elemId}`" @click="onUnPinFilter" class="search-append search-append-bg" size="sm"><font-awesome-icon :icon="['far', 'thumbtack']"/></b-btn>
              <b-popover :target="`BTN_UNPIN_FILTER_${elemId}`" triggers="hover" placement="top">
                {{ $t('button.unpinFromToolbar') }}
              </b-popover>
            </b-input-group-append>
          </b-input-group>
        </li>
        <li v-if="entity != 'WEBHOOK' && canView(entity)" @[badgeFilterMouseEnterEvent]="onBadgeFilterEnter" 
            @[badgeFilterMouseOverEvent]="onBadgeFilterOver" 
            @[badgeFilterMouseLeaveEvent]="onBadgeFilterLeave" 
            @[badgeFilterTouchEndEvent]="onBadgeFilterOver">
          <b-dropdown v-if="!badgeFilterPinned" :id="`BTN_BADGE_FILTER_${elemId}`" ref="badgeFilter"
            class="action-bar-dropdown text-filter" 
            toggle-class="text-decoration-none" no-caret
            @hide="onBadgeFilterDropdownHide"
          >
            <template #button-content>
              <font-awesome-icon :class="badgeFilters != null && badgeFilters.length > 0 ? 'active' : ''" :icon="['far', 'filter']"/>
            </template>
            
            <b-dropdown-form @submit.stop.prevent class="filter-padding">
              <TaskViewBadgeFilter :filters="badgeFilters" 
                :fields="badgeFilterFields" 
                :fieldValues="badgeFilterFieldValues" 
                canPin
                @modified="onBadgeFilterModified" 
                @filterModalOpened="onBadgeFilterModalOpened"
                @filterModalClosed="onBadgeFilterModalClosed"
                @fetchFieldOptions="onBadgeFilterFetchOptions"
                @pin="onPinBadgeFilter"
                />
            </b-dropdown-form>
            
          </b-dropdown>
        </li>
        <li>
          <TaskViewBadgeFilter v-if="badgeFilterPinned"
            :filters="badgeFilters" 
            :fields="badgeFilterFields" 
            :fieldValues="badgeFilterFieldValues" 
            canPin
            pinned
            @modified="onBadgeFilterModified" 
            @filterModalOpened="onBadgeFilterModalOpened"
            @filterModalClosed="onBadgeFilterModalClosed"
            @fetchFieldOptions="onBadgeFilterFetchOptions"
            @pin="onUnPinBadgeFilter"
            />
        </li>
        <li v-if="canSyncLdap(entity) && $store.state.data.status.ldapEnabled && entity === 'USER'">
          <span :id="`BTN_SYNC_${elemId}`">
            <b-btn @click="syncLdap"><font-awesome-icon :icon="['far', 'down-to-bracket']"/></b-btn>
          </span>
          <b-popover :target="`BTN_SYNC_${elemId}`" triggers="hover" placement="top">
            {{ $t('button.sync_ldap') }}
          </b-popover>
        </li>
        <li v-if="entity === 'USER' && hasPermission('AUTHORING_AS_ALIAS_USER')">
          <span :id="`BTN_ALIAS_${elemId}`">
            <b-btn :disabled="entitySelection.length !== 1 || !entitySelection[0].enabled || userId === entitySelection[0].uuId" @click="alias"><font-awesome-icon :icon="['far', 'user-group']"/></b-btn>
          </span>
          <b-popover :target="`BTN_ALIAS_${elemId}`" triggers="hover" placement="top">
            {{ $t('button.alias') }}
          </b-popover>
        </li>
        <li v-if="entity != 'WEBHOOK'" class="view" @[viewMouseEnterEvent]="onViewOver" @mouseleave="onViewLeave">
          <b-dropdown :id="`BTN_VIEW_${elemId}`" ref="view" class="action-bar-dropdown" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <font-awesome-icon :icon="['far','desktop']"/>
            </template>
            
            <b-dropdown-item @click="showAllColumns" href="#">
              <span class="action-item-label">{{ $t('task.button.all_columns') }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="showNoColumns" href="#">
              <span class="action-item-label">{{ $t('task.button.no_columns') }}</span>
            </b-dropdown-item>
            <b-dropdown-divider/>
            <b-dropdown-item @click="savePreset" href="#">
              <span class="action-item-label">{{ $t(`${entity? (entity == 'TEMPLATE__PROJECT'? 'template'+'.' : formattedEntity+'.') : ''}button.save_view`) }}</span>
            </b-dropdown-item>
            <b-dropdown-divider/>
            <template v-for="(item, index) in filteredViews">
              <b-dropdown-item class="action-item" :disabled="inProgressShow" @click="loadViewSettings(item)" href="#" :key="index">
                <span :id="`viewname${index}`" class="action-item-label-with-icon">{{ item.name }}</span>
                <b-popover
                  v-if="isEllipsisActive(item.name)"
                  :target="`viewname${index}`"
                  placement="top"
                  boundary="viewport"
                  custom-class="popover-margin"
                  triggers="hover"
                  offset="-100"
                  :content="item.name">
                </b-popover> 
                <span>
                  <template v-if="!editPermission(item)">
                    <span class="action-item-icon"
                        :id="`COPY_COLUMN_${index}`"
                        @click.stop.prevent="copyColumnSettings(item.name, item)">
                      <font-awesome-icon class="" :icon="['far','copy']"/>
                    </span>
                    <b-popover :target="`COPY_COLUMN_${index}`" triggers="hover" placement="top" boundary="viewport">
                      {{ $t(`${entity? (entity == 'TEMPLATE__PROJECT'? 'template'+'.' : formattedEntity+'.') : ''}button.copy_view`) }}
                    </b-popover>
                    <span class="action-item-icon position-third" 
                        @[infoMouseEnterEvent]="onInfoOver(index)" @mouseleave="onInfoLeave(index)"
                        :id="`BTN_INFO_${index}`">
                      <font-awesome-icon class="" :icon="['far','circle-info']"/>
                    </span>
                    <b-popover
                      :target="`BTN_INFO_${index}`"
                      :ref="`BTN_INFO_${index}`"
                      :show.sync="showInfo[index]"
                      placement="top"
                      boundary="viewport"
                      triggers="manual"
                      :content="$t('owner', [views[index].owner])">
                    </b-popover>
                  </template>
                  <template v-if="editPermission(item)">
                    <span class="action-item-icon position-third"
                        :id="`SHARE_COLUMN_${index}`"
                        @click.stop.prevent="shareColumnSettings(index, item.name, item)">
                      <font-awesome-icon class="" :icon="[item.defaultView ? 'fas' : 'far','share-nodes']"/>
                    </span>
                    <b-popover :target="`SHARE_COLUMN_${index}`" triggers="hover" placement="top" boundary="viewport">
                      {{ $t(`${entity? (entity == 'TEMPLATE__PROJECT'? 'template'+'.' : formattedEntity+'.') : ''}button.share_view`) }}
                    </b-popover>
                  </template>
                  <template v-if="editPermission(item)">
                    <span class="action-item-icon position-second" 
                        :id="`UPDATE_COLUMN_${index}`"
                        @click.stop.prevent="updateColumnSettings(index, item.name, item)">
                      <font-awesome-icon class="" :icon="['far', 'floppy-disk']"/>
                    </span>
                    <b-popover :target="`UPDATE_COLUMN_${index}`" triggers="hover" placement="top" boundary="viewport">
                      {{ $t(`${entity? (entity == 'TEMPLATE__PROJECT'? 'template'+'.' : formattedEntity+'.') : ''}button.update_view`) }}
                    </b-popover>
                  </template>
                  <template v-if="editPermission(item)">
                    <span class="action-item-icon"
                        :id="`REMOVE_COLUMN_${index}`"
                        @click.stop.prevent="removeColumnSettings(index)">
                      <font-awesome-icon class="" :icon="['far', 'trash-can']"/>
                    </span>
                    <b-popover :target="`REMOVE_COLUMN_${index}`" triggers="hover" placement="top" boundary="viewport">
                      {{ $t(`${entity? (entity == 'TEMPLATE__PROJECT'? 'template'+'.' : formattedEntity+'.') : ''}button.delete_view`) }}
                    </b-popover>
                  </template>
                </span>
              </b-dropdown-item>
            </template>
            
          </b-dropdown>
        </li>
        <template v-if="entity != 'ACCESS_POLICY' && entity != 'TAG' && entity != 'WEBHOOK'">
          <li @[colorMouseEnterEvent]="onColoringOver" @mouseleave="onColoringLeave">
            <b-dropdown :id="`BTN_COLORING_${elemId}`" ref="coloring" class="action-bar-dropdown" toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <font-awesome-icon :icon="['far', 'palette']"/>
              </template>
              <b-dropdown-group :header="$t('colorby')">
                <template v-for="(value, name, index) in coloring">
                  <b-dropdown-item @click="onColorChange(name)" href="#" :key="index">
                    <span class="action-item-label">{{ $t(`entity_selector.coloring_${name}`) }}</span><font-awesome-icon class="active-check" v-if="coloring[name]" :icon="['far', 'check']"/>
                  </b-dropdown-item>
                </template>
              </b-dropdown-group>
            </b-dropdown>
          </li>
        </template>
        
      </PriorityNavigation>
            
      <ag-grid-vue style="width: 100%" class="spreadsheet ag-theme-balham generic-selector selector-grid-height" id="rebate-grid"
            :gridOptions="gridOptions"
            @grid-ready="onGridReady"
            :columnDefs="columnDefs"
            :context="context"
            :defaultColDef="defaultColDef"
            :getRowId="params => params.data.uuId"
            pagination
            :paginationPageSize="1000"
            :paginationPageSizeSelector="false"
            :cacheBlockSize="10000"
            rowModelType="serverSide"
            :rowMultiSelectWithClick="nonAdmin"
            :rowSelection="singleSelection? 'single' : 'multiple'"
            :serverSideInfiniteScroll="true"
            :sideBar="false"
            suppressContextMenu
            suppressDragLeaveHidesColumns
            :suppressCellFocus="nonAdmin"
            :singleClickEdit="false"
            :suppressMultiSort="false"
            :enableRangeSelection="!nonAdmin"
            :suppressRowClickSelection="!nonAdmin"

            :enableFillHandle="!nonAdmin"
            :fillOperation="fillOperation"
            fillHandleDirection="xy"
            :processCellForClipboard="processCellForClipboard"
            :processCellFromClipboard="processCellFromClipboard"
            suppressClipboardApi
            :navigateToNextCell="navigateToNextCell"
            :tabToNextCell="tabToNextCell"
            @cell-key-down="onCellKeyDown"
            @paste-start="onPasteStart"
            @paste-end="onPasteEnd"
            @cell-focused="cellFocused"

            noRowsOverlayComponent="noRowsOverlay"
            :noRowsOverlayComponentParams="noRowsOverlayComponentParams"
            
            :overlayLoadingTemplate="overlayLoadingTemplate"
            enableCellEditingOnBackspace

            @cell-editing-started="onCellEditingStarted"
            @cell-editing-stopped="onCellEditingStopped"
            >
     </ag-grid-vue>

      <template v-slot:modal-footer="{ ok, cancel }">
        <template v-if="nonAdmin">
          <b-button :disabled="disableOk" size="sm" variant="success" @click="ok()">{{ $t('button.ok') }}</b-button>
          <b-button size="sm" variant="danger" @click="cancel()">{{ $i18n.t('button.cancel') }}</b-button>
        </template>
        <b-button v-else size="sm" variant="danger" @click="cancel()">{{ $i18n.t('button.close') }}</b-button>
      </template>
    </b-modal>

    <component v-if="currentEditorComponent != null" 
      :is="currentEditorComponent" 
      :show.sync="editorShow" 
      :id="entityId"
      :title="editorTitleLabel"
      @success="editorSuccess" 
      
    />

    <b-modal :title="duplicateTitleLabel"
        v-model="duplicateShow"
        @hidden="duplicateCancel"
        content-class="shadow"
        no-close-on-backdrop
        >
      <template v-if="entity=='USER'">
        <b-form-group :label="$t('user.field.email')" label-for="duplicateEmail">
          <b-input-group>
            <b-form-input id="duplicateEmail" type="text"
              :data-vv-as="$t('user.field.email')"
              data-vv-name="duplicateEmail"
              data-vv-delay="500"
              v-model="duplicateEmail"/>
          </b-input-group>
          <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showDuplicateEmailError }">
            <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('duplicateEmail') }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group :label="$t('user.field.firstName')" label-for="duplicateFirstname">
          <b-input-group>
            <b-form-input id="duplicateFirstname" type="text"
              v-model="duplicateFirstname"/>
          </b-input-group>
        </b-form-group>

        <b-form-group :label="$t('user.field.lastName')" label-for="duplicatLastname">
          <b-input-group>
            <b-form-input id="duplicatLastname" type="text"
              :data-vv-as="$t('user.field.lastName')"
              data-vv-name="duplicateLastname"
              data-vv-delay="500"
              v-model="duplicateLastname"/>
          </b-input-group>
          <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showDuplicateLastnameError }">
            <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('duplicateLastname') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </template>
      <template v-else-if="entity=='WEBHOOK'">
        <b-form-group :label="$t('webhook.field.entity')" label-for="duplicateEntityField">
          <b-input-group>
            <b-form-input id="duplicateEntityField" type="text"
              :data-vv-as="$t('webhook.field.entity')"
              data-vv-name="duplicateEntityField"
              data-vv-delay="500"
              v-model="duplicateEntityField"/>
          </b-input-group>
          <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showDuplicateEntityError }">
            <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('duplicateEntityField') }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group :label="$t('webhook.field.action')" label-for="duplicateAction">
          <b-input-group>
            <b-form-input id="duplicateAction" type="text"
              :data-vv-as="$t('webhook.field.action')"
              data-vv-name="duplicateAction"
              data-vv-delay="500"
              v-model="duplicateAction"/>
          </b-input-group>
          <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showDuplicateActionError }">
            <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('duplicateAction') }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group :label="$t('webhook.field.url')" label-for="duplicateUrl">
          <b-input-group>
            <b-form-input id="duplicateUrl" type="text"
              :data-vv-as="$t('webhook.field.url')"
              data-vv-name="duplicateUrl"
              data-vv-delay="500"
              v-model="duplicateUrl"/>
          </b-input-group>
          <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showDuplicateUrlError }">
            <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('duplicateUrl') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </template>
      <template v-else>
        <b-form-group :label="duplicateFieldNameLabel" label-for="name">
          <b-input-group>
            <b-form-input id="duplicateName" type="text"
              :data-vv-as="duplicateFieldNameLabel"
              data-vv-name="duplicate.name"
              data-vv-delay="500"
              trim
              v-model="duplicateName"/>
          </b-input-group>
          <b-input-group class="mt-1" v-if="entity=='PROJECT'">
            <b-form-checkbox v-model="includeTasks">{{ $t('project.duplicate_tasks') }}</b-form-checkbox>
          </b-input-group>
          <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showDuplicateNameError }">
            <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('duplicate.name') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </template>

      <template v-slot:modal-footer="{ cancel }">
          <b-button v-if="duplicateInProgress" disabled size="sm" variant="success"><b-spinner small type="grow" />{{ $t('button.processing') }}</b-button>
          <b-button v-else size="sm" variant="success" @click="duplicateOk">{{ $t('button.duplicate') }}</b-button>
          <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <template v-if="entity != 'USER' && entity != 'ACCESS_POLICY'">
      <GanttImportDialog v-if="entity != null"
        :properties="docImportProperties" 
        :mode="ganttImportMode" :show="docImportShow"
        :existingData="existingData"
        :title="$t(`entity_selector.${formattedEntity}_import_document`)"
        @modal-ok="docImportOk"
        @modal-cancel="docImportCancel" />
    </template>
    
    <InProgressModal :show.sync="inProgressShow" :label="inProgressLabel" :isStopable="inProgressStoppable"/>

    <b-modal 
        :title="ecTitle"
        v-model="ecConfirmDeleteShow"
        @hidden="ecConfirmDeleteShow=false"
        @ok="ecConfirmDeleteOk"
        @cancel="ecConfirmDeleteCancel"
        content-class="entity-delete-modal shadow"
        no-close-on-backdrop
        >
      
      <p>{{ ecConfirmDeleteStatement }}</p>
      
      <template v-slot:modal-footer="{ ok, cancel }">
        <template v-if="entityCol.cantDeleteDetails == null">
          <b-form-checkbox v-if="ecShowApplyAllCheckbox" class="apply-to-all" v-model="entityCol.applyAll">{{ $t('apply_to_all') }}</b-form-checkbox>
          <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
          <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
        </template>
        <template v-else>
          <b-button size="sm" variant="success" @click="ok()">{{ $t('button.ok') }}</b-button>
        </template>
      </template>
    </b-modal>

    <b-modal v-if="entity === 'LOCATION'" :title="$t('location.confirmation.title_delete')"
        v-model="cannotDeleteModalShow"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{  $t('location.error.location_in_use') }}
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="cancel()">{{ $t('button.close') }}</b-button>
      </template>
    </b-modal>
    
    <template v-if="entity == 'TAG'">
      <b-modal :title="$t('tag.merge_title')"
          v-model="mergeTagShow"
          content-class="shadow"
          no-close-on-backdrop
          >
        <div class="container pl-0">
          <b-row>
            <b-col cols="12" class="pr-0">
              <b-form-group :label="$t('tag.merge_from')" label-for="name">
                <multiselect v-model="mergeFrom" class="custom-dropdown-options enable-option-icon"
                  :max-height="300"
                  :options="mergeOptions"
                  :placeholder="''"
                  :searchable="false" 
                  :allow-empty="false"
                  :showLabels="false">
                  <template slot="option" slot-scope="props">
                    <font-awesome-icon class="selected-option-icon" v-if="mergeFrom == props.option" :icon="['far', 'check']" />
                    <span class="option__title">{{ props.option }}</span>
                  </template>
                </multiselect>
              </b-form-group>
            </b-col>
            <b-col cols="12" class="pr-0">
              <b-form-group :label="$t('tag.merge_to')" label-for="name">
                <multiselect v-model="mergeTo" class="custom-dropdown-options enable-option-icon"
                  :max-height="300"
                  :options="mergeOptions"
                  :placeholder="''"
                  :searchable="false" 
                  :allow-empty="false"
                  :showLabels="false">
                  <template slot="option" slot-scope="props">
                    <font-awesome-icon class="selected-option-icon" v-if="mergeTo == props.option" :icon="['far', 'check']" />
                    <span class="option__title">{{ props.option }}</span>
                  </template>
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        

        <template v-slot:modal-footer="{ cancel }">
            <b-button v-if="duplicateInProgress" disabled size="sm" variant="success"><b-spinner small type="grow" />{{ $t('button.processing') }}</b-button>
            <b-button :disabled="mergeFrom === null || mergeTo === null || mergeFrom === mergeTo" v-else size="sm" variant="success" @click="mergeOk">{{ $t('tag.button.merge') }}</b-button>
            <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
        </template>
      </b-modal>
    </template>

    <SaveViewModal :show.sync="promptSaveShow" :name="saveName" :title="$t('task.confirmation.save')" :profile="saveProfile" @ok="confirmSaveOk"/>
    <SaveViewModal :show.sync="promptShareShow" :name="saveName" :title="$t('task.confirmation.share')" :sharing="true" :profile="saveProfile" @ok="confirmSaveOk"/>

    <b-modal :title="$t('task.confirmation.title_delete')"
        v-model="confirmDeleteViewShow"
        @ok="confirmDeleteViewOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t('task.confirmation.delete_view') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

  </div>
</template>

<script>
import 'ag-grid-enterprise'
import { AgGridVue } from 'ag-grid-vue'

import alertStateEnum from '@/enums/alert-state'
import { cloneDeep, debounce } from 'lodash'
import { strRandom, objectClone, invertColor, randomString, isEllipsisActive, EventBus } from '@/helpers'
import { getCustomFieldInfo, handleCustomFieldError, getCustomFieldExportDataPropertyHandler } from '@/helpers/custom-fields'
import { fieldValidateUtil } from '@/script/helper-field-validate'
import { convertDisplayToDuration, extractDurationConversionOpts } from '@/helpers/task-duration-process'
import { columnDefSortFunc, isPropertyCompatible } from '@/views/management/script/common'

import ListFilter from '@/components/ListFilter/ListFilter'
import { viewProfileService, tagService, compositeService, companyService, profileService } from '@/services'
import DetailLinkCellRenderer from '@/components/Aggrid/CellRenderer/DetailLink'
import ColorCellRenderer from '@/components/Aggrid/CellRenderer/Color'
import RebateCellRenderer from '@/components/Aggrid/CellRenderer/Rebate'
import PayFrequencyRenderer from '@/components/Aggrid/CellRenderer/PayFrequency'
import CostCellRenderer from '@/components/Aggrid/CellRenderer/Cost'
import DateOnlyCellRenderer from '@/components/Aggrid/CellRenderer/DateOnly'
import PercentageCellRenderer from '@/components/Aggrid/CellRenderer/Percentage'
import RowSelectorCellRenderer from '@/components/Aggrid/CellRenderer/SelectorRowSelector'
import SelectorHeaderComponent from '@/components/Aggrid/CellHeader/SelectorRangeSelection'
import EnumCellRenderer from '@/components/Aggrid/CellRenderer/Enum'
import SkillLevelCellRenderer from '@/components/Aggrid/CellRenderer/SkillLevel'
import DurationCellRenderer from '@/components/Aggrid/CellRenderer/Duration'
import GenericEntityArrayCellRenderer from '@/components/Aggrid/CellRenderer/GenericEntityArray'
import GenericObjectCellRenderer from '@/components/Aggrid/CellRenderer/GenericObject'
import GenericCellRenderer from '@/components/Aggrid/CellRenderer/Generic'
import StatusCellRenderer from '@/components/Aggrid/CellRenderer/Status'
import BooleanCellRenderer from '@/components/Aggrid/CellRenderer/Boolean'
import AvatarCellRenderer from '@/components/Aggrid/CellRenderer/Avatar';

import NameEditor from '@/components/Aggrid/CellEditor/Name'
import PercentageEditor from '@/components/Aggrid/CellEditor/Percentage'
import StringEditor from '@/components/Aggrid/CellEditor/String'
import TagEditor from '@/components/Aggrid/CellEditor/Tag'
import ColorEditor from '@/components/Aggrid/CellEditor/Color'
import MultilineEditor from '@/components/Aggrid/CellEditor/Multiline'
import ListEditor from '@/components/Aggrid/CellEditor/List'
import CostEditor from '@/components/Aggrid/CellEditor/Cost'
import SkillLevelEditor from '@/components/Aggrid/CellEditor/SkillLevel'
import PlainPercentageEditor from '@/components/Aggrid/CellEditor/PlainPercentage'
import CustomersEditor from '@/components/Aggrid/CellEditor/Customers'
import DateTimeEditor from '@/components/Aggrid/CellEditor/DateTime'
import CompaniesEditor from '@/components/Aggrid/CellEditor/Companies'
import LocationsEditor from '@/components/Aggrid/CellEditor/Locations'
import RebatesEditor from '@/components/Aggrid/CellEditor/Rebates'
import StatusEditor from '@/components/Aggrid/CellEditor/Status'
import ProjectsEditor from '@/components/Aggrid/CellEditor/Projects'
import WebsitesEditor from '@/components/Aggrid/CellEditor/Websites'
import SocialsEditor from '@/components/Aggrid/CellEditor/Socials'
import AccessPolicyEditor from '@/components/Aggrid/CellEditor/AccessPolicy'
import NumericEditor from '@/components/Aggrid/CellEditor/Numeric'
import FloatNumericEditor from '@/components/Aggrid/CellEditor/FloatNumeric'
import IntegerNumericEditor from '@/components/Aggrid/CellEditor/IntegerNumeric'

import BlankHeaderComponent from '@/components/Aggrid/CellHeader/Blank'

import RebateModal from '@/components/modal/RebateModal.vue'
import ResourceModal from '@/components/modal/ResourceModal.vue'
import SkillModal from '@/components/modal/SkillModal.vue'
import LocationModal from '@/components/modal/LocationModal.vue'
import TaskTemplateModal from '@/components/modal/TaskTemplateModal'
import StageModal from '@/components/modal/StageModal'
import ProjectModal from '@/components/modal/ProjectModal'
import CustomerModal from '@/components/modal/CustomerModal'
import UserModal from '@/components/modal/UserModal'
import AccessPolicyModal from '@/components/modal/AccessPolicyModal'
import TagModal from '@/components/modal/TagModal'
import WebhookModal from '@/components/modal/WebhookModal'

import NoRowsOverlay from '@/components/Aggrid/Overlay/NoRows'
import PriorityNavigation from '@/components/PriorityNavigation/PriorityNavigation';
import Multiselect from 'vue-multiselect';

const operationStatus = {
  SUCCESS: 'SUCCESS'
  , ABORT: 'ABORT'
}
Object.freeze(operationStatus)
const operationStatusKeys = Object.keys(operationStatus)

const CELL_COPY_CODE = '_COPIED_OBJ='

function ServerSideDatasource(self) {
  return {
    getRows(params) {
      if (!self.keepAlertFeedback) {
        self.resetAlert()
      }
      self.keepAlertFeedback = false
      const list = typeof self.entityService.listFunc == 'function'? self.entityService.listFunc(self) : self.entityService.list
      if (self.entityService == null || list == null || self.entityService.buildParams == null || self.lackOfMandatoryField()) {
        params.success({ rowData: [], rowCount: 0 });
        self.showNoRowLabel(self.$t('entity_selector.error.insufficient_permission_to_show_data'))
        return
      }
      
      const buildParams = self.entityService.buildParams
      const companyUuId = self.$store.state.company && self.$store.state.company.type !== 'Primary' ? self.$store.state.company.uuId : null //Used by Skill Selector
      const buildParamsSecondArgPayload = { 
        exportData: self.exportData
        , searchFilter: self.filterText
        , badgeFilters: self.badgeFilters
        , nonAdmin: self.nonAdmin
        , self
      }

      if (self.entity == 'RESOURCE') {
        buildParamsSecondArgPayload.projectIds = self.projectIds //used by RESOURCE
        buildParamsSecondArgPayload.bookings = self.bookings //used by RESOURCE
      }

      if (self.nonAdmin && self.entity == 'STAGE') {
        buildParamsSecondArgPayload.tagFilter = self.canView('TAG') && self.canView('STAGE', ['TAG'])? self.tagFilter : null//used by STAGE
        buildParamsSecondArgPayload.tagFilterState = self.tagFilterState //used by STAGE
        buildParamsSecondArgPayload.entityOptions = self.entityOptions //used by STAGE
      }

      if (self.entity == 'PROJECT') {
        buildParamsSecondArgPayload.projectIds = self.projectIds //used by PROJECT
      }

      const processResponse = (response) => {
        self.totalRecords = response.arg_total
        params.success({ rowData: response.data, rowCount: response.arg_total })
        if (self.entity == 'TAG') {
          // save the names in a map so we can check for duplicates
          self.valuesMap = response.data.reduce(function(map, obj) {
            map[obj.name.toLowerCase()] = obj;
            return map;
          }, {});
          self.mergeOptions = response.data.map(d => { return d.name });
        }

        if(params.api != null && !params.api.isDestroyed() && self.show) {
          if (self.previousVScrollPosition != null && params.api.gridBodyCtrl && params.api.gridBodyCtrl.bodyScrollFeature) {
            params.api.gridBodyCtrl.bodyScrollFeature.setVerticalScrollPosition(self.previousVScrollPosition)
          }
          if (self.lastFocusedCell != null) {
            self.resetFocus(params.api, self.lastFocusedCell)
          }

          //For non admin, mark row selected based on entitySelection
          if (self.nonAdmin && self.entitySelection && self.entitySelection.length > 0) {
            for (const e of self.entitySelection) {
              if (e?.uuId == null) {
                continue;
              }
              const found = params.api.getRowNode(e.uuId);
              if (found != null) {
                found.setSelected(true);
              }
            }
          }
        }
        if (self.inProgressShow) {
          self.inProgressShow = false
        }
        if (params.api != null && !params.api.isDestroyed()) {
          params.api.hideOverlay()
        }
        
        if (response.arg_total === 0) {
          self.showNoRowLabel(null)
        }
      }

      let request = null
      //Non admin STAGE selector has a special fallback logic to list the data
      if (self.entity == 'STAGE' && self.nonAdmin) {
        request = list(buildParams(params, buildParamsSecondArgPayload), { self })
        .then(response => {
          if (self.tagFilterState == 2 && response.arg_total == 0 && (self.searchFilter == null || self.searchFilter.trim().length == 0)) {
            buildParamsSecondArgPayload.tagFilterState = self.tagFilterState = 1; //used by STAGE
            list(buildParams(params, buildParamsSecondArgPayload), { self }).then(response1 => {
              if (self.tagFilterState == 1 && response1.arg_total == 0 && (self.searchFilter == null || self.searchFilter.trim().length == 0)) {
                buildParamsSecondArgPayload.tagFilterState = self.tagFilterState = 0;
                list(buildParams(params, buildParamsSecondArgPayload), { self }).then(response2 => {
                  processResponse(response2);
                })
                .catch(e => {
                  params.success({ rowData: [], rowCount: 0 });
                  if (e != null && e.response != null && e.response.status == 403) {
                    self.showNoRowLabel(self.$t('entity_selector.error.insufficient_permission_to_show_data'))
                  }
                });
              } else {
                processResponse(response1);
              }
            })
            .catch(e => {
              params.success({ rowData: [], rowCount: 0 });
              if (e != null && e.response != null && e.response.status == 403) {
                self.showNoRowLabel(self.$t('entity_selector.error.insufficient_permission_to_show_data'))
              }
            });
          } else if (self.tagFilterState == 1 && response.arg_total == 0 && (self.searchFilter == null || self.searchFilter.trim().length == 0)) {
            buildParamsSecondArgPayload.tagFilterState = self.tagFilterState = 0;
            list(buildParams(params, buildParamsSecondArgPayload), { self }).then(response2 => {
              processResponse(response2);
            })
            .catch(e => {
              params.success({ rowData: [], rowCount: 0 });
              if (e != null && e.response != null && e.response.status == 403) {
                self.showNoRowLabel(self.$t('entity_selector.error.insufficient_permission_to_show_data'))
              }
            });
          } else {
            processResponse(response);
          }
        })
      } else { // generic logic for other selector
        request = list(buildParams(params, buildParamsSecondArgPayload), { companyId: companyUuId, self })
        .then(response => {
          processResponse(response)
        })
      }
      
      request.catch(e => {
        params.success({ rowData: [], rowCount: 0 });
        if (e != null && e.response != null && e.response.status == 403) {
          self.showNoRowLabel(self.$t('entity_selector.error.insufficient_permission_to_show_data'))
        } else {
          //Print out the unexpected error
          console.error(e) //eslint-disable-line no-console
        }
      })
    }
  }
}
import currencies from '@/views/management/script/currencies'

export default {
  name: 'GenericSelectorModalForAdmin'
  // , emits: ['ok', 'cancel']
  , components: {
    'ag-grid-vue': AgGridVue
    , AlertFeedback: () => import('@/components/AlertFeedback')
    , InProgressModal: () => import('@/components/modal/InProgressModal')
    , GanttImportDialog: () => import('@/components/Gantt/components/GanttImportDialog')
    , TaskViewBadgeFilter: () => import('@/components/Filter/TaskViewBadgeFilter.vue')
    , SaveViewModal: () => import('@/components/modal/SaveViewModal.vue')
    , PriorityNavigation
    , ListFilter
    , Multiselect
    //Renderer
    , detailLinkCellRenderer: DetailLinkCellRenderer
    , colorCellRenderer: ColorCellRenderer
    , rebateCellRenderer: RebateCellRenderer
    , payFrequencyRenderer: PayFrequencyRenderer
    , costCellRenderer: CostCellRenderer
    , rowSelectorCellRenderer: RowSelectorCellRenderer
    , selectorHeaderComponent: SelectorHeaderComponent
    , percentageCellRenderer: PercentageCellRenderer
    , enumCellRenderer: EnumCellRenderer
    , skillLevelCellRenderer: SkillLevelCellRenderer
    , durationCellRenderer: DurationCellRenderer
    , genericEntityArrayCellRenderer: GenericEntityArrayCellRenderer
    , dateOnlyCellRenderer: DateOnlyCellRenderer
    , genericObjectCellRenderer: GenericObjectCellRenderer
    , genericCellRenderer: GenericCellRenderer
    , statusCellRenderer: StatusCellRenderer
    , booleanCellRenderer: BooleanCellRenderer
    , avatarCellRenderer: AvatarCellRenderer
    //Editor
    , nameEditor: NameEditor
    , stringEditor: StringEditor
    , tagEditor: TagEditor
    , percentageEditor: PercentageEditor
    , colorEditor: ColorEditor
    , multilineEditor: MultilineEditor
    , listEditor: ListEditor
    , costEditor: CostEditor
    , skillLevelEditor: SkillLevelEditor
    , plainPercentageEditor: PlainPercentageEditor
    , customersEditor: CustomersEditor
    , dateTimeEditor: DateTimeEditor
    , companiesEditor: CompaniesEditor
    , locationsEditor: LocationsEditor
    , rebatesEditor: RebatesEditor
    , statusEditor: StatusEditor
    , projectsEditor: ProjectsEditor
    , websitesEditor: WebsitesEditor
    , socialsEditor: SocialsEditor
    , accessPolicyEditor: AccessPolicyEditor
    , numericEditor: NumericEditor
    , floatNumericEditor: FloatNumericEditor
    , integerNumericEditor: IntegerNumericEditor
    //Header
    , blankHeaderComponent: BlankHeaderComponent
    //Overlay
    , noRowsOverlay: NoRowsOverlay
  }
  , props: {
    entity: {
      type: String
      , default: null
      , validator: propValue => {
        return [null, 'CUSTOMER', 'PROJECT', 'STAGE', 'TEMPLATE__PROJECT'
                , 'SKILL', 'RESOURCE', 'LOCATION', 'REBATE'
                , 'USER', 'ACCESS_POLICY', 'TAG', 'WEBHOOK'].includes(propValue)
      }
    }
    , show: {
      type: Boolean
      , required: true
    }
    , entityService: {
      type: Object
      , default: () => {}
      // , required: true
    }
    , targetColumn: {
      type: String
      , default: null
    }
    //----label----
    , selectorTitle: {
      type: String,
      default: null
    }
    , editorTitleNew: {
      type: String
      , default: null
    }
    , editorTitleEdit: {
      type: String
      , default: null
    }
    , editorTitleView: {
      type: String
      , default: null
    }
    /** Non admin related props **/
    , nonAdmin: {
      type: Boolean,
      default: false
    }
    , preselected: {
      type: [String, Array],
      default: null
    }
    , singleSelection: {
      type: Boolean,
      default: false
    }
    , projectIds: {
      type: Array,
      default: () => [] //used by RESOURCE and PROJECT entity 
    }
    , tagFilter: {
      type: Object,
      default: () => {} //used by STAGE entity
    }
    /** End of Non admin related props **/
  },
  data: function() {
    return {
      elemId: `${this.entity != null? this.entity : 'ENTITY'}_SELECTOR_FOR_ADMIN`
      , allowManage: true //always true. It is referenced in  DetailLink.vue
      , gridApi: null

      , alertObj: {
        msg: null
        , msgDetails: { title: null, list: [] }
        , state: alertStateEnum.SUCCESS

      }
      , selectorShow: false
      // , searchFilter: ''
      
      , filterText: ''
      , filterTextFocus: false
      , closePriorityNavDropdown: false
      , filterPinned: false

      , editorShow: false
      , entityId: null
      , currentEditorComponent: null
      
      , coloring: {}

      , duplicateShow: false
      , duplicateName: null
      , duplicateFirstname: null
      , duplicateLastname: null
      , duplicateEmail: null
      , duplicateInProgress: false
      , includeTasks: false
      , duplicateEntityField: null
      , duplicateAction: null
      , duplicateUrl: null
      
      , docImportShow : false
      , existingData: null
      , docImportProperties : []

      , inProgressShow: false
      , inProgressLabel: null
      , inProgressStoppable: false
      , inProgressState: {
        cancel: false
      }

      , rowSelectorClicked_allColsSelectedRowIndexList: []
      , rangeSelection: []
      , entitySelection: []

      , pendingListByFillOperation: []
      , triggeredByFillOperation: false
      , processValueChangedList: []
      , processDateValueChangedList: []
      , pendingProcessRequestList: []
      , pendingRequestBatchList: []
      , pendingDeleteCells: []

      , ecConfirmDeleteShow: false
      , cannotDeleteModalShow: false
      , ecConfirmDeleteEntities: []
      , entityCol: {
        entityName: null
        , parentName: null
        , entityId: null
        , parentId: null
        , colId: null
        , applyAll: false
        , cantDeleteDetails: null // special use case. It is used when entity cant be deleted due to specific reason. Expected format: { title, content }

      }

      , mergeTagShow: false
      , valuesMap: {}
      , mergeFrom: null
      , mergeTo: null
      , mergeOptions: []

      , overlayNoRowsTemplate: this.$t(`entity_selector.${this.formattedEntity}_grid_no_data`)

      , noRowsMessage: null
      , noRowsOverlayComponentParams: null

      , isCellEditing: false
      , lastOpenColumnMenuParams: null

      , noRowDeletes: []

      , keepAlertFeedback: false

      , customSrcValueHandler: null
      , propertyCompatibleFunc: null
      , consolidatePropertyGroupWhenValueChangedFunc: null
      , propertyCopyHandler: null
      , valueChangedHandler: null
      , importDataProperties: null
      , exportDataPropertyHandler: null
      , importDataFunc: null

      , profileColumnLoaded: false

      //BadgeFilter related
      , badgeFilters: [
        // { field: 'taskName', name: 'Task Name: Task #1, Task #2', value: [{ checked: true, text: 'Task #1'}, { checked: true, text: 'Task #2'}] }
      ]
      , badgeFilterFields: [
        { value: 'tagName', text: 'Tag Name' }
        , { value: 'type', text: 'Type' }
      ]
      , badgeFilterFieldValues: {
        // taskName: [
        //   { text: 'Task #1' }
        //   , { text: 'Task #2' }
        // ],
      }
      , badgeFilterFocus: false
      , badgeFilterModalOpened: 'close'
      , badgeFilterOptionFetchFunc: null
      , badgeFilterPinned: false

      //User view related
      , views: []
      , promptSaveShow: false
      , promptShareShow: false
      , saveName: null
      , saveProfile: null
      , saveIndex: -1
      , confirmDeleteViewShow: false
      , deleteViewIndex: -1
      , viewName: null
      , showInfo: []

      //nonAdmin mode
      // , selected: []
      , disableOk: false
      , deletedIds: []
      , deleteInProgressIds: []
      , bookings: false //used by RESOURCE
      , tagFilterState: 2 //used by STAGE: -1 unrestricted, 0: global (non-entity + uncategorized tags), 1: specific entity, 2: specific entity + uncategorized tags
      , entityOptions: [] //used by STAGE

      , durationConversionOpts: {}

      , forcePriorityNavRefresh: false
    }
  },
  beforeMount() {
    this.userId = this.$store.state.authentication.user.uuId
    const getColumnDefs = (c) => {
      return {
        colId: c.colId
        , width: c.actualWidth
        , sort: c.sort != null? c.sort : null
        , sortIndex: c.sortIndex != null? c.sortIndex : null
      }
    }

    const saveSelectorColumnSettings = (columns, skipCheck=true) => {
      if (this.profileKeySelector != null) {
        const newColumns = columns.filter(c => c.colId != 'rowSelector').map(c => getColumnDefs(c))
        const oldColumns = this.profileSettings[this.profileKeySelector]
        if (skipCheck) {
          this.profileSettings[this.profileKeySelector] = newColumns
          this.updateViewProfile({ clearViewName: false })
          return
        }

        let hasChanged = false
        if (oldColumns == null) {
          hasChanged = true
        } else if (oldColumns.length != newColumns.length) {
          hasChanged = true
        } else {
          for (const [index, col] of oldColumns.entries()) {
            if (col.colId != newColumns[index].colId || 
                col.width != newColumns[index].width ||
                col.sort != newColumns[index].sort ||
                col.sortIndex != newColumns[index].sortIndex) {
              hasChanged = true
              break
            }
          }
        }
        if (hasChanged) {
          this.profileSettings[this.profileKeySelector] = newColumns
          this.updateViewProfile()
        }
      }
    }

    const self = this
    this.gridOptions = {}
    this.columnDefs = []
    this.gridOptions = {
      processUnpinnedColumns(params) {
        if (params.api.isDestroyed()) {
          return;
        }
        params.api.setColumnsPinned(['uuId'], null);
        self.enforcePinnedColumnOrders(params.api);
      },
      onNewColumnsLoaded: function(params) {
        if (params.source == 'api' && params.type == 'newColumnsLoaded') {
          self.enforcePinnedColumnOrders(params.api);
        }
      },
      onColumnVisible: function(params) {
        if (!self.profileColumnLoaded) {
          return
        }
        let fromToolPanel = params.source == "toolPanelUi"
        if (fromToolPanel) {
          let colKey = params.column.colId;
          let columnMenuColumnIndex = params.api
            .getAllGridColumns()
            .findIndex(col => {
              return col === self.lastOpenColumnMenuParams.column;
            });
          if (colKey == 'avatarRef') {
            params.api.moveColumns([colKey], self.nonAdmin? 0 : 1);
          } else {
            params.api.moveColumns([colKey], columnMenuColumnIndex + 1);
          }
        }
        const cols = params.api.getAllGridColumns().map(i => { 
          return { colId: i.colId, headerName: i.colDef.headerName, hide: i.colDef.hide, pinned: i.pinned }} )
        const columnState =  params.api.getColumnState();
        //get the actual hide value from columnState
        for (const col of columnState) {
          const found = cols.find(i => i.colId == col.colId)
          if (found) {
            found.hide = col.hide;
          }
        }
        cols.sort(columnDefSortFunc)
        for (const [index,c] of cols.entries()) {
          params.api.moveColumns([c.colId], index);
        }

        const columns = params.api.getAllDisplayedColumns()
        saveSelectorColumnSettings(columns, false)
        
      }
      , postProcessPopup: params => {
        if ((params.type == 'columnMenu')) {
          self.lastOpenColumnMenuParams = params;
        }
      }
      , onSortChanged: function(event) {
        if (event.source === 'gridOptionsChanged') {
          return; // do not process this sort changed as it does not contain the user's sort column selection
        }
        //Clear last focused cell. Reset vertical scroll position to top. Clear range selection
        self.lastFocusedCell = null
        self.previousVScrollPosition = null
        event.api.clearRangeSelection()
        if (event.api.gridBodyCtrl && event.api.gridBodyCtrl.bodyScrollFeature) {
          event.api.gridBodyCtrl.bodyScrollFeature.setVerticalScrollPosition(0)
        }
        //Update column setting
        const columns = event.api.getAllDisplayedColumns()
        saveSelectorColumnSettings(columns, false)
      }
      , onDragStopped: function(event) {
        const columns = event.api.getAllDisplayedColumns()
        saveSelectorColumnSettings(columns, false)
      }
      , onFirstDataRendered: function(event) {
        if (self.newToProfile != null && self.newToProfile == true) {
          self.newToProfile = null
          event.api.sizeColumnsToFit()
          self.$nextTick(() => {
            const columns = event.api.getAllDisplayedColumns()
            saveSelectorColumnSettings(columns)
          })
        }
      }
      , onCellValueChanged: function(event) {
        let colId = event.column.colId
        const rowIndex = event.rowIndex
        let newValue = event.newValue
        let oldValue = event.oldValue
        const rowNode = event.api.getDisplayedRowAtIndex(rowIndex)
        if (event.column.colId == self.targetColumnId) {

          if (typeof self.entityService.uuIdCellValueChanged === 'function') {
            const r = self.entityService.uuIdCellValueChanged(self, rowNode.data)
            colId = r.colId
            newValue = r.newValue
            oldValue = r.oldValue
          } else {
            if (rowNode.data.oldName == null) {
              return //Skip change when oldName is null/undefined. It happens when user click other cell without any change
            }
            colId = 'name'
            newValue = rowNode.data.name
            oldValue = rowNode.data.oldName //oldName is added in autoGroupColumnDef.valueSetter.
          }
        }
        
        const payload = {
          colId
          , data: objectClone(rowNode.data)
          , newValue
          , oldValue
          , property: colId
          , entityId: rowNode.id
          , parentId: rowNode.data.pUuId
          , entityName: rowNode.data.name
          , color: event.colDef.color //Default Color. Used when creating new entity.
        }

        if (colId === self.targetColumnId || payload.newValue == payload.oldValue) {
          //do nothing
        } else if (self.triggeredByFillOperation) {
          self.pendingListByFillOperation.push(payload)
        } else if (self.isPasteInProgress) {
          self.processValueChangedList.push(payload)
        } else {
          self.processValueChangedList.push(payload)
          self.inProgressLabel = self.$t(`entity_selector.${self.formattedEntity}_update_progress`)
          self.processValueChanged(event.api)
        }
      }
      , onRangeSelectionChanged: function(event) {
        if (event.finished == true) {
          if (self.triggeredByFillOperation) {
            const pendingList = self.pendingListByFillOperation.splice(0, self.pendingListByFillOperation.length)
            self.processValueChangedList.push(...pendingList)
            self.consolidateChangedCellValues(self.processValueChangedList)
            self.triggeredByFillOperation = false
            self.inProgressLabel = self.$t(`entity_selector.${self.formattedEntity}_update_progress`)
            self.processValueChanged(event.api)
          }
          const cellRanges = event.api.getCellRanges()
          const originalRanges = cellRanges.map(i => {
            return {
              rowStartIndex: i.startRow.rowIndex,
              rowEndIndex: i.endRow.rowIndex,
              columns: i.columns.map(j => j.colId)
            }
          })
          if (cellRanges.length > 0) {
            const lastRange = (() => {
                const tmp = cellRanges[cellRanges.length-1]
                const isAsc = tmp.startRow.rowIndex <= tmp.endRow.rowIndex
                return {
                  rowStartIndex: isAsc? tmp.startRow.rowIndex : tmp.endRow.rowIndex,
                  rowEndIndex: isAsc? tmp.endRow.rowIndex : tmp.startRow.rowIndex,
                  columns: tmp.columns.map(i => i.colId),
                  startColumn: tmp.startColumn.colId,
                  orgRowStartIndex: tmp.startRow.rowIndex,
                  orgRowEndIndex: tmp.endRow.rowIndex
                }
              })()
            const lastColumnId = lastRange.startColumn == lastRange.columns[0]? lastRange.columns[lastRange.columns.length-1] : lastRange.columns[0] 
            //Handle drag selection which starts (mouse click press) or finishes (mouse click release) on rowSelector column. 
            //Brief: Include all cells of the selected rows into the cell ranges as if user clicks on rowSelector columns
            if (lastRange.columns.length > 1 && (lastColumnId == 'rowSelector' || lastRange.startColumn == 'rowSelector')) {
              self.rowSelectorChanged({
                cellRanges,
                originalRanges,
                lastRange,
                api: event.api
              })
            } else {
              //Brief: Reshape previous ranges to avoid clashing with last/latest range.
              const lastRangeColumns = lastRange.columns
              const isLastRangeAsc = lastRange.rowStartIndex <= lastRange.rowEndIndex
              const lastRangeStartIndex = isLastRangeAsc? lastRange.rowStartIndex : lastRange.rowEndIndex
              const lastRangeEndIndex = isLastRangeAsc? lastRange.rowEndIndex : lastRange.rowStartIndex

              const cRanges = cellRanges.map(i => {
                return {
                  rowStartIndex: i.startRow.rowIndex,
                  rowEndIndex: i.endRow.rowIndex,
                  columns: i.columns.map(j => j.colId)
                }
              })
              cRanges.splice(cRanges.length - 1, 1)
              const newCellRanges = []
              let hasChanges = false

              //Before reshape, check if last range's start cell clashes with previous cell range.
              //If yes, do nothing (and expect aggrid will do its job to remove cell ranges)
              const startColumnId = lastRange.startColumn
              const startCell = {
                rowStartIndex: lastRange.orgRowStartIndex,
                rowEndIndex: lastRange.orgRowStartIndex
              }
              let isStartCellClashed = false
              for (const range of cRanges) {
                const isCellRangeAsc = range.rowStartIndex <= range.rowEndIndex
                const cellRangeStartIndex = isCellRangeAsc? range.rowStartIndex : range.rowEndIndex
                const cellRangeEndIndex = isCellRangeAsc? range.rowEndIndex : range.rowStartIndex
                const isRowClashed = startCell.rowStartIndex >= cellRangeStartIndex && startCell.rowEndIndex <= cellRangeEndIndex
                const columnClashed = range.columns.filter(i => i == startColumnId).length > 0
                if (isRowClashed && columnClashed) {
                  isStartCellClashed = true
                  break
                }
              }
              if (isStartCellClashed) {
                return
              }

              //Reshape previous ranges to avoid clashing with last/latest range.
              for (const range of cRanges) {
                const isCellRangeAsc = range.rowStartIndex <= range.rowEndIndex
                const cellRangeStartIndex = isCellRangeAsc? range.rowStartIndex : range.rowEndIndex
                const cellRangeEndIndex = isCellRangeAsc? range.rowEndIndex : range.rowStartIndex
                const isRowClashed = (lastRangeStartIndex >= cellRangeStartIndex && lastRangeStartIndex <= cellRangeEndIndex) ||
                                     (cellRangeStartIndex >= lastRangeStartIndex && cellRangeStartIndex <= lastRangeEndIndex)
                const clashedColumns = range.columns.filter(i => lastRangeColumns.includes(i))                
                if (!isRowClashed || clashedColumns.length == 0) {
                  newCellRanges.push(range)
                  continue
                }
                
                //Reshape cell range if it clash with lastRange.
                const splitTopNeeded = cellRangeStartIndex < lastRangeStartIndex
                const splitBottomNeeded = cellRangeEndIndex > lastRangeEndIndex
                const firstClashedCol = clashedColumns[0]
                const splitLeftNeeded = range.columns.findIndex(i => i == firstClashedCol) != 0
                const lastClashedCol = clashedColumns[clashedColumns.length-1]
                const splitRightNeeded = range.columns.findIndex(i => i == lastClashedCol) != range.columns.length-1

                if (splitTopNeeded) {
                  newCellRanges.push({
                    rowStartIndex: cellRangeStartIndex,
                    rowEndIndex: lastRangeStartIndex-1,
                    columns: objectClone(range.columns)
                  })
                  hasChanges = true
                }

                if (splitRightNeeded) {
                  const startColIdx = range.columns.findIndex(i => i == lastClashedCol)+1
                  newCellRanges.push({
                    rowStartIndex: splitTopNeeded? lastRangeStartIndex : cellRangeStartIndex,
                    rowEndIndex: splitBottomNeeded? lastRangeEndIndex : cellRangeEndIndex,
                    columns: range.columns.slice(startColIdx)
                  })
                  hasChanges = true
                }

                if (splitBottomNeeded) {
                  newCellRanges.push({
                    rowStartIndex: lastRangeEndIndex+1,
                    rowEndIndex: cellRangeEndIndex,
                    columns: objectClone(range.columns)
                  })
                  hasChanges = true
                }

                if (splitLeftNeeded) {
                  const sliceLen = range.columns.findIndex(i => i == firstClashedCol)
                  newCellRanges.push({
                    rowStartIndex: splitTopNeeded? lastRangeStartIndex : cellRangeStartIndex,
                    rowEndIndex: splitBottomNeeded? lastRangeEndIndex : cellRangeEndIndex,
                    columns: range.columns.slice(0, sliceLen)
                  })
                  hasChanges = true
                }
              }
              
              //Merge last range to any existing range when condition met.
              //Conditions: 1) Matched rows and column(s) in sequence, or 
              //            2) Matched columns and row(s) in sequence.
              let hasRangeMerged = false
              if (newCellRanges.length > 0) {
                const allColumns = event.api.getAllDisplayedColumns().filter(i => i.colId != 'rowSelector').map(i => i.colId)
                const lastRowStartColIndex = allColumns.findIndex(i => i == lastRangeColumns[0])
                const lastRowEndColIndex = allColumns.findIndex(i => i == lastRangeColumns[lastRangeColumns.length - 1])
                const cloned = objectClone(newCellRanges)
                const newRanges = []
                for (const cRange of cloned) {
                  const startColIndex = allColumns.findIndex(i => i == cRange.columns[0])
                  const endColIndex = allColumns.findIndex(i => i == cRange.columns[cRange.columns.length - 1])
                  const isRowIndexMatched = cRange.rowStartIndex == lastRangeStartIndex && cRange.rowEndIndex == lastRangeEndIndex
                  const isColumnMatched = startColIndex == lastRowStartColIndex && endColIndex == lastRowEndColIndex
                  const isRowInSequence = cRange.rowStartIndex -1 == lastRange.rowEndIndex || cRange.rowEndIndex + 1 == lastRange.rowStartIndex
                  const isColumnInSequence = startColIndex - 1 == lastRowEndColIndex || endColIndex + 1 == lastRowStartColIndex
                  if (isRowIndexMatched && isColumnInSequence) {
                    newRanges.push({
                      rowStartIndex: lastRangeStartIndex
                      , rowEndIndex: lastRangeEndIndex
                      , columns: lastRowStartColIndex < startColIndex? [...lastRangeColumns, ...cRange.columns] : [...cRange.columns, ...lastRangeColumns]
                    })
                    hasRangeMerged = true
                    continue
                  } else if (isColumnMatched && isRowInSequence) {
                    newRanges.push({
                      rowStartIndex: lastRangeStartIndex < cRange.rowStartIndex? lastRangeStartIndex : cRange.rowStartIndex
                      , rowEndIndex: lastRangeEndIndex > cRange.rowEndIndex? lastRangeEndIndex : cRange.rowEndIndex
                      , columns: lastRangeColumns
                    })
                    hasRangeMerged = true
                    continue
                  }
                  newRanges.push(cRange)
                }
                if (hasRangeMerged) {
                  newCellRanges.splice(0, newCellRanges.length, ...newRanges)
                }
              }
              
              //hasChanges flag is important to avoid infinite loop. 
              //any addCellRange() call will trigger onRangeSelectionChange() event.
              //Don't call addCellRange() when no change is required.
              if (hasChanges || hasRangeMerged) {
                //Adding last range when hasRangeMerged is false. 
                //Details: If hasRangeMerged is true, the last range has been merged to one of the previous range.
                if (!hasRangeMerged) {
                  newCellRanges.push({
                    rowStartIndex: lastRange.rowStartIndex,
                    rowEndIndex: lastRange.rowEndIndex,
                    columns: lastRange.columns
                  })
                }
                event.api.clearRangeSelection()
                for (const ncRange of newCellRanges) {
                  event.api.addCellRange(ncRange)
                }
              } else {
                //When thing settles down, update entitySelection variable.
                let selectedEntities = []
                for (const oRange of originalRanges) {
                  const startRowIdx = oRange.rowStartIndex > oRange.rowEndIndex? oRange.rowEndIndex : oRange.rowStartIndex
                  const endRowIdx = oRange.rowStartIndex > oRange.rowEndIndex? oRange.rowStartIndex : oRange.rowEndIndex
                  
                  for (let i = startRowIdx; i <= endRowIdx; i++) {
                    const rowNode = event.api.getDisplayedRowAtIndex(i)
                    
                    if (rowNode == null || rowNode.data == null) {
                      continue
                    }

                    if (rowNode.data) {
                      //Treat any cell selection is ag-Grid-AutoColumn cell selection.
                      selectedEntities.push({ 
                          uuId: rowNode.data.uuId
                        , name: rowNode.data.name
                        , parent: rowNode.data.pUuId
                        , parentName: rowNode.data.pName
                        , colId: self.targetColumnId
                        , rowIndex: rowNode.rowIndex
                        , enabled: rowNode.data.enabled
                      })
                    }
                  }
                }
                
                //Rearrange order of the tasks.
                //Tasks without rowIndex will be pushed to the bottom of the list. Theorectically, all tasks should have rowIndex property.
                //The first task/summary task will be target parent for new task creation.
                selectedEntities.sort(function( a, b ) {
                  if (a.rowIndex == null && b.rowIndex == null) {
                    return 0
                  }
                  if (b.rowIndex == null || a.rowIndex < b.rowIndex){
                    return -1
                  }
                  if (a.rowIndex == null || a.rowIndex > b.rowIndex){
                    return 1
                  }
                  return 0
                })
                
                self.entitySelection.splice(0, self.entitySelection.length, ...selectedEntities)
              }
            }
          } else {
            //Clean up entitySelection when range selection is empty.
            self.entitySelection.splice(0, self.entitySelection.length)
          }
          event.api.refreshHeader()
        }
        
      }
      , onPaginationChanged: function(/** event */) {
        //Don't reset previous vertical scroll when it is triggerred by editor success event
        if (self.triggeredByEditorSuccess) {
          self.triggeredByEditorSuccess = false
        } else {
          self.previousVScrollPosition = null
        }
        
      }
      , onSelectionChanged: function(event) {
        self.entitySelection.splice(0, self.entitySelection.length, ...(event.api.getSelectedNodes().map(i => i.data)));

        const isArrayEmpty = Array.isArray(self.preselected) && self.preselected.length == 0;        

        let hasChanged = self.entitySelection.length > 0 && (self.preselected == null || isArrayEmpty);
        if (!hasChanged && self.preselected != null) {
          if (typeof self.preselected == 'string' && self.entitySelection.uuId != self.preselected) {
            hasChanged = true;
          } else if (self.preselected.length != self.entitySelection.length) {
            hasChanged = true;
          } else {
            for (const e of self.entitySelection) {
              if (!self.preselected.includes(e.uuId)) {
                hasChanged = true;
                break;
              }
            }
          }
        }
        self.disableOk = !hasChanged;
      }
    }
    this.context = { componentParent: self }
    this.defaultColDef = {
      sortable: true
      , resizable: true
      , minWidth: 100
      , hide: true
      , lockPinned: true
      , menuTabs: ['columnsMenuTab']
      , suppressKeyboardEvent: (params) => {
        if (params.event.keyCode === 46 
            || params.event.keyCode === 35 || params.event.keyCode == 36 
            || (params.event.keyCode == 37 && params.event.ctrlKey)) {
          return true
        } else if (params.event.keyCode == 13) {
          if (params.event.ctrlKey) {
            return true
          }
          if ((typeof params.colDef.editable == 'boolean' && !params.colDef.editable) || 
              (typeof params.colDef.editable == 'function' &&  !params.colDef.editable({ data: params.data }))) {
            return true
          }
        } else if (params.event.keyCode == 68 && params.event.ctrlKey) { //'D'
          return true
        }
        return false
      }
      // , cellRendererParams: { enableReadonlyStyle: true }
      , cellRendererParams: {
          enableReadonlyStyle: !self.nonAdmin
        }
    }
    
  },
  mounted() {
    //Getting schema api info. It is used in deciding which column to be displayed (based on the user's permission).
    this.$store.dispatch('data/schemaAPI', {type: 'api', opts: 'brief' })
    .finally(() => {
      //Call init() when component is newly created and mounted. 
      //Reason: The show value is already true when component is created/mounted, so it won't trigger the watch event.
      this.init()
    })

    //BadgeFilter related
    document.addEventListener('click', this.toggleBadgeFilterFocus)
    document.addEventListener('keydown', this.handleBadgeFilterEscapeKeyDown)
  },
  created() {
    this.getDurationConversionOpts()
    this.modelInfo = null
    this.enumList = {}
    this.profileSettings = null
    this.newToProfile = null
    this.profileKeySelector = null
    this.profileKeyColoring = null
    this.exportData = false
    this.lastFocusedCell = null
    this.cellCopyPrefix = null
    this.previousVScrollPosition = null
    this.isPasteInProgress = false
    this.mandatoryFields = []
    this.isInitCompleted = false
    this.isGridReady = false
    this.triggeredByEditorSuccess = false
    this.toggleSelectorShow(this.show)
    this.showSandboxInfo();
    document.addEventListener('keydown', this.keyDownHandler)
    this.noRowsOverlayComponentParams = {
      msgFunc: this.prepareNoRowsMessage
    }
    
  },
  beforeDestroy() {
    this.isPasteInProgress = false
    this.previousVScrollPosition = null
    this.cellCopyPrefix = null
    this.lastFocusedCell = null
    this.exportData = false
    this.userId = null
    this.newToProfile = null
    this.profileSettings = null
    this.profileKeySelector = null
    this.profileKeyColoring = null
    this.enumList = null
    this.modelInfo = null
    this.mandatoryFields = null
    this.isInitCompleted = false
    this.isGridReady = false
    this.triggeredByEditorSuccess = false
    document.removeEventListener('keydown', this.keyDownHandler)

    //BadgeFilter related
    document.removeEventListener('keydown', this.handleBadgeFilterEscapeKeyDown)
    document.removeEventListener('click', this.toggleBadgeFilterFocus);
    this.badgeFilterFocus = false;
    this.badgeFilterModalOpened = 'close';

    this.gridApi = null;
  },
  watch: {
    // async 
    show(newValue) {
      //Reset value
      this.resetAlert()
      this.showSandboxInfo();
      this.entityId = null
      // this.searchFilter = ''
      this.entitySelection.splice(0, this.entitySelection.length)
      this.rangeSelection.splice(0, this.rangeSelection.length)
      this.profileKeySelector = null
      this.profileKeyColoring = null
      this.viewName = null
      this.lastFocusedCell = null
      this.previousVScrollPosition = null
      this.isPasteInProgress = false
      this.isCellEditing = false
      Object.keys(this.coloring).forEach(key => delete this.coloring[key])
      this.customSrcValueHandlers = null
      this.propertyCompatibleFunc = isPropertyCompatible //reset to default func
      this.consolidatePropertyGroupWhenValueChangedFunc = null
      this.propertyCopyHandler = null
      this.valueChangedHandler = null
      this.importDataProperties = null
      this.exportDataPropertyHandler = null
      this.importDataFunc = null
      this.badgeFilterOptionFetchFunc = null
      this.profileColumnLoaded = false;
      this.isInitCompleted = false;
      this.isGridReady = false;
      this.deletedIds = [];
      if(newValue) {
        this.init()
      }
      this.toggleSelectorShow(newValue)
    },
  },
  computed: {     
    infoMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    }
    , ganttImportMode() {
      return this.entity == 'TEMPLATE__PROJECT'? 'TASK_TEMPLATE' : this.entity
    }
    , editorTitleLabel() {
      let entity = this.entity != null? this.entity.toLowerCase() : ''
      
      if (this.entityId && this.entityId.indexOf('NEW') > -1) {
        if (this.editorTitleNew != null) {
          return this.editorTitleNew
        }
        return this.$t(`entity_selector.${entity}_new`)
      }
      if (entity != '' && this.canEdit(this.entity)) {
        return this.$t(`entity_selector.${entity}_edit`)
      }
      return this.$t(`entity_selector.${entity}_view`)
    }
    , selectorTitleLabel() {
      let entity = this.entity != null? this.entity.toLowerCase() : ''
      let viewNamePrefix = this.viewName != null? ' - '+ this.viewName : ''
      return this.selectorTitle != null? this.selectorTitle + viewNamePrefix : this.$t(`entity_selector.${entity}_selector`) + viewNamePrefix
    }
    , duplicateTitleLabel() {
      let entity = this.entity != null? this.entity.toLowerCase() : ''
      return this.duplicateTitle != null? this.duplicateTitle : this.$t(`entity_selector.${entity}_duplicate`)
    }
    , duplicateFieldNameLabel() {
      if (this.entity != null) {
        return this.$t(`entity_selector.${this.entity.toLowerCase()}_duplicate_field_name`)
      } else {
        return ''
      }
    }
    , showDuplicateNameError() {
      return fieldValidateUtil.hasError(this.errors, 'duplicate.name')
    }
    , showDuplicateEmailError() {
      return fieldValidateUtil.hasError(this.errors, 'duplicateEmail');
    }
    , showDuplicateLastnameError() {
      return fieldValidateUtil.hasError(this.errors, 'duplicateLastname');
    }
    , showDuplicateEntityError() {
      return fieldValidateUtil.hasError(this.errors, 'duplicateEntityField')
    }
    , showDuplicateActionError() {
      return fieldValidateUtil.hasError(this.errors, 'duplicateAction');
    }
    , showDuplicateUrlError() {
      return fieldValidateUtil.hasError(this.errors, 'duplicateUrl');
    }
    , colorMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter'
    }
    , targetColumnId() {
      return this.targetColumn == null? 'uuId' : this.targetColumn
    }
    , formattedEntity() {
      return this.entity == null? 'null' : this.entity.toLowerCase()
    }
    , disableEdit() {
      return this.entitySelection.length != 1
    }
    , disableDuplicate() {
      return this.entitySelection.length != 1
    }
    , disableDelete() {
      return this.entitySelection.length < 1
    }
    , ecTitle() {
      return this.entityCol != null && this.entityCol.cantDeleteDetails != null 
              && this.entityCol.cantDeleteDetails.title != null? this.entityCol.cantDeleteDetails.title : this.$t('entity_selector.confirm_deletion_title')
    }
    , ecShowApplyAllCheckbox() {
      return this.ecConfirmDeleteEntities.length > 0
    }
    , ecConfirmDeleteStatement() {
      if (this.entityCol != null && this.entityCol.cantDeleteDetails != null) {
        return this.entityCol.cantDeleteDetails.content != null? this.entityCol.cantDeleteDetails.content : ''
      }
      if (this.formattedEntity != null) {
        return this.$t(`entity_selector.${this.formattedEntity}_entitycol_delete`, [this.entityCol.entityName])
      } else {
        return ''
      }
    }
    , overlayLoadingTemplate() {
      return `<span class='grid-overlay'><div class="mr-1 spinner-grow spinner-grow-sm text-dark"></div>${ this.$t(`entity_selector.${this.formattedEntity}_grid_loading_list`) }</span>`;
    }
    // , overlayNoRowsTemplate() {
    //   return `<span class='grid-overlay'>${ this.$t(`entity_selector.${this.formattedEntity}_grid_no_data`) }</span>`;
    // }
    , filterMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    }
    , badgeFilterMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    }
    , badgeFilterMouseOverEvent() {
      return this.isTouchDevice()? null : 'mouseover';
    }
    , badgeFilterMouseLeaveEvent() {
      return this.isTouchDevice()? null : 'mouseleave';
    }
    , badgeFilterTouchEndEvent() {
      return this.isTouchDevice()? 'touchend' : null;
    }
    , priorityNavMouseOverEvent() {
      return this.isTouchDevice()? null : 'mouseover';
    }
    , priorityNavTouchEndEvent() {
      return this.isTouchDevice()? 'touchend' : null;
    }
    , viewMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    }
    , filteredViews() {
      return this.views.filter(i => i.type == `${this.entity}_admin_selector`);
    }
  },
  methods: {
    async init() {
      //reset tagFilterState
      if (this.entity == 'STAGE' && this.nonAdmin && this.tagFilter != null) {
        const hasEntity = Object.hasOwn(this.tagFilter, 'entity')
        const hasTags = Object.hasOwn(this.tagFilter, 'tags') && Array.isArray(this.tagFilter.tags) && this.tagFilter.tags.length > 0;
        if (hasEntity && hasTags) {
          this.tagFilterState = 2; //specific entity + uncategorized tags
        } else if (hasEntity) {
          this.tagFilterState = 1; //entity only
        } else if (hasTags) {
          this.tagFilterState = 0; //non entity + uncategorized tags
        } else {
          this.tagFilterState = -1; //No filter
        }
      } else {
        this.tagFilterState = -1; //No filter
      }
      

      const self = this;
      // clear out the views from the previous selector
      this.views.splice(0, this.views.length);
      if (this.entity != null) {
        const modelInfoObject =this.entityService.modelInfoObject != null? this.entityService.modelInfoObject : this.entity
        const requests = [
          this.getModelInfo(modelInfoObject).catch(e => this.httpAjaxError(e))
        ]
        if (this.entity == 'TEMPLATE__PROJECT') {
          requests.push(getCustomFieldInfo(this, 'PROJECT', 'PROJECT_TEMPLATE').catch(e => this.httpAjaxError(e)))
        } else if (this.entity == 'PROJECT') {
          requests.push(getCustomFieldInfo(this, 'PROJECT', 'PROJECT').catch(e => this.httpAjaxError(e)))
        } else {
          requests.push(getCustomFieldInfo(this, this.entity).catch(e => this.httpAjaxError(e)))
        }
        if (this.nonAdmin && this.entity == 'STAGE') {
          requests.push(this.$store.dispatch('data/info', {type: 'holder', object: 'STAGE,TAG'}).then(value => {
            this.entityOptions.splice(0, this.entityOptions.length);
            for(const v of value) {
              this.entityOptions.push({ value: v, text: v });
            }
          })
          .catch(e => this.httpAjaxError(e)))
        }
        await Promise.allSettled(requests)
        
        this.loadUserProfile(); // User profile holds entity views
        this.loadPublicProfile(); //Public profile holds entity views
        
        //Setup handlers after enumList object is ready
        if (this.entityService != null) {
          this.customSrcValueHandler = Object.hasOwn(this.entityService, 'getCustomSrcValueHandler')? this.entityService.getCustomSrcValueHandler(this) : null  
          if (Object.hasOwn(this.entityService, 'getPropertyCompatibleFunc')) {
            this.propertyCompatibleFunc = this.entityService.getPropertyCompatibleFunc(this)
          }
          if (Object.hasOwn(this.entityService, 'consolidatePropertyGroupWhenValueChangedFunc')) {
            this.consolidatePropertyGroupWhenValueChangedFunc = this.entityService.consolidatePropertyGroupWhenValueChangedFunc(this)
          }
          if (Object.hasOwn(this.entityService, 'getPropertyCopyHandler')) {
            this.propertyCopyHandler = this.entityService.getPropertyCopyHandler(this)
          }
          if (Object.hasOwn(this.entityService, 'getValueChangedHandler')) {
            this.valueChangedHandler = this.entityService.getValueChangedHandler(this)
          }
          if (Object.hasOwn(this.entityService, 'getImportDataProperties')) {
            this.importDataProperties = this.entityService.getImportDataProperties(this)
          }
          if (Object.hasOwn(this.entityService, 'getExportDataPropertyHandler')) {
            this.exportDataPropertyHandler = this.entityService.getExportDataPropertyHandler(this)
          }
          if (Object.hasOwn(this.entityService, 'importDataFunc')) {
            this.importDataFunc = this.entityService.importDataFunc(this)
          }
        }
        
        this.cellCopyPrefix = `${this.entity}${CELL_COPY_CODE}`
        this.profileKeySelector = `${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_list`
        this.profileKeyColoring = `${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_coloring`

        let colorOptions = null
        if (typeof this.entityService.getColorMenuOptions === 'function') {
          colorOptions = this.entityService.getColorMenuOptions()
        } else {
          colorOptions = { none: true }
        }
        Object.keys(colorOptions).forEach(key => this.$set(this.coloring, key, colorOptions[key])) //Use $set to force rerendering html
        this.currentEditorComponent = this.getEditorComponent(this.entity)

        //badgeFilter
        if (Object.hasOwn(this.entityService, 'getBadgeFilterFields')) {
          this.badgeFilterFields = this.entityService.getBadgeFilterFields(this)
        } else {
          this.badgeFilterFields = []
        }
        
        if (Object.hasOwn(this.entityService, 'getBadgeFilterOptionFetchFunc')) {
          this.badgeFilterOptionFetchFunc = this.entityService.getBadgeFilterOptionFetchFunc(this)
        }
        
        const cDefs = this.entityService.getColumnDefs(this)
        cDefs.sort(columnDefSortFunc);
        
        const cellStyleFunc = (params) => {
          if (params.data &&
              params.data.color &&
              self.coloring[self.formattedEntity]) {
            return { background: params.node.data.color, color: invertColor(params.node.data.color, true) }
          } else if (params.data &&
              params.data.locationColor &&
              self.coloring.location) {
            return { background: params.node.data.locationColor, color: invertColor(params.node.data.locationColor, true) }
          } else if (params.data &&
              params.data.companyColor &&
              self.coloring.company) {
            return { background: params.node.data.companyColor, color: invertColor(params.node.data.companyColor, true) }
          } else if (params.data &&
              params.data.projectColor &&
              self.coloring.project) {
            return { background: params.node.data.projectColor, color: invertColor(params.node.data.projectColor, true) }
          } else if (params.data &&
              params.data.customerColor &&
              self.coloring.customer) {
            return { background: params.node.data.customerColor, color: invertColor(params.node.data.customerColor, true) }
          } else if (params.data &&
              params.data.rebateColor &&
              self.coloring.rebate) {
            return { background: params.node.data.rebateColor, color: invertColor(params.node.data.rebateColor, true) }
          } else if (params.data &&
              params.data.statusColor &&
              self.coloring.status) {
            return { background: params.node.data.statusColor, color: invertColor(params.node.data.statusColor, true) }
          }
          // else if (params.data &&
          //     params.data.departmentColor &&
          //     self.coloring.company) {
          //     return { background: params.node.data.companyColor, color: invertColor(params.node.data.companyColor, true) }
          // }
        };
        
        if (cDefs[0].field != 'avatarRef') {
          cDefs[0].cellStyle = cellStyleFunc;
        } else {
          cDefs[1].cellStyle = cellStyleFunc;
        }
        const avatarColIndex = cDefs.findIndex(i => i.field == 'avatarRef');
        if (avatarColIndex != -1) {
          cDefs[avatarColIndex].cellStyle = cellStyleFunc; 
        }
        if (this.nonAdmin) {
          if (cDefs[0].field != 'avatarRef') {
            cDefs[0].checkboxSelection = true;
          } else {
            cDefs[1].checkboxSelection = true;
          }
        } else {
          cDefs.unshift(this.getRowSelectorColumn());
        }

        if (this.nonAdmin) {
          for (const c of cDefs) {
            c.editable = false;
          }
        }
        this.columnDefs = cDefs
        
        if (Array.isArray(this.importDataProperties) && this.importDataProperties.length > 0) {
          this.docImportProperties.splice(0, this.docImportProperties.length, ...this.importDataProperties)
        } else {
          this.docImportProperties.splice(0, this.docImportProperties.length)
        }
          
        // make sure the custom fields are selectable for import
        if (this.customFields) {
          for (const cfield of this.customFields) {
            const found = this.docImportProperties.find(v => v.value === cfield.name);
            if (!found) {
              this.docImportProperties.push({ value: cfield.name, text: cfield.displayName });
            }
          }
        }
        
        await this.loadViewProfile()
        this.mandatoryFields = this.entityService != null && typeof this.entityService.getMandatoryFields === 'function'? this.entityService.getMandatoryFields() : []
        if (this.isGridReady == true) {
          this.moveAvatarToFront();
          if (this.nonAdmin && this.preselected != null) {
            if (typeof this.preselected == 'string') {
              this.entitySelection.splice(0, this.entitySelection, { uuId: this.preselected });
            } else if (this.preselected.length > 0) {
              this.entitySelection.splice(0, this.entitySelection, ...this.preselected.map(i => ({ uuId: i })));
            }
          }
          //Setup the serverSideDatasource.
          const datasource = new ServerSideDatasource(self)
          if (this.gridApi != null && !this.gridApi.isDestroyed()) {
            this.gridApi.setGridOption('serverSideDatasource', datasource)
          }
          this.isGridReady = false
        }
      } else {
        this.columnDefs = []
        this.currentEditorComponent = null
      }
      
      this.isInitCompleted = true
    }
    , onGridReady(params) {
      this.gridApi = params.api;
      //Set up a flag when grid is ready before completion of init() call. e.g.: customFields data is not ready or view profile is not loaded. 
      //The flag will be used by init() to continue setup serverSideDataSource()
      //By doing this, the number of getRows() call is reduced to 1.
      if (this.isInitCompleted != true) {
        this.isGridReady = true
      } else {
        this.moveAvatarToFront();
        if (this.nonAdmin && this.preselected != null) {
          if (typeof this.preselected == 'string') {
            this.entitySelection.splice(0, this.entitySelection, { uuId: this.preselected });
          } else if (this.preselected.length > 0) {
            this.entitySelection.splice(0, this.entitySelection, ...this.preselected.map(i => ({ uuId: i })));
          }
        }
        params.api.setGridOption('serverSideDatasource', new ServerSideDatasource(this))
        
        // const self = this
        // const datasource = new ServerSideDatasource(self)
        // params.api.setGridOption('serverSideDatasource', datasource)
      }
    }
    , toggleSelectorShow(newValue) {
      this.selectorShow = newValue
    }
    , hidden() {
      this.alert = null
      this.inProgressShow = false;
      this.$emit('update:show', false)
      this.$emit('cancel', this.deletedIds)
    }
    , openEditor(isNew) {
      if(isNew) {
        this.entityId = `${this.entity}_NEW_${strRandom(5)}`
      } else {
        this.entityId = this.entitySelection[0].uuId
      }
      this.editorShow = true
      this.resetAlert()
    }
    , editorSuccess(payload) { 
      if (this.gridApi == null || this.gridApi.isDestroyed()) {
        return
      }
      this.keepAlertFeedback = true //Set true so that the alert msg is not removed when refreshing data
      this.resetAlert({ msg: payload.msg })
      this.triggeredByEditorSuccess = true
      this.previousVScrollPosition = this.gridApi.getVerticalPixelRange().top
      this.gridApi.refreshServerSide({ purge: true })
      this.scrollToTop()
    }
    
    , openDetail(id){
      this.entityId = id
      this.editorShow = true
      this.resetAlert()
    }
    , resetAlert({ msg=null, details=null, detailTitle=null, alertState=alertStateEnum.SUCCESS } = {}) {
      this.alertObj.msg = msg
      this.alertObj.state = alertState
      this.alertObj.msgDetails.title = detailTitle
      const list = this.alertObj.msgDetails.list
      if (details != null && Array.isArray(details)) {
        list.splice(0, list.length, ...details)
      } else {
        list.splice(0, list.length)
      }
    }
    , showSandboxInfo() {
      if (this.$store.state.sandbox.value &&
          (this.entity === 'USER' ||
           this.entity === 'ACCESS_POLICY' ||
           this.entity === 'WEBHOOK' ||
           this.entity === 'STAGE')) {
        this.resetAlert({ msg: this.$t(this.entity === 'USER' ? 
          'sandbox.user_live_changes' : 
          this.entity === 'ACCESS_POLICY' ? 
          'sandbox.access_policy_live_changes' :
          this.entity === 'WEBHOOK' ?
          'sandbox.webhook_live_changes' :
          'sandbox.stage_live_changes'), alertState: alertStateEnum.INFO })
        this.keepAlertFeedback = true; 
      }
    }
    , getEditorComponent(entity) {
      switch(entity) {
        case 'REBATE':
          return RebateModal
        case 'RESOURCE':
          return ResourceModal
        case 'SKILL':
          return SkillModal
        case 'LOCATION':
          return LocationModal
        case 'TEMPLATE__PROJECT':
          return TaskTemplateModal
        case 'STAGE':
          return StageModal
        case 'PROJECT':
          return ProjectModal
        case 'CUSTOMER':
          return CustomerModal
        case 'USER':
          return UserModal
        case 'ACCESS_POLICY':
          return AccessPolicyModal
        case 'TAG':
          return TagModal
        case 'WEBHOOK':
          return WebhookModal
        default: 
          return null
      }
    }
    , updateViewProfile({ clearViewName=true } = {}) {
      // clear the view name from profile
      if (clearViewName) {
        this.profileSettings[`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_view`] = this.viewName = null;
      }
      viewProfileService.update([this.profileSettings], this.userId)
      .catch((e) => {
        console.error(e) // eslint-disable-line no-console
      })
    }
    , createViewProfile() {
      return viewProfileService.create([this.profileSettings],
                        this.userId).then((response) => {  
        const data = response.data[response.data.jobCase]
        this.profileSettings.uuId = data[0].uuId
        this.newToProfile = true
      })
      .catch((e) => {
        console.error(e) // eslint-disable-line no-console
      })
    }
    , async loadViewProfile() {
      const profileData = await this.$store.dispatch('data/viewProfileList', this.userId).then((value) => {
        return value;
      })
      .catch((e) => {
        console.error(e) // eslint-disable-line no-console
      });
      
      if (profileData.length === 0) {
        await this.createViewProfile()
      }
      else {
        this.profileSettings = profileData[0]
        if (typeof this.profileSettings[this.profileKeySelector] != 'undefined') {
          this.loadColumnSettings(this, this.profileSettings[this.profileKeySelector])
          if (typeof this.entityService.getColorMenuOptions === 'function') {
            const colorMenuOptions = this.entityService.getColorMenuOptions()
            for (const optName in colorMenuOptions) {
              this.$set(this.coloring, optName, this.profileSettings[this.profileKeyColoring]? this.profileSettings[this.profileKeyColoring][optName] : colorMenuOptions[optName])
            }
          }
          this.filterText = typeof this.profileSettings[`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_search`] !== 'undefined' ? this.profileSettings[`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_search`] : '';
          this.badgeFilters = Array.isArray(this.profileSettings[`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_badgeFilters`])? this.profileSettings[`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_badgeFilters`] : [];
          this.viewName = this.profileSettings[`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_view`] != null? this.profileSettings[`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_view`] : null;

          this.filterPinned = typeof this.profileSettings[`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_filterPinned`] !== 'undefined' ? this.profileSettings[`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_filterPinned`] : false;
          this.badgeFilterPinned = typeof this.profileSettings[`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_badgeFilterPinned`] !== 'undefined' ? this.profileSettings[`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_badgeFilterPinned`] : false;

          //Resource (Non admin) selector's special settings
          if (this.nonAdmin && this.entity == 'RESOURCE' && this.profileSettings['resource_selector_bookings'] !== 'undefined') {
            this.bookings = this.profileSettings['resource_selector_bookings'];
          } else {
            this.bookings = false;
          }
        } else {
          this.newToProfile = true
          
          // clear out the filters from the previous modal
          this.badgeFilters.splice(0, this.badgeFilters.length);
          
          this.useDefault = true;
          const defaultView = this.views.find(v => v.defaultView);
          if (defaultView) {
            this.loadViewSettings(defaultView);
          }
        }
      }
    }
    , loadColumnSettings(target, columns) {
      if (target == null || columns == null || columns.length == 0) {
        this.profileColumnLoaded = true
        return
      }
      
      // order the columns based upon the order in 'columns'
      // 0 index column is reserved for rowSelector
      let idx = 1
      columns.forEach(function(col) {
        const index = target.columnDefs.findIndex((c) => c.colId === col.colId || c.field === col.colId)
        if (index !== -1) {
          target.columnDefs.splice(idx++, 0, target.columnDefs.splice(index, 1)[0])
        }
      })
      
      for (const column of target.columnDefs) {
        const setting = columns.filter(c => c.colId === column.colId || c.colId === column.field)
        if (setting.length === 0) {
          if (column.colId != 'rowSelector') {
            column.hide = true
            column.sort = null //reset to null to clean up previous view value
            column.sortIndex = null //reset to null to clean up previous view value
          }
        }
        else {
          column.hide = false
          column.width = setting[0].width
          column.sort = setting[0].sort
          column.sortIndex = setting[0].sortIndex
        }
      }

      //Rearrange sort Index if necessary
      const columnsWithSortIndex = target.columnDefs.filter(i => i.sortIndex != null);
      if (columnsWithSortIndex.length > 0) {
        columnsWithSortIndex.sort((a, b) => {
          if (a.sortIndex < b.sortIndex) {
            return -1;
          } else if (a.sortIndex > b.sortIndex) {
            return 1;
          }
          return 0;
        })
        
        for (let i = 0, len = columnsWithSortIndex.length; i < len; i++) {
          columnsWithSortIndex[i].sortIndex = i;
        }
      }

      if (target.gridApi != null && !target.gridApi.isDestroyed()) {
        target.gridApi.setGridOption('columnDefs', [])
        target.gridApi.setGridOption('columnDefs', cloneDeep(target.columnDefs))

      }
      this.profileColumnLoaded = true
      return
    }
    , async getModelInfo(entity) {
      const requests = [
        this.$store.dispatch('data/enumList')
      ]

      if (entity != 'WEBHOOK') {
        requests.unshift(this.$store.dispatch('data/info', {type: 'api', object: entity}));
      } else {
        requests.unshift(this.$store.dispatch('data/info', {type: 'webhook', object: 'MODELS'}));
      }

      await Promise.all(requests).then(responses => {
        if (entity != 'WEBHOOK') {
          const infoResp = responses[0];
          this.modelInfo = infoResp[entity].properties
        } else {
          //Special use case for WEBHOOK, modelInfo stores entity options
          const value = responses[0];
          this.modelInfo = value;
        }
        const enumResp = responses[1];
        if (enumResp != null) {
          if (enumResp.jobCase != null && enumResp[enumResp.jobCase] != null) {
            const propertyList = enumResp[enumResp.jobCase]
            const keys = Object.keys(propertyList);
            for (const k of keys) {
              const obj = propertyList[k]
              const codes = Object.keys(obj)
              const list = []
              for (const c of codes) {
                list.push({ value: c, text: c, num: obj[c] })
              }
              this.$set(this.enumList, k, list)
            }
          }

          //Fill up currency name property
          if (Array.isArray(this.enumList.CurrencyEnum)) {
            const currencyList = this.enumList.CurrencyEnum
            for (const item of currencyList)   {
              const found = currencies.find(i => i.code === item.value)
              if (found != null && found.name != null) {
                item.text = `${found.code} (${found.name})`
              }
            }
          }
        }
      })
      .catch(e => {
        this.httpAjaxError(e)
      })
    }
    , applyFilter(pattern) {
      // this.searchFilter = pattern
      this.filterText = pattern
      this.profileSettings[`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_search`] = pattern
      this.updateViewProfile()
      this.previousVScrollPosition = null
      this.gridApi.refreshServerSide({ purge: true })
    }
    , showDuplicateDialog() {
      this.resetAlert()
      const data = this.gridApi.getRowNode(this.entitySelection[0].uuId).data
      if (this.entity == 'USER') {
        this.duplicateFirstname = data.firstName
        this.duplicateLastname = data.lastName
        this.duplicateEmail = ''
      } else {
        const origName = data.name
        this.duplicateName = `${origName} ${this.$t('entity_selector.duplicate_name_suffix')}`
      }
      this.includeTasks = false
      this.duplicateShow = true
    }
    , duplicateOk() {
      this.duplicateEntity()
    }
    , duplicateEntity: debounce(function() {
      this.duplicateInProgress = true
      if(this.entity == 'USER') {
        if(!this.duplicateEmail || this.duplicateEmail.trim().length < 1) {
          this.errors.add({
            field: `duplicateEmail`,
            msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('staff.field.email')])
          });
        }
        if(!this.duplicateLastname || this.duplicateLastname.trim().length < 1) {
          this.errors.add({
            field: `duplicateLastname`,
            msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('staff.field.lastName')])
          });
        }
      } else if (this.entity == 'WEBHOOK') {
        if (!this.duplicateEntityField) {
          this.errors.add({
            field: `duplicateEntityField`,
            msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('webhook.field.entity')])
          });
        }
        if (!this.duplicateAction) {
          this.errors.add({
            field: `duplicateAction`,
            msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('webhook.field.action')])
          });
        }
        if (!this.duplicateUrl) {
          this.errors.add({
            field: `duplicateUrl`,
            msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('webhook.field.url')])
          });
        }
      } else {
        if(!this.duplicateName || this.duplicateName.trim().length < 1) {
          this.errors.add({
            field: `duplicate.name`,
            msg: this.$i18n.t('error.missing_argument', [this.$i18n.t(`${this.duplicateFieldNameLabel}`)])
          })
        }
      }
      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length < 1) {
          let selected = this.entitySelection[0].uuId
          let cloneData = { name: this.duplicateName }
          if (this.entity == 'USER') {
            cloneData = {
              email: this.duplicateEmail
              , firstName: this.duplicateFirstname
              , lastName: this.duplicateLastname
            }
          } else if (this.entity == 'WEBHOOK') {
            cloneData = {
              entity: this.duplicateEntityField
              , action: this.duplicateAction
              , url: this.duplicateUrl
            }
          }
          this.entityService.clone(selected, cloneData, { includeTask: this.includeTasks })
          .then(() => {
            this.resetAlert({ msg: this.$t(`${this.formattedEntity}.duplicate`) })
            this.previousVScrollPosition = this.gridApi.getVerticalPixelRange().top
            this.gridApi.refreshServerSide({ purge: true })
          })
          .catch(e => {
            let  alertMsg = this.$t('error.clone.failure', [this.$t(`entityType.${this.entity}`)])
            if(e.response && e.response.status == 404 && e.response.data && e.response.data.jobClue != null) {
              const clue = e.response.data.jobClue.clue
              let signal = false;
              if ('Not_unique_key' === clue) { 
                alertMsg = this.$i18n.t('user.error.duplicate_value', [this.$i18n.t('user.field.email')]);
                this.errors.add({
                  field: `duplicateEmail`,
                  msg: this.$i18n.t('user.error.duplicate_value', [this.$i18n.t('user.field.email')])
                });
                signal = true; //Set true to signal not to close dialog.
              } else if ('Unknown_holder' === clue) {
                alertMsg = this.$t(`entity_selector.${this.formattedEntity}_duplicate_error_not_found`)
              }
              if (!signal) {
                //Only show alert feedback when dialog is ready to be closed.
                this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR })
              }
              this.scrollToTop()
            }
            else if ('user.licence.limit' === e.response.data.jobClue.hint) {
              alertMsg = this.$t('user.error.user_limit_exceeded');
              this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR })
              this.scrollToTop()
            } else {
              this.httpAjaxError(e)
            }
          })
          .finally(() => {
            this.duplicateShow = false
            this.errors.clear()
            //Make sure the dialog is closed before reenable duplicate button to avoid button spamming.
            this.$nextTick(() => {
              this.duplicateInProgress = false
            })
          })
        } else {
          this.duplicateInProgress = false
        }
      })
    }, 100)
    
    , duplicateCancel() {
      this.duplicateShow = false
      this.errors.clear()
    }
    , scrollToTop() {
      setTimeout(() => {
        let elem = document.querySelector(`.${this.elemId}`);
        elem = elem != null? elem.querySelector('.modal-body') : null;
        elem = elem != null? elem.firstChild : null;
        if (elem != null && elem.scrollIntoView) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    }
    , async rowDelete() {
      this.resetAlert()
      this.deleteInProgressIds.splice(0, this.deleteInProgressIds.length)
      const targetColEntities = []

      if (this.entity === 'LOCATION') {
          
        const toDeleteIds = this.entitySelection.map(s => s.uuId);
        const canDelete = await this.entityService.list({ holder: toDeleteIds}, true).then(response => {
          let ret = true;
          for (const res of response.data) {
            if (res.count > 1) {
              ret = false;
              break;
            }
          }
          return ret;
        }).catch(e => {
          // eslint-disable-next-line
          console.error(e);
        });
        
        if (!canDelete) {
          this.cannotDeleteModalShow = true;
          return;
        } 
        // else {
        //   this.deletedIds.push(...(this.entitySelection.map(i => i.uuId)));
        // }
      }
      
      const getCustomNameFunc = this.entityService != null && typeof this.entityService.getCustomName == 'function'? this.entityService.getCustomName : null

      for (const task of this.entitySelection) {
        const rowNode = this.gridApi.getRowNode(task.uuId)
        if (rowNode == null) {
          continue
        }
        targetColEntities.push({
          uuId: rowNode.id
          , name: getCustomNameFunc != null? getCustomNameFunc(rowNode.data) : rowNode.data.name
          , parent: rowNode.data.pUuId
          , parentName: rowNode.data.pName
          , colId: this.targetColumnId
        })
      }

      const preRowDeleteHandler 
        = this.entityService != null 
          && this.entityService.preRowDeleteHandler != null 
          && typeof this.entityService.preRowDeleteHandler == 'function'? this.entityService.preRowDeleteHandler(this) : null

      if (preRowDeleteHandler != null && typeof preRowDeleteHandler.execute == 'function') {
        if (preRowDeleteHandler.isAsync == true) {
          await preRowDeleteHandler.execute(targetColEntities)
        } else {
          preRowDeleteHandler.execute(targetColEntities)
        }
      }
      
      if (targetColEntities.length > 0) {
        //Prepare data for taskcol delete confirmation dialog
        this.ecConfirmDeleteEntities = targetColEntities
        this.prepareTargetColConfirmDeleteDialog()
      }
    }
    , async fileImport() {
      const existingData = await this.entityService.listNames({ start: 0, limit: -1 }).then((response) => {
        return response.data;
      });
      
      this.existingData = existingData;
      this.docImportShow = true
    }
    , async docImportOk({ items }) {
      this.docImportShow = false
      this.inProgressShow = true
      this.resetAlert()
      await this.addEntities(items)
      .catch(() => {
        //Fail gracefully
      })
      this.inProgressShow = false
      this.previousVScrollPosition = this.gridApi.getVerticalPixelRange().top
      this.gridApi.refreshServerSide({ purge: true })
    }
    , docImportCancel() {
      this.docImportShow = false
    }
    , async addEntities(items) {
      this.inProgressLabel = this.$t(`entity_selector.${this.formattedEntity}_import_progress`, [0])
      let percentage = 0
      const self = this
      const errorFunc = (e) => {
        self.httpAjaxError(e)
      }
      
      if (typeof this.importDataFunc == 'function') {
        for (const item of items) {
          await this.importDataFunc(item, errorFunc)
          percentage++
          this.inProgressLabel = this.$t(`entity_selector.${this.formattedEntity}_import_progress`, [parseFloat(percentage / items.length * 100).toFixed(0)])
        }
      }

      // if (typeof this.entityService.importData == 'function') {
      //   for (const item of items) {
      //     await this.entityService.importData(item, errorFunc, self)
      //     percentage++
      //     this.inProgressLabel = this.$t(`entity_selector.${this.formattedEntity}_import_progress`, [parseFloat(percentage / items.length * 100).toFixed(0)])
      //   }
      // }
    }
    , fileExport() {
      this.inProgressShow = true
      this.inProgressLabel = this.$t('dataview.exporting')
      this.exportData = true
      
      let listener = () =>{
        const keys = this.gridApi
          .getColumns()
          .filter(column => column.getColId() != 'rowSelector')
          .map(column => column.getColId())
        
        this.gridApi.exportDataAsExcel({ 
          fileName: this.$t(`entity_selector.${this.formattedEntity}_export_filename`)
          , sheetName: this.$t(`entity_selector.${this.formattedEntity}_export_sheetname`)
          , columnKeys: keys
          , rowHeight: 20
          , processCellCallback: this.processCellCallback()
        })
      
        this.exportData = false
        this.inProgressShow = false
        this.gridApi.removeEventListener('modelUpdated', listener)
      }
      this.previousVScrollPosition = this.gridApi.getVerticalPixelRange().top
      this.gridApi.refreshServerSide({purge: true})
      this.gridApi.addEventListener('modelUpdated', listener)
    }
    , processCellCallback() {
      const propertyHandler = this.exportDataPropertyHandler
      const customFieldHandler = getCustomFieldExportDataPropertyHandler(this.customFields)
      return function(params) {
        if (propertyHandler != null && Object.hasOwn(propertyHandler, params.column.colId)) {
          return propertyHandler[params.column.colId](params)
        } else if (Object.hasOwn(customFieldHandler, params.column.colId)) {
          return customFieldHandler[params.column.colId](params)
        } else if (params.column.colId.indexOf('uuId') !== -1) {
          return params.node.data.name
        }
        return params.value
      }
    }
    , onColoringOver() {
      this.$refs.coloring.visible = true
      const elems = document.querySelectorAll('.ag-header-cell-resize');
      for (const el of elems) {
        el.style.pointerEvents = "none";
      }
    }
    , onColoringLeave() {
      this.$refs.coloring.visible = false
      const elems = document.querySelectorAll('.ag-header-cell-resize');
      for (const el of elems) {
        el.style.pointerEvents = "auto";
      }
    }
    , isTouchDevice() {
      const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
      const mq = function (query) {
          return window.matchMedia(query).matches
      }
      if ('ontouchstart' in window) {
          return true
      }
      const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('')
      return mq(query)
    }
    , onColorChange(val) {
      for (const key of Object.keys(this.coloring)) {
        this.coloring[key] = false
      }
      this.coloring[val] = true
      this.coloring = objectClone(this.coloring) //Reassign value to force vue to rerender the elements
      this.profileSettings[this.profileKeyColoring] = this.coloring
      this.updateViewProfile()
      this.gridApi.redrawRows()
    }
    , getRowSelectorColumn() {
      return {
        headerName: ''
        , colId: 'rowSelector'
        , field: 'name'
        , width: 48
        , minWidth: 48
        , maxWidth: 48
        , hide: false
        , cellRenderer: 'rowSelectorCellRenderer'
        , cellRendererParams: {
          isReadOnly: !this.canEdit(this.entity)
          , enableReadonlyStyle: true
        }
        , pinned: 'left'
        , lockPosition: 'left'
        , lockVisible: true
        , suppressColumnsToolPanel: true

        , menuTabs: ['generalMenuTab']
        , resizable: false
        , headerComponent: 'selectorHeaderComponent'
        , suppressFillHandle: true 
        , rowDrag: false
      }
    }
    //Referenced in RowSelector.vue
    , rowSelectorMouseDown(rowIndex=null) {
      if (rowIndex == null) {
        return
      }
      
      //Consolidate all ranges's row and column details into rowColumnMap 
      const rowColumnMap = new Map()
      const cellRanges = this.gridApi.getCellRanges()
      for (const cRange of cellRanges) {
        const rowStartIndex = cRange.startRow.rowIndex > cRange.endRow.rowIndex? cRange.endRow.rowIndex : cRange.startRow.rowIndex
        const rowEndIndex = cRange.startRow.rowIndex > cRange.endRow.rowIndex? cRange.startRow.rowIndex : cRange.endRow.rowIndex
        const columns = cRange.columns.map(i => i.colId)
        if (rowStartIndex == rowEndIndex) {
          if (!rowColumnMap.has(rowStartIndex)) {
            rowColumnMap.set(rowStartIndex, new Set())
          }
          const rCol = rowColumnMap.get(rowStartIndex)
          for (const col of columns) {
            if (col == 'rowSelector') {
              continue
            }
            rCol.add(col)
          }
          continue
        }

        for (let i = rowStartIndex; i <= rowEndIndex; i++) {
          if (!rowColumnMap.has(i)) {
            rowColumnMap.set(i, new Set())
          }
          const rCol = rowColumnMap.get(i)
          for (const col of columns) {
            rCol.add(col)
          }
        }
      }

      const maxColumnsLength = this.gridApi.getColumnState().filter(i => i.hide != true && i.colId != 'rowSelector').length
      //Reset list
      this.rowSelectorClicked_allColsSelectedRowIndexList.splice(0, this.rowSelectorClicked_allColsSelectedRowIndexList.length)
      //Check which row has full set of columns in range selection.
      //When the row has full set, add it to tobeUnselected list
      for (let [key, value] of rowColumnMap) {
        if (value.size == maxColumnsLength) {
          this.rowSelectorClicked_allColsSelectedRowIndexList.push(key)
        }
      }
    }
    //Referenced in RowSelector.vue
    , rowSelectorMouseUp({ ctrlKey=false, shiftKey=false, rowIndex=null } = {}) {
      const cellRanges = this.gridApi.getCellRanges()
      const originalRanges = cellRanges.map(i => {
        return {
          rowStartIndex: i.startRow.rowIndex,
          rowEndIndex: i.endRow.rowIndex,
          columns: i.columns.map(j => j.colId)
        }
      })

      //Handle case when shift key is pressed and click on row selector
      if (shiftKey == true && rowIndex != null) {
        const focusedCell = this.gridApi.getFocusedCell()
        const focusedCellRowIndex = focusedCell.rowIndex
        const focusedCellColId = focusedCell.column.colId

        let cellRanges = this.gridApi.getCellRanges()
        cellRanges = cellRanges.map(i => {
          return {
            rowStartIndex: i.startRow.rowIndex > i.endRow.rowIndex? i.endRow.rowIndex : i.startRow.rowIndex,
            rowEndIndex: i.startRow.rowIndex > i.endRow.rowIndex? i.startRow.rowIndex : i.endRow.rowIndex,
            columns: i.columns.map(i => i.colId)
          }
        })
        const newRanges = []
        
        let isDirectionUp = rowIndex < focusedCellRowIndex
        let newStartRowIndex = rowIndex > focusedCellRowIndex? focusedCellRowIndex : rowIndex
        let newEndRowIndex = rowIndex > focusedCellRowIndex? rowIndex : focusedCellRowIndex
        
        //Handle case when both shift key and ctrl key are pressed
        if (ctrlKey == true) {
          //Remove last range if there is any. New range will be created as replacement later.
          if (cellRanges.length > 0) {
            cellRanges.splice(cellRanges.length-1 , 1)
          }

          //Reshape previous ranges to avoid new range created between the row which last focused cell resides and the row which user click
          for (const cRange of cellRanges) {
            const isClashed = (newStartRowIndex >= cRange.rowStartIndex && newStartRowIndex <= cRange.rowEndIndex) ||
                                (cRange.rowStartIndex >= newStartRowIndex && cRange.rowStartIndex <= newEndRowIndex)
            
            if (!isClashed) {
              //Transfer the range to newCellRanges when there is no row clashed with last range.
              newRanges.push({
                rowStartIndex: cRange.rowStartIndex,
                rowEndIndex: cRange.rowEndIndex,
                columns: cRange.columns
              })
              continue
            }

            //split existing range to exclude the last range's selected rows.
            if (cRange.rowStartIndex < newStartRowIndex && cRange.rowEndIndex >= newStartRowIndex) {
              newRanges.push({
                rowStartIndex: cRange.rowStartIndex,
                rowEndIndex: newStartRowIndex-1,
                columns: cRange.columns
              })
            }
            if (cRange.rowEndIndex > newEndRowIndex && cRange.rowStartIndex <= newEndRowIndex) {
              newRanges.push({
                rowStartIndex: newEndRowIndex+1,
                rowEndIndex: cRange.rowEndIndex,
                columns: cRange.columns
              })
            }
          }
        }

        //New range replacing last range if there is any
        newRanges.push({
          rowStartIndex: isDirectionUp? newEndRowIndex: newStartRowIndex,
          rowEndIndex: isDirectionUp? newStartRowIndex : newEndRowIndex,
          columns: this.gridApi.getColumnState().filter(i => i.hide != true && i.colId != 'rowSelector').map(i => i.colId)
        })

        this.gridApi.clearRangeSelection()
        for (const nRange of newRanges) {
          this.gridApi.addCellRange(nRange)
        }
        this.gridApi.setFocusedCell(focusedCellRowIndex, focusedCellColId)
        return
      }

      //Handle edge case: shift click rowSelector when there is no existing range selection.
      if (cellRanges.length == 0) {
        const curFocusedCell = this.gridApi.getFocusedCell()
        if (curFocusedCell == null) {
          this.gridApi.clearFocusedCell()
          return
        }
        const rowIndex = this.gridApi.getFocusedCell().rowIndex
        this.gridApi.addCellRange({
          rowStartIndex: rowIndex,
          rowEndIndex: rowIndex,
          columns: this.gridApi.getColumnState().filter(i => i.hide != true && i.colId != 'rowSelector').map(i => i.colId)
        })
        this.gridApi.setFocusedCell(rowIndex, this.targetColumnId, null)
        return
      }

      const lastRange = (() => {
        const tmp = cellRanges[cellRanges.length-1]
        const isAsc = tmp.startRow.rowIndex <= tmp.endRow.rowIndex
        return {
          rowStartIndex: isAsc? tmp.startRow.rowIndex : tmp.endRow.rowIndex,
          rowEndIndex: isAsc? tmp.endRow.rowIndex : tmp.startRow.rowIndex,
          columns: tmp.columns.map(i => i.colId),
          startColumn: tmp.startColumn.colId,
          orgRowStartIndex: tmp.startRow.rowIndex,
          orgRowEndIndex: tmp.endRow.rowIndex
        }
      })()

      this.rowSelectorChanged({ 
        cellRanges,
        originalRanges,
        lastRange,
        api: this.gridApi
      })
    }
    , rowSelectorChanged({ cellRanges, originalRanges, lastRange, api }) {
      //Brief: Depends on user interaction, add/remove cell range selection.
      //General Specification:
      //If startCell clashes with previous ranges, remove all cell in the new range.
      //If startCell does not clash with previous ranges, select all cells in the new range. (reshape cell in previous ranges to avoid new range).
      //If only rowSelector column in the new range,
      //   - Clash or not clash becomes irrelavant
      //   - Select all cell if not all cells are selected in the row. Otherwise, remove selected cell from any ranges.
      //   - If row count is more than one, focus on 1st row (starting row)

      if (cellRanges.length > 0) {
        const startColumnId = lastRange.startColumn
        const previousRanges = cellRanges.slice(0, cellRanges.length-1).map(i => {
          const isAsc = i.startRow.rowIndex <= i.endRow.rowIndex
          return {
            rowStartIndex: isAsc? i.startRow.rowIndex : i.endRow.rowIndex,
            rowEndIndex: isAsc? i.endRow.rowIndex : i.startRow.rowIndex,
            columns: i.columns.map(j => j.colId)
          }
        })

        // //Check is last range is single row or multiple row selection
        // const isSingleRow = lastRange.rowEndIndex - lastRange.rowStartIndex == 0
        
        //Check if last range's start cell clashes with previous cell ranges.
        const startCell = {
          rowStartIndex: lastRange.orgRowStartIndex,
          rowEndIndex: lastRange.orgRowStartIndex
        }
        let isStartCellClashed = false
        for (const range of previousRanges) {
          const isCellRangeAsc = range.rowStartIndex <= range.rowEndIndex
          const cellRangeStartIndex = isCellRangeAsc? range.rowStartIndex : range.rowEndIndex
          const cellRangeEndIndex = isCellRangeAsc? range.rowEndIndex : range.rowStartIndex
          const isRowClashed = startCell.rowStartIndex >= cellRangeStartIndex && startCell.rowEndIndex <= cellRangeEndIndex
          const columnClashed = range.columns.filter(i => i == startColumnId).length > 0
          if (isRowClashed && columnClashed) {
            isStartCellClashed = true
            break
          }
        }

        //Determine to unselect or select row
        let toUnselect = this.rowSelectorClicked_allColsSelectedRowIndexList.includes(startCell.rowStartIndex)

        //Prepare new cell ranges for previous ranges
        const newCellRanges = []
        for (const range of previousRanges) {

          const isClashedWithLastRange = (lastRange.rowStartIndex >= range.rowStartIndex && lastRange.rowStartIndex <= range.rowEndIndex) ||
                                          (range.rowStartIndex >= lastRange.rowStartIndex && range.rowStartIndex <= lastRange.rowEndIndex)

          if (!isClashedWithLastRange) {
            //Transfer the range to newCellRanges when there is no row clashed with last range.
            newCellRanges.push({
              rowStartIndex: range.rowStartIndex,
              rowEndIndex: range.rowEndIndex,
              columns: range.columns
            })
            continue
          }

          //split existing range to exclude the last range's selected rows.
          if (range.rowStartIndex < lastRange.rowStartIndex && range.rowEndIndex >= lastRange.rowStartIndex) {
            newCellRanges.push({
              rowStartIndex: range.rowStartIndex,
              rowEndIndex: lastRange.rowStartIndex-1,
              columns: range.columns
            })
          }
          if (range.rowEndIndex > lastRange.rowEndIndex && range.rowStartIndex <= lastRange.rowEndIndex) {
            newCellRanges.push({
              rowStartIndex: lastRange.rowEndIndex+1,
              rowEndIndex: range.rowEndIndex,
              columns: range.columns
            })
          }
        }

        if (!isStartCellClashed && !toUnselect) {
          //Prepare new cell range for last range
          newCellRanges.push({
            rowStartIndex: lastRange.rowStartIndex,
            rowEndIndex: lastRange.rowEndIndex,
            columns: api.getColumnState().filter(i => i.hide != true && i.colId != 'rowSelector').map(i => i.colId)
          })
          if (lastRange.startColumn == 'rowSelector') {
            api.setFocusedCell(lastRange.orgRowStartIndex, this.targetColumnId, null)
          }
        }

        if (toUnselect) {
          if (lastRange.startColumn == 'rowSelector') {
            api.setFocusedCell(lastRange.orgRowStartIndex, this.targetColumnId, null)
          }
        }
        
        //Check against original ranges. Don't update cell ranges if there is no changes. It helps to avoid infinite loop.
        if (JSON.stringify(originalRanges) != JSON.stringify(newCellRanges)) {
          api.clearRangeSelection()
          for (const newRange of newCellRanges) {
            api.addCellRange(newRange)
          }
        } else {
          const rowNodes = api.rowModel.rowsToDisplay
          const selection = new Set()
          for (const range of originalRanges) {
            let startIdx = range.rowStartIndex
            let endIdx = range.rowEndIndex
            if (startIdx > endIdx) {
              startIdx = range.rowEndIndex
              endIdx = range.rowStartIndex
            }

            for (let i = startIdx; i <= endIdx; i++) {
              selection.add(rowNodes[i].data.uuId)
            }
          }
        }
        
      }
    }
    , async processValueChanged(api, { customProgressLabel=null } = {}) {
      if (customProgressLabel != null) {
        this.inProgressLabel = customProgressLabel
      } else {
        this.inProgressLabel = this.$t(`entity_selector.${this.formattedEntity}_update_progress`)
      }
      this.inProgressShow = true
      
      if (this.pendingProcessRequestList.length > 250) {
        do {
          this.pendingRequestBatchList.push(this.pendingProcessRequestList.splice(0, 250))
        } while(this.pendingProcessRequestList.length > 250)
      }

      //Prepare to call compose api request and end the session
      if (this.processValueChangedList.length == 0 && this.processDateValueChangedList.length == 0) {
        //Add the requests as a new batch to batch list.
        if (this.pendingProcessRequestList.length > 0) {
          const requests = this.pendingProcessRequestList.splice(0, this.pendingProcessRequestList.length)
          this.pendingRequestBatchList.push(requests)
        }

        //Process the request batch
        if (this.pendingRequestBatchList.length > 0) {
          const bList = this.pendingRequestBatchList.splice(0, this.pendingRequestBatchList.length)
          this.resetAlert()
          for (const b of bList) {
            await compositeService.exec(b)
            .then(response => {
              if (response.status == 207 && response.data != null && response.data.jobCase != null) {
                const feedbackList = response.data[response.data.jobCase]
                if (feedbackList != null && feedbackList.length > 0) {
                  this.keepAlertFeedback = true
                  if (typeof this.entityService.handleAjaxError == 'function') {
                    const result = this.entityService.handleAjaxError(this, feedbackList)
                    if (result.msg != null) {
                      this.resetAlert({ msg: result.msg, alertState: alertStateEnum.ERROR })
                      this.scrollToTop()
                    } else {
                      const msg = handleCustomFieldError(feedbackList, this.columnDefs, this);
                      this.resetAlert({ msg: msg != null? msg : this.$t('entity_selector.error.partial_update_operation'), alertState: alertStateEnum.WARNING })
                      this.scrollToTop()
                    }
                  } else {
                    const msg = handleCustomFieldError(feedbackList, this.columnDefs, this);
                    this.resetAlert({ msg: msg != null? msg : this.$t('entity_selector.error.partial_update_operation'), alertState: alertStateEnum.WARNING })
                    this.scrollToTop()
                  }
                }
              }
            })
            .catch(e => {
              this.keepAlertFeedback = true
              this.inProgressShow = false
              let httpStatus = null
              if (e.response != null && e.response.status != null) {
                httpStatus = e.response.status
              }
              if ((httpStatus == 422 || httpStatus == 424) && e.response.data != null && e.response.data.jobCase != null) {
                const feedbackList = e.response.data[e.response.data.jobCase]
                if (feedbackList != null && feedbackList.length > 0) {
                  if (typeof this.entityService.handleAjaxError == 'function') {
                    const result = this.entityService.handleAjaxError(this, feedbackList)
                    if (result.msg != null) {
                      this.resetAlert({ msg: result.msg, alertState: alertStateEnum.ERROR })
                      this.keepAlertFeedback = true;
                      this.scrollToTop()
                    } else {
                      this.httpAjaxError(e)
                    }
                  } else {
                    this.httpAjaxError(e)
                  }
                } else {
                  this.httpAjaxError(e)
                }
              } else {
                this.httpAjaxError(e)
              }
            })
          }
          this.previousVScrollPosition = this.gridApi.getVerticalPixelRange().top
          this.gridApi.refreshServerSide({ purge: true })
        } else {
          this.inProgressShow = false
        }
        return
      }

      const handler = this.valueChangedHandler != null? this.valueChangedHandler : {}

      let isPendingOtherProcess = false;
      do {
        let currentItem = null
        if (this.processDateValueChangedList.length > 0) {
          currentItem = this.processDateValueChangedList.shift()
        } else {
          currentItem = this.processValueChangedList.shift()
        }

        // const colId = currentItem.colId
        const property = currentItem.property
        const oldValue = currentItem.oldValue
        const newValue = currentItem.newValue
        // const rowData = currentItem.data
        let entityId = currentItem.entityId

        if (handler[property] != null && typeof handler[property].execute === 'function') {
          let result
          const oVal = oldValue != null? oldValue : handler[property].defaultValueIfNull
          if (handler[property].isAsync) {
            result = await handler[property].execute(entityId, oVal, newValue)
          } else {
            result = handler[property].execute(entityId, oVal, newValue)
          }
          if (result.status == operationStatus.SUCCESS) {
            if (result.isDataProp) {
              //This flow is meant for handling custom data format for entity's property (not link/edge)
              const entityObj = { uuId: entityId }
              const blankToNullList = ['currencyCode', 'complexity', 'durationAUM', 'priority']
              const newVal = result.value
              if (typeof newVal == 'string' && newVal.trim().length == 0 && blankToNullList.includes(property)) {
                entityObj[property] = null
              } else {
                entityObj[property] = newVal
              }
              this.pendingProcessRequestList.push({
                method: 'PUT',
                invoke: this.entityService.entityUpdateApiUrl,
                body: [entityObj],
                vars: [],
                note: `${this.entity}_update__${property}`
              })
            } else if (result.value.length > 0) {
              //This flow is meant for handling custom data format for entity's property which is a link/edge
              this.pendingProcessRequestList.push(...result.value)
            }
          } else if (result.status == operationStatus.ABORT) {
            const prop = result.property != null? result.property : property
            const val = Object.hasOwn(result, 'value')? result.value : oldValue
            
            const rowNode = api.getRowNode(entityId)
            if (rowNode != null) {
              const data = rowNode.data
              data[prop] = val
              rowNode.setData(data)
            }
          }
          
        } else if ('tag' === property) {
          const request = await this.updateTags(entityId, oldValue, newValue)
          if (request.length > 0) {
            this.pendingProcessRequestList.push(...request)
          }
        } else { // update entity
          const entityObj = { uuId: entityId }
          const blankToNullList = ['currencyCode', 'complexity', 'durationAUM', 'priority']
          if (typeof newValue == 'string' && newValue.trim().length == 0 && blankToNullList.includes(property)) {
            entityObj[property] = null
          } else if (property === 'fixedDuration') {
            // duration values need to be converted to minutes
            entityObj[property] = convertDisplayToDuration(`${newValue}D`, this.durationConversionOpts).value;
          } else {
            entityObj[property] = newValue
          }
          
          this.pendingProcessRequestList.push({
            method: 'PUT',
            invoke: this.entityService.entityUpdateApiUrl,
            body: [entityObj],
            vars: [],
            note: `${this.entity}_update__${property}`
          })
        }

      } while(this.processValueChangedList.length > 0);

      if(!isPendingOtherProcess && this.processValueChangedList.length == 0 && this.processDateValueChangedList.length == 0) {
        this.inProgressShow = false;
        //Last, call itself again to begin next iteration
        this.processValueChanged(api);
      }
    }
    , resetFocus(api, cell) {
      api.clearRangeSelection()
      if (cell != null && cell.rowIndex != null && cell.colId != null) {
        api.setFocusedCell(cell.rowIndex, cell.colId, null)
        api.addCellRange({
          rowStartIndex: cell.rowIndex
          , rowEndIndex: cell.rowIndex
          , columnStart: cell.colId
          , columnEnd: cell.colId
        })
      }
    }
    , async updateTags(entityId, oldTagList, newTagList) {
      const oldTagNames = objectClone(oldTagList)
      const oldTagFilters = []
      for(const t of oldTagNames) {
        if (t == null || t.trim().length == 0) {
          continue
        }
        oldTagFilters.push({ value: t.trim(), regex: false })
      }      
      let oldTags
      if (oldTagFilters.length > 0) {
        oldTags = await tagService.list_with_filters({filters: oldTagFilters}).then((response) => {
          return response.data.filter(i => i.uuId != null)
        })
      } else {
        oldTags = []
      }

      const newTagNames = objectClone(newTagList)
      const newTagFilters = []
      if (Array.isArray(newTagNames)) {
        for(const t of newTagNames) {
          if (t == null || t.trim().length == 0) {
            continue
          }
          newTagFilters.push({ value: t.trim(), regex: false })
        }
      }
      
      let newTags
      if (newTagFilters.length > 0) {
        newTags = await tagService.list_with_filters({filters: newTagFilters}).then((response) => {
          const data = response.data
          const list = []
          for (const f of newTagFilters) {
            const found = data.find(i => i.name.localeCompare(f.value, undefined, { sensitivity: 'base' }) === 0)
            if (found != null) {
              list.push(found)
            }else {
              list.push({ uuId: null, name: f.value })
            }
          }
          return list
        })
      } else {
        newTags = []
      }
      return this.updateTag(entityId, oldTags, newTags)
    }
    , updateTag(entityId, oldTagList, updatedTagList) {
      let _entityPath = this.entity
      if (_entityPath != null && _entityPath.indexOf('__') > -1) {
        _entityPath = _entityPath.replace('__', '/')  
      }
      
      const originTagList = oldTagList != null && Array.isArray(oldTagList)? oldTagList.map(i => { return { uuId: i.uuId, name: i.name } }) : []
      const tagList = updatedTagList != null && Array.isArray(updatedTagList)? updatedTagList.map(i => { return { uuId: i.uuId, name: i.name } }) : []
      const toAdd = []
      const toUpdate = []
      const unchangedIds = []
      for(const tag of tagList) {
        const index = originTagList.findIndex(j => j.uuId === tag.uuId)
        if(index == -1) {
          toAdd.push(tag)
        } else {
          unchangedIds.push(tag.uuId)
        }
      }

      const toAddIds = toAdd.map(i => i.uuId)
      const toUpdateIds = toUpdate.map(i => i.uuId)
      const toRemove = originTagList.filter(i => !toAddIds.includes(i.uuId) && !toUpdateIds.includes(i.uuId) && !unchangedIds.includes(i.uuId))
      const requests = []

      if(toAdd.length > 0) {
        const addTagReqTemplate = (refId, tagName) => {
          const list = []
          tagList.forEach(i => {
            list.push( {
              uuId: i.uuId
            })
          })
          return {
            method: 'POST',
            invoke: '/api/tag/add',
            body: [{ 
            name: tagName
            }],
            vars: [{ 
              name: refId,
              path: '$.feedbackList.uuId'
            }],
            note: `addTag__${refId}`
          }
        }
        const addTagLinkReqTemplate = (refId, entityId, tagList) => {
          const list = []
          tagList.forEach(i => {
            list.push( {
              uuId: i.uuId
              , name: i.name
            })
          })
          return {
            method: 'POST',
            invoke: `/api/${_entityPath}/link/tag/add`,
            body: { 
              uuId: entityId,
              tagList: list
            },
            vars: [],
            note: `${this.entity}_AddTagLink__${refId}`
          }
        }
        
        // for (const [index, tag] of toAdd.entries()) {
        for (const tag of toAdd) {
          let refId = randomString(8)
          if (tag.uuId == null) {
            refId = `tagUuId_${refId}`
            tag.uuId = `@{${refId}}`
            requests.push(addTagReqTemplate(refId, tag.name))
          }
          requests.push(addTagLinkReqTemplate(`${entityId}_${refId}`, entityId, [tag]))
        }
      }

      if(toRemove.length > 0) {
        const removeTagLinkReqTemplate = (refId, entityId, tagList) => {
          const list = []
          tagList.forEach(i => {
            list.push( {
              uuId: i.uuId
            })
          })
          return {
            method: 'POST',
            invoke: `/api/${_entityPath}/link/tag/delete`,
            body: { 
              uuId: entityId,
              tagList: list
            },
            vars: [],
            note: `${this.entity}_RemoveTagLink__${refId}`
          }
        }
        for (const [index, tag] of toRemove.entries()) {
          requests.push(removeTagLinkReqTemplate(`${index}_${entityId}`, entityId, [tag]))
        }
      }

      return requests
    }
    , keyDownHandler(e) {
      if (!this.show) {
        return
      }
      if (this.gridOptions == null || this.gridApi == null) {
        return
      }
      
      if (!e.target.classList.contains('ag-cell') && e.target.tagName != 'BODY' || e.target.classList.contains('modal-open')) {
        return
      }

      if (e.key == 'Delete' && e.target.closest('.generic-selector') == null 
          && this.gridApi.getCellRanges() != null && this.gridApi.getCellRanges().length > 0) {
        //Construct the necessary payload value and call the cellKeyDown method 
        this.onCellKeyDown({ api: this.gridApi, event: { keyCode: e.keyCode, key: e.key } })
      } else if (e.keyCode == 36 || e.keyCode == 35) {//Home & End Key
        const api = this.gridApi
        if (api == null) {
          return
        }
        e.stopPropagation()
        e.preventDefault()
        if (e.shiftKey) {
          return
        }
        const rowCount = api.getDisplayedRowCount()
        if (rowCount == 0) {
          return
        }

        const firstRowIndex = 0
        const lastRowIndex = rowCount - 1
        const columns = this.gridApi.getAllDisplayedColumns().filter(i => i.colId != 'rowSelector').map(i => i.colId)
        const startColumn = columns[0]
        const lastColumn = columns[columns.length - 1]

        const rowIndex = e.keyCode == 36? firstRowIndex : lastRowIndex
        const colId = e.keyCode == 36? startColumn : lastColumn
        const vPosition = e.keyCode == 36? 'top' : 'bottom'

        if (e.ctrlKey) {
          this.navigateCellTo(api, rowIndex, colId, vPosition)
        } else {
          const focusedCell = api.getFocusedCell()
          if (focusedCell != null) {
            this.navigateCellTo(api, focusedCell.rowIndex, colId, null)
          }
        }
        return
      } else if (e.keyCode == 37) { //ArrowLeft Key
        if (e.ctrlKey != true) {
          return 
        }
        e.stopPropagation()
        e.preventDefault()
        const api = this.gridApi
        const focusedCell = api.getFocusedCell()
        if (focusedCell != null) {
          const columns = this.gridApi.getAllDisplayedColumns().filter(i => i.colId != 'rowSelector').map(i => i.colId)
          this.navigateCellTo(api, focusedCell.rowIndex, columns[0], null)
        }
      } else if (e.ctrlKey && e.keyCode == 65) {
        e.stopPropagation()
        e.preventDefault()
        const api = this.gridApi
        const totalCount = api.getDisplayedRowCount()
        if (totalCount == 0) {
          return
        }
        const columns = this.gridApi.getAllDisplayedColumns().filter(i => i.colId != 'rowSelector').map(i => i.colId)

        api.clearRangeSelection()
        api.addCellRange({
          rowStartIndex: 0,
          rowEndIndex: totalCount - 1,
          columns
        })
        //Set a focus cell if there is none
        const focusCell = api.getFocusedCell()
        if (focusCell != null) {
          return
        }
        api.setFocusedCell(0, columns[0], null)
      } else if (e.ctrlKey && e.keyCode == 68) {//'D'
        e.stopPropagation()
        e.preventDefault()
      }

    }
    //Refer in detailLink.vue (CellRenderer)
    , getRowColor(data) {
      if (data == null) {
        return ''
      }
      if (this.coloring[this.formattedEntity] && data.color) {
        return data.color
      } else if (this.coloring.location && data.locationColor) {
        return data.locationColor
      } else if (this.coloring.company && data.companyColor) {
        return data.companyColor
      } else if (this.coloring.project && data.projectColor) {
        return data.projectColor
      } else if (this.coloring.customer && data.customerColor) {
        return data.customerColor
      } else if (this.coloring.rebate && data.rebateColor) {
        return data.rebateColor
      } else if (this.coloring.status && data.statusColor) {
        return data.statusColor
      }
    }
    , navigateCellTo(api, pRowIndex, pColId, vPosition=null) {
      setTimeout(() => {
        let rowIndex = pRowIndex
        const colId = pColId
        api.clearRangeSelection()
        api.setFocusedCell(rowIndex, colId, null)
        api.addCellRange({
          rowStartIndex: rowIndex
          , rowEndIndex: rowIndex
          , columns: [colId]
        })
        api.ensureIndexVisible(rowIndex, vPosition)
        api.ensureColumnVisible(colId, 'auto')
      })
    }
    , async onCellKeyDown(params) {
      if (params.event.key == 'Delete' && this.canDelete(this.entity)) {
        const getCustomNameFunc = this.entityService != null && typeof this.entityService.getCustomName == 'function'? this.entityService.getCustomName : null
        const cellRanges = params.api.getCellRanges()
        //Prepare cell information
        const targetColEntities = []
        const processedCells = [] //Used to elimate duplicate records.
        for (const cRange of cellRanges) {
          const startRowIdx = cRange.startRow.rowIndex <= cRange.endRow.rowIndex? cRange.startRow.rowIndex : cRange.endRow.rowIndex
          const lastRowIdx = cRange.startRow.rowIndex <= cRange.endRow.rowIndex? cRange.endRow.rowIndex : cRange.startRow.rowIndex 
          
          const columns = cRange.columns
          for (let idx = startRowIdx; idx <= lastRowIdx; idx++) {
            const rowNode = params.api.getDisplayedRowAtIndex(idx)
            
            for (const column of columns) {
              const colId = column.colId
              const rowId = rowNode.data.uuId
              const found = processedCells.find(i => rowId == i.rowId && (this.targetColumnId == i.colId || colId == i.colId))
              if (found != null) {
                continue //Duplicated cell is found. Process to next iteration.
              }
              processedCells.push({ rowId, colId })

              //Handle 'targetColumn' cell
              //Brief: Delete targetColumn cell means remove the whole row
              if (this.targetColumnId == colId) {
                targetColEntities.push({
                   uuId: rowNode.id
                  , name: getCustomNameFunc != null? getCustomNameFunc(rowNode.data) : rowNode.data.name
                  , parent: rowNode.data.pUuId
                  , parentName: rowNode.data.pName
                  , colId
                })
                continue
              }

              let propertyValue = null
              if (rowNode.data[colId] == null) {
                if (this.customSrcValueHandler[colId] != null) {
                  propertyValue = this.customSrcValueHandler[colId](rowNode.data)
                } else {
                  continue //Skip when the property value is null.
                }
              } else {
                propertyValue = rowNode.data[colId]
              }
              
              //Handle non targetColumn cell
              //Skip when the cell is not editable
              let isEditable
              if (typeof this.defaultColDef.editable === 'function') {
                // isEditable = this.defaultColDef.editable({ data: { uuId: rowId, taskType: rowNode.data.taskType }})
                isEditable = this.defaultColDef.editable({ data: { uuId: rowId }})
              } else if (typeof this.defaultColDef.editable === 'boolean') {
                isEditable = this.defaultColDef.editable
              }
              const colDef = this.columnDefs.find(i => i.colId == colId || i.field == colId)
              if (typeof colDef.editable === 'function') {
                // isEditable = colDef.editable({ data: { uuId: rowId, taskType: rowNode.data.taskType }})
                isEditable = colDef.editable({ data: { uuId: rowId }})
              } else if (typeof colDef.editable === 'boolean') {
                isEditable = colDef.editable
              }
              if (!isEditable) {
                continue
              }
              
              this.pendingDeleteCells.push({
                colId
                , data: objectClone(rowNode.data)
                , property: colId
                , entityId: rowId
                , parenId: rowNode.data.pUuId
                , taskName: rowNode.data.name
                // , taskType: rowNode.data.taskType
                // , isTaskCol: false
                , value: propertyValue
              })
            }
          }
        }
        
        if (targetColEntities.length > 0) {
          const preRowDeleteHandler 
            = this.entityService != null 
              && this.entityService.preRowDeleteHandler != null 
              && typeof this.entityService.preRowDeleteHandler == 'function'? this.entityService.preRowDeleteHandler(this) : null

          if (preRowDeleteHandler != null && typeof preRowDeleteHandler.execute == 'function') {
            if (preRowDeleteHandler.isAsync == true) {
              await preRowDeleteHandler.execute(targetColEntities)
            } else {
              preRowDeleteHandler.execute(targetColEntities)
            }
          }

          //Prepare data for taskcol delete confirmation dialog
          this.ecConfirmDeleteEntities = targetColEntities
          this.prepareTargetColConfirmDeleteDialog()
        } else if (this.pendingDeleteCells.length > 0) {
          this.deleteCell()
        }
      } else if ((params.event.keyCode == 13 || params.event.keyCode == 68) && params.event.ctrlKey) {
        const api = params.api
        if (api == null) {
          return
        }

        const focusedCell = api.getFocusedCell()
        if (focusedCell == null) {
          return
        }

        const cellRanges = api.getCellRanges()
        if (cellRanges.length == 0 || (cellRanges.length > 1 && params.event.keyCode == 68)) { //Ctrl+D supports only single range
          return
        }

        const fRange = cellRanges.find(i => {
          if (i.startRow != null && i.endRow != null && i.columns.find(j => j.colId == focusedCell.column.colId) != null) {
            return i.startRow.rowIndex <= focusedCell.rowIndex && focusedCell.rowIndex <= i.endRow.rowIndex
          }
          return false
        })
        if (fRange == null) {
          return
        }
        
        //Stop operation when range is a single cell selection because nothing to copy and fill.
        if (fRange.startRow.rowIndex == fRange.endRow.rowIndex && fRange.columns.length == 1) {
          return
        }

        const rowIndex = fRange.startRow.rowIndex < fRange.endRow.rowIndex? fRange.startRow.rowIndex : fRange.endRow.rowIndex
        this.ctrlEnterFillCell(api, rowIndex, fRange.columns[0].colId)
        return
      } else if (params.event.keyCode == 13) {
        //Navigate to next cell when user press enter on the read-only cell.
        let editable = false
        const api = params.api
        if (api == null) {
          return
        }
        const fRowIndex = params.rowIndex
        const column = params.column
        if (typeof column.colDef.editable == 'boolean') {
          editable = column.colDef.editable
        } else if (typeof column.colDef.editable == 'function') {
          const data = params.data
          editable = column.colDef.editable({ data })
        }
        if (!editable) {
          let rowIndex
          if (params.event.shiftKey) {
            rowIndex = fRowIndex - 1
            if (rowIndex < 0) {
              rowIndex = 0
            }
          } else {
            rowIndex = fRowIndex + 1
            const lastRowIndex = api.getDisplayedRowCount() - 1
            if (rowIndex > lastRowIndex) {
              rowIndex = lastRowIndex
            }
          }
          //Navigate to next cell below when user press enter on ready only cell.
          if (api.getDisplayedRowAtIndex(rowIndex) != null) {
            this.navigateCellTo(api, rowIndex, column.colId, null)
          }
        }
      } else if (params.event.key == '.') { //Fixed the issue of cell editing is not triggered by '.' key
        const editingCells = params.api.getEditingCells()
        if (editingCells.length == 0) {
          params.api.setFocusedCell(params.rowIndex, params.column.colId);
          params.api.startEditingCell({
            rowIndex: params.rowIndex,
            colKey: params.column.colId,
            key: params.event.key
          });
        }
      }
    }
    , fillOperation(params) {
      //Skip when the target cell is not editable or is rowSelector.
      const payload = { 
        node: params.rowNode
        , data: params.rowNode.data
        , column: params.column
        , colDef: params.column.colDef
        , api: params.api
        , context: params.context
      }
      
      if ((typeof params.column.colDef.editable === 'function' && !params.column.colDef.editable(payload)) || 
          !params.column.colDef.editable || params.column.colId == 'rowSelector') {
        return params.currentCellValue
      }

      //Skip when the target cell is blacklisted
      const blackListed = ['totalActualDuration']
      if (blackListed.includes(params.column.colId)) {
        return params.currentCellValue
      }

      //Used to signal batch operation in onCellValueChanged event.
      this.triggeredByFillOperation = true

      //Find the source Column, source rowNode based on the available details in params object.
      const tgtRowNode = params.rowNode
      const targetColId = params.column.colId
      let srcRowNode = null 
      let sourceColId = null
      
      if (params.direction == 'left' || params.direction == 'right') {
        srcRowNode = tgtRowNode //Default: Same row as target.
        const columns = params.api.getColumnState().filter(i => i.hide != true).map(i => i.colId)
        const targetColIdx = columns.findIndex(i => i == targetColId)
        let sourceColIdx = targetColIdx - params.currentIndex - 1
        if (params.direction == 'left') {
          sourceColIdx = targetColIdx + params.currentIndex + 1
        }
        sourceColId = columns[sourceColIdx]
      } else {// direction: up or down
        let srcRowIndex = tgtRowNode.rowIndex - params.currentIndex - 1
        if (params.direction == 'up') {
          srcRowIndex = tgtRowNode.rowIndex + params.currentIndex + 1
        }
        srcRowNode = params.api.getDisplayedRowAtIndex(srcRowIndex)
        sourceColId = targetColId //Default: Same column as source.
      }

      let srcProperty = sourceColId
      let tgtProperty = targetColId
      //Check if the source and target's property value type are compatible
      if (srcProperty != tgtProperty) {
        if (sourceColId == this.targetColumnId) {
          srcProperty = 'name'
        }
        if (targetColId == this.targetColumnId) {
          tgtProperty = 'name'
        }
        const compatibleResult = this.propertyCompatibleFunc(srcProperty, tgtProperty)
        if (!compatibleResult.status) {
          return tgtRowNode.data[compatibleResult.colId != null? compatibleResult.colId : tgtProperty]
        }
      }

      let srcValue = null
      if (this.customSrcValueHandler != null && this.customSrcValueHandler[sourceColId] != null) {
        srcValue = this.customSrcValueHandler[sourceColId](srcRowNode.data)
      } else {
        srcValue = srcRowNode.data[sourceColId == this.targetColumnId? 'name' : sourceColId]
      }
      let tgtValue = objectClone(tgtRowNode.data[targetColId])

      //Skip when the target cell is a mandatory field and the new value is either blank or null
      if (this.mandatoryFields.length > 0 && 
          this.mandatoryFields.includes(tgtProperty) && 
          (srcValue == null || typeof srcValue == 'string' && srcValue.trim().length == 0 ||
            Array.isArray(srcValue) && srcValue.length == 0)) {
        return tgtValue
      }

      const result = this.propertyCopyOperation(tgtProperty, srcValue, tgtRowNode.data)
      if (result.status == operationStatus.ABORT) {
        return tgtValue
      }
      return result.value
    }
    , processCellForClipboard(params) {
      const rowData = params.node.data
      let srcColId = params.column.colId

      if (srcColId == this.targetColumnId) {
        if (this.entityService.customClipboardUuIdCellColId != null) {
          srcColId = this.entityService.customClipboardUuIdCellColId
        } else {
          srcColId = 'name'  
        }
      }

      const srcRowId = rowData.uuId
      const srcRowData = params.api.getRowNode(srcRowId).data
      let newValue = srcRowData[srcColId]
      let property = srcColId
      if (Object.hasOwn(this.entityService, 'customCellForClipboardHandler')) {
        const handler = this.entityService.customCellForClipboardHandler(this)
        if (handler[srcColId] != null) {
          const result = handler[srcColId](srcRowData)
          if (result.srcColId != undefined) {
            srcColId = result.srcColId
          }
          if (result.newValue != undefined) {
            newValue = result.newValue
          }
        }
      }

      
      
      
      const source = {
        colId: srcColId
        // , data: objectClone(srcRowData)
        , value: newValue
        , property
        , entityId: srcRowData.uuId
        // , parentId: srcRowData.pUuId
        // , taskName: srcRowData.name
        // , taskType: srcRowData.taskType
      }

      return this.cellCopyPrefix+JSON.stringify(source)
    }
    , processCellFromClipboard(params) {
      let rowData = params.node.data
      //Skip when the target cell is not editable or is rowSelector.
      const payload = { 
        node: params.node
        , data: params.node.data
        , column: params.column
        , colDef: params.column.colDef
        , api: params.api
        , context: params.context
      }
 
      if ((typeof params.column.colDef.editable === 'function' && !params.column.colDef.editable(payload)) || 
          !params.column.colDef.editable || params.column.colId == 'rowSelector') {
        return rowData[params.column.colId]
      }

      let colId = params.column.colId
      
      if (colId == this.targetColumnId) {
        if (this.entityService.customClipboardUuIdCellColId != null) {
          colId = this.entityService.customClipboardUuIdCellColId
        } else {
          colId = 'name'  
        }
      }
      
      //Skip when the target cell is blacklisted
      const blackListed = ['totalActualDuration']
      if (blackListed.includes(colId)) {
        return rowData[colId]
      }

      let source = params.value
      //Defensive code: return original value when the value is not from aggrid cell copy.
      if (typeof source !== 'string' || !source.startsWith(this.cellCopyPrefix)) {
        return rowData[colId]
      }

      //Restore value to JSON object.
      source = source.substring(this.cellCopyPrefix.length)
      source = JSON.parse(source)
      
      const target = {
        colId: colId
        , data: objectClone(rowData)
        , value: rowData[colId]
        , property: colId
        , entityId:   rowData.uuId
        , parentId: rowData.pUuId
        , entityName: rowData.name
      }

      if (source.property != target.property) {
        const compatibleResult = this.propertyCompatibleFunc(source.property, target.property)
        if (!compatibleResult.status) {
          return rowData[compatibleResult.colId != null? compatibleResult.colId : target.property]
        }
      }

      let srcValue = source.value

      //Skip when the target cell is a mandatory field and the new value is either blank or null
      if (this.mandatoryFields.length > 0 && 
          this.mandatoryFields.includes(target.property) && 
          (srcValue == null || typeof srcValue == 'string' && srcValue.trim().length == 0 ||
            Array.isArray(srcValue) && srcValue.length == 0)) {
        return target.value
      }

      const result = this.propertyCopyOperation(target.property, srcValue, target.data)
      if (result.status == operationStatus.ABORT) {
        return result.colId != null? 
          rowData[result.colId] : (result.value != null? result.value : target.value)
      }
      return result.value
    }
    , prepareTargetCellData(colId, rowData, { color=null }={}) {
      const target = {
        colId: colId
        , data: objectClone(rowData)
        , oldValue: rowData[colId]
        , property: colId
        , entityId:   rowData.uuId
        , parentId: rowData.pUuId
        , taskName: rowData.name
        , taskType: rowData.taskType
        , color: color
      }

      return target
    }
    , ctrlEnterFillCellPropCheck_note(srcValue, tgtValue) {
      const isTgtTaskCol = tgtValue.colId.startsWith(this.taskColPrefix)
      let tgtNotes = tgtValue
      if (isTgtTaskCol) {
        if (tgtValue.single == null) {
          tgtValue.single = []
        }
        tgtNotes = tgtValue.single
      }
      if (srcValue.length == 0 && tgtNotes.length == 0) {
        // return rowData[colId] //No change when both lists are empty.
        return { action: 'continue' }
      }

      //Remove the top item from the tgtNotes when src is empty
      if (srcValue.length == 0) {
        tgtNotes.shift()
        return { action: 'add', newValue: tgtNotes }
      }

      const newNote = objectClone(srcValue[0])
      if (newNote.identifier == null || newNote.identifier.trim().length == 0) {
        delete newNote.identifier
      }
      delete newNote.uuId
      tgtNotes.unshift(newNote)
      return { action: 'add', newValue: tgtNotes }
    }
    , ctrlEnterFillCellPropCheck_staff(srcValue, tgtValue) {
      const isTgtTaskCol = tgtValue.colId.startsWith(this.taskColPrefix)
      let tgtStaffs = tgtValue
      if (isTgtTaskCol) {
        if (tgtValue.single == null) {
          tgtValue.single = []
        }
        tgtStaffs = tgtValue.single
      }
      if (srcValue.length == 0 && tgtStaffs.length == 0) {
        //No change when both lists are empty.
        return { action: 'continue' }
      }

      let newValue = []
      if (srcValue.length > 0) {
        const updatedStaffs = []
        for (const srcStaff of srcValue) {
          const found = tgtStaffs.find(i => i.uuId == srcStaff.uuId)
          if (found != null) {
            //Update tgtStaff with srcStaff's utilization. Keep the rest.
            found.utilization = srcStaff.utilization
            updatedStaffs.push(found)
          } else {
            //Clean up workEffort (duration, durationAUM) when add to new list.
            delete srcStaff.duration
            delete srcStaff.durationAUM
            updatedStaffs.push(srcStaff)
          }
        }
        newValue = updatedStaffs
      }
      return { action: 'add', newValue }
    }
    , ctrlEnterFillCell(api, rowIndex, srcColId) {
      const srcRowNode = api.getDisplayedRowAtIndex(rowIndex)
      if (srcRowNode == null) {
        return
      }
      
      //Prepare source (property) value
      const srcRowData = srcRowNode.data
      const isSrcTaskCol = srcColId.startsWith(this.taskColPrefix)

      const source = {
        colId: srcColId
        , value: srcRowData[srcColId]
        , property: srcColId
        , taskId:   srcRowData.uuId
      }

      if (isSrcTaskCol) {
        const valueObj = source.value
        source.value = valueObj.single 
        source.property = valueObj.property
        source.taskId = valueObj.uuId != null? valueObj.uuId : null
      }

      //Skip when source is taskCol and has no valid task
      if (isSrcTaskCol && source.taskId == null) {
        return
      }

      const pendingFilled = []
      const cellRanges = api.getCellRanges()
      for (const cRange of cellRanges) {
        const rowStartIndex = cRange.startRow.rowIndex < cRange.endRow.rowIndex? cRange.startRow.rowIndex : cRange.endRow.rowIndex
        const rowEndIndex = cRange.startRow.rowIndex < cRange.endRow.rowIndex? cRange.endRow.rowIndex : cRange.startRow.rowIndex
        const columns = cRange.columns.map(i => { return { colId: i.colId, color: i.userProvidedColDef.color }})
        for (let i = rowStartIndex; i <= rowEndIndex; i++) {
          const curRowNode = api.getDisplayedRowAtIndex(i)
          if (curRowNode == null) {
            continue
          }

          const tgtRowData = curRowNode.data
          for (const col of columns) {
            if (col.colId === srcColId && rowIndex == i) {
              continue //Skip when current cell (rowIndex & colId) is source cell
            }
            const target = this.prepareTargetCellData(col.colId, tgtRowData, { color: col.color })

            if (source.property != target.property) {
              const compatibleResult = this.propertyCompatibleFunc(source.property, target.property)
              if (!compatibleResult.status) {
                continue
              }
            }

            let srcValue = source.value

            const result = this.propertyCopyOperation(target.property, srcValue, tgtRowData)
            if (result.status == operationStatus.ABORT) {
              continue
            }
            target.newValue = result.value
            pendingFilled.push(target)
          }
        }
      }

      if (pendingFilled.length > 0) {
        this.processValueChangedList.push(...pendingFilled)
        this.inProgressLabel = this.$t('task.progress.updating_tasks')
        this.processValueChanged(api)
      }
    }
    , onPasteStart(/** params */) {
      this.isPasteInProgress = true
    }
    , onPasteEnd(params) {
      this.isPasteInProgress = false
      this.consolidateChangedCellValues(this.processValueChangedList)
      if (this.processValueChangedList.length > 0) {
        this.inProgressLabel = this.$t('task.progress.paste_tasks')
        this.processValueChanged(params.api)  
      }
    }
    , consolidateChangedCellValues(valueChangedList) {
      if (valueChangedList == null || !Array.isArray(valueChangedList)) {
        return
      }
      if (this.consolidatePropertyGroupWhenValueChangedFunc != null) {
        const resultList = this.consolidatePropertyGroupWhenValueChangedFunc(valueChangedList)
        valueChangedList.splice(0, valueChangedList.length, ...resultList)
      }
    }
    , navigateToNextCell(params) {
      const previousCellPosition = params.previousCellPosition
      const nextCellPosition = params.nextCellPosition
      if (nextCellPosition == null) {
        return previousCellPosition
      }
      //Clear range selection when move focus from cell to header.
      if (nextCellPosition.rowIndex < 0 && previousCellPosition.rowIndex > -1) {
        params.api.clearRangeSelection()
      }
      // Stay in previousCell when next Cell belonged to rowSelector column.
      if (nextCellPosition.column.colId === 'rowSelector') {
        return previousCellPosition
      }
      return nextCellPosition
    }
    , tabToNextCell(params) {
      //Fix for the bug: Multiple tabToNextCell events are fired when tab in cell editing.
      const curColId = params.previousCellPosition.column.colId
      const columns = params.api.getAllDisplayedColumns().filter(i => i.colId != 'rowSelector')
      let rowIndex = params.previousCellPosition.rowIndex
      let index = columns.findIndex(i => i.colId == curColId)
      let nextColIdx
      if (index == 0 && params.backwards) {
        rowIndex -= 1
        nextColIdx = columns.length - 1
      } else if (index == columns.length-1 && !params.backwards) {
        rowIndex += 1
        nextColIdx = 0
      } else if (params.backwards) {
        nextColIdx = index - 1
      } else {
        nextColIdx = index + 1
      }
      const column = columns[nextColIdx]

      if  (this.tabTimeoutId == null) {
        this.tabRowIndex = rowIndex
        this.tabColumn = column
        this.tabTimeoutId = setTimeout(() => {
          this.tabRowIndex = null
          this.tabColumn = null
          this.tabTimeoutId = null
        }, 0)
        setTimeout(() => {
          params.api.clearRangeSelection()
          params.api.setFocusedCell(rowIndex, column.colId)
          params.api.addCellRange({
            rowStartIndex: rowIndex
            , rowEndIndex: rowIndex
            , columns: [column.colId]
          })
          params.api.ensureIndexVisible(rowIndex, null)
          params.api.ensureColumnVisible(column.colId, 'auto')
        }, 0)
        return params.previousCellPosition
      }
      //Skip cell navigation. 
      //Pro: to break infinite loop
      //Cons: will cause cell focus appear in the top left html element (e.g. Company logo)
      //Hence, a really short timeout duration is used to minimise the appearance of the cell focus.
      return null
    }
    , cellFocused(event) {
      if (event.rowIndex != null && event.column != null) {
        this.lastFocusedCell = { rowIndex: event.rowIndex, colId: event.column.colId }  
      }
    }
    , async deleteCell() {
      //deleteCell() expects only non targetColumn cell.
      const pendingItems = []
      const cells = this.pendingDeleteCells.splice(0, this.pendingDeleteCells.length)
      const mandatoryFields = this.entityService != null && typeof this.entityService.getMandatoryFields == 'function'? this.entityService.getMandatoryFields() : []
      for (const cell of cells) {
        // Skip deleting cell when the property of the cell is a mandatory property/field of entity.
        if (mandatoryFields.includes(cell.property)) {
          continue
        }
        cell.oldValue = cell.value
        if (cell.property == 'color') {
          cell.newValue = null
        } else {
          //Some models may update the property in data. Hence, it is important that cell.data is passed as reference to keep the change and will be used later.
          const result = this.propertyDeleteOperation(cell.property, cell.data)
          if (result.state == operationStatus.ABORT) {
            continue
          }
          if (Object.hasOwn(result, 'oldValue')) {
            cell.oldValue = result.oldValue
          }
          cell.newValue = result.value
        }
        delete cell.value
        pendingItems.push(cell)
      }
      
      if (pendingItems.length > 0) {
        this.consolidateChangedCellValues(pendingItems)

        const data = []
        for (const item of pendingItems) {
          const rowData = item.data //use item.data for applyTransaction because some delete actions may update other property values in the data. (e.g totalActualDuration)
          if (Object.hasOwn(rowData, item.colId)) {
            rowData[item.colId] = item.newValue
            data.push(rowData)
          }
          //rowData[item.colId] = item.newValue
        }
        if (data.length > 0) {
          this.gridApi.applyTransaction({ update: data })
        }


        this.processValueChangedList.push(...pendingItems)
      }

      this.processValueChanged(this.gridApi, { customProgressLabel: this.$t(`entity_selector.${this.formattedEntity}_delete_progress`) })
    }
    , ecConfirmDeleteOk() {
      const entities = []
      if (this.entityCol.cantDeleteDetails == null) {
        entities.push({ 
          entityId: this.entityCol.entityId
          , parentId: this.entityCol.parentId 
          , colId: this.entityCol.colId
        })
      }
      if (this.entityCol.applyAll == true) {
        const cantDeleteList = []
        const clonedList = cloneDeep(this.ecConfirmDeleteEntities)
        while(clonedList.length > 0) {
          const i = clonedList.shift()
          if (i.cantDeleteDetails == null) {
            entities.push({
              entityId: i.uuId
              , parentId: i.parent
              , colId: i.colId
            })
          } else {
            cantDeleteList.push(i)
          }
        }

        if (cantDeleteList.length > 0) {
          this.ecConfirmDeleteEntities.splice(0, this.ecConfirmDeleteEntities.length, ...cantDeleteList)  
        } else {
          this.ecConfirmDeleteEntities.splice(0, this.ecConfirmDeleteEntities.length)
        }
      }
      
      const deleteTaskReqTemplate = (entityId) => {
        return {
          method: 'POST',
          invoke: this.entityService.entityDeleteApiUrl,
          body: [{
            uuId: entityId,
          }],
          vars: [],
          note: `${this.entity}_delete__${entityId}`
        }
      }
      const toBeUpdated = []
      const toBeRemoved = []
      
      const toBeRemovedRowIndexes = []
      for(const entity of entities) {
        this.pendingProcessRequestList.push(deleteTaskReqTemplate(entity.entityId))
        this.deleteInProgressIds.push(entity.entityId)
        const rowNode = this.gridApi.getRowNode(entity.entityId)
        const rowData = objectClone(rowNode.data)
        toBeRemoved.push(rowData)
        toBeRemovedRowIndexes.push(rowNode.rowIndex)
      }

      //Adjust focused cell position to cell above (if possible) when the row which focused cell belongs to is removed.
      let suggestedRowIndex = -1
      if (toBeRemovedRowIndexes.length > 0) {
        const currentFocusedCell = this.gridApi.getFocusedCell()
        if (currentFocusedCell != null) {
          const rowIndex = currentFocusedCell.rowIndex
          const colId = currentFocusedCell.column.colId
          const rowPinned = currentFocusedCell.rowPinned
          const isFocusedCellAffected = toBeRemovedRowIndexes.includes(rowIndex)
          if (isFocusedCellAffected) {
            suggestedRowIndex = rowIndex - 1
            while (toBeRemovedRowIndexes.includes(suggestedRowIndex)) {
              suggestedRowIndex = suggestedRowIndex - 1
            }
          }
          if (suggestedRowIndex > -1) {
            this.gridApi.clearRangeSelection()
            this.gridApi.setFocusedCell(suggestedRowIndex, colId, rowPinned)
          }
        }
      }

      if (toBeUpdated.length > 0 || toBeRemoved.length > 0) {
        this.gridApi.applyServerSideTransaction({ update: toBeUpdated.length > 0? toBeUpdated : null, remove: toBeRemoved.length > 0? toBeRemoved : null })
      }
      
      setTimeout(() => {
        this.prepareTargetColConfirmDeleteDialog()
      }, 300)
    }
    , prepareTargetColConfirmDeleteDialog() {
      if (this.ecConfirmDeleteEntities == null || this.ecConfirmDeleteEntities.length == 0) {
        this.deletedIds.push(...this.deleteInProgressIds)
        this.deleteInProgressIds.splice(0, this.deleteInProgressIds.length)
        this.deleteCell()
        return
      }
      
      const entity = this.ecConfirmDeleteEntities.shift()
      this.entityCol.entityId = entity.uuId
      this.entityCol.entityName = entity.name
      this.entityCol.parentName = entity.parent == null || entity.parent == 'ROOT'? null : entity.parentName
      this.entityCol.applyAll = false
      this.entityCol.parentId = entity.parent
      this.entityCol.colId = entity.colId
      this.entityCol.cantDeleteDetails = entity.cantDeleteDetails != null? entity.cantDeleteDetails : null
      this.inProgressShow = false
      this.ecConfirmDeleteShow = true
    }
    , ecConfirmDeleteCancel() {
      if (this.pendingProcessRequestList.length > 0) {
        this.deletedIds.push(...this.deleteInProgressIds)
        this.deleteInProgressIds.splice(0, this.deleteInProgressIds.length)
        this.deleteCell()
      }
    }
    , propertyDeleteOperation(property, data) {
      //Custom handling logic
      let handler
      if (Object.hasOwn(this.entityService, 'getPropertyDeleteHandler')) {
        handler = this.entityService.getPropertyDeleteHandler(this)
      } else {
        handler = {}
      }
      
      if (handler[property] != null) {
        const val = handler[property]
        if (typeof val === 'function') {
          const result = val(data)
          if (result != null && operationStatusKeys.includes(result.status)) {
            return { found: true, value: result.value, oldValue: result.oldValue, status: result.status }  //found prop is used for debug
          }
        } else {
          return { found: true, value: val, status: operationStatus.SUCCESS } //found prop is used for debug
        }
      }


      //Default handling logic
      let value = data[property]
      if (typeof value == 'string') {
        value = ''
      } else if (typeof value == 'number') {
        value = 0
      } else if (typeof value == 'object') {
        value = { uuId: null }
      } else if (Array.isArray(value)) {
        value = []
      }
      return { found: false, value, status: operationStatus.SUCCESS }
    }
    , propertyCopyOperation(property, srcValue, tgtData) {
      const handler = this.propertyCopyHandler != null? this.propertyCopyHandler : {}
      //When no handler for specific property, just copy value
      if (!Object.hasOwn(handler, property)) {
        return { value: srcValue, status: operationStatus.SUCCESS }
      }

      const val = handler[property]
      if (typeof val === 'function') {
        const result = val(srcValue, tgtData)
        if (result != null && operationStatusKeys.includes(result.status)) {
          return result
        }
      }
      return { value: val, status: operationStatus.SUCCESS }
    }
    , httpAjaxError(e) {
      console.error(e) //eslint-disable-line no-console
      const response = e.response;
      let alertMsg = this.$t('error.internal_server')
      if (response && 403 === response.status) {
        alertMsg = this.$t('error.authorize_action')
      } else if (422 === response?.status) {
        const feedbackList = response.data[response.data.jobCase];
        const msg = handleCustomFieldError(feedbackList, this.columnDefs, this);
        if (msg != null) {
          alertMsg = msg;
        }
      }
      this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR })
      this.scrollToTop();
    }
    , mergeTag() {
      this.mergeTo = null
      if (this.entitySelection.length == 1) {
        const rowNode = this.gridApi.getRowNode(this.entitySelection[0])
        if (rowNode != null && rowNode.data != null) {
          this.mergeFrom = rowNode.data.name
        }
      }
      this.mergeTagShow = true
    }
    , async mergeOk() {
      this.mergeTagShow = false
      const mergeToUuid = this.valuesMap[this.mergeTo.toLowerCase()].uuId
      const mergeFromUuid = this.valuesMap[this.mergeFrom.toLowerCase()].uuId
      const links = await tagService.listLinks({ filter: mergeFromUuid }).then((response) => {
        return response.data[0]
      });
      
      const cmdList = [];
      for (const key of Object.keys(links)) {
        for (const uuId of links[key]) {
          let path = key;
          if (key === 'projectTemplate') {
            path = 'template/project';
          }
          else if (key === 'taskTemplate') {
            path = 'template/task';
          }
          cmdList.push({
            "invoke": `/api/${path}/link/tag/add`,
            "body": {
              uuId: uuId,
              tagList: [{ uuId: mergeToUuid }]
            }
          });
        }
      }
      
      // delete the merge from tag
      cmdList.push({
        "invoke": `/api/tag/delete`,
        "body": [{ uuId: mergeFromUuid }]
      });
      
      await compositeService.exec(cmdList)
      .then(response => {
        const feedbackList = response.data.feedbackList;
        if (Array.isArray(feedbackList) && 
              feedbackList.length > 0 && 
              feedbackList[0].uuId != null) {
          return feedbackList[0].uuId;
        }
      })
      .catch((e) => {
        this.httpAjaxError(e);
        return null;
      });
      this.resetAlert({ msg: this.$t('tag.merge_success'), alertState: alertStateEnum.SUCCESS })
      this.gridApi.refreshServerSide({ purge: true })
    }
    , lackOfMandatoryField() {
      return this.entityService != null && typeof this.entityService.lackOfMandatoryField === 'function'? this.entityService.lackOfMandatoryField() : false
    }
    , showNoRowLabel(msg=null) {
      this.noRowsMessage = msg
      let api = this.gridApi
      if (api != null && !api.isDestroyed()) {
        api.hideOverlay()
        setTimeout(() => {
          if (api != null && !api.isDestroyed()) {
            api.showNoRowsOverlay()
          }
        })
      }
    }
    , syncLdap() {
      const self = this;
      this.inProgressShow = true;
      this.inProgressLabel = this.$t('user.progress.sync_ldap');
      this.entityService.syncLdap().then(() => {
        this.gridApi.refreshServerSide({ purge: true });
        this.inProgressShow = false;
      })
      .catch(function(error) {
        console.error(error); // eslint-disable-line no-console
        self.inProgressShow = false;
      });
    }
    , alias() {
      const self = this;
      this.entityService.switchUser(this.entitySelection[0].uuId).then(() => {
        location.reload();
        this.inProgressShow = false;
      })
      .catch(function(error) {
        console.error(error); // eslint-disable-line no-console
        self.inProgressShow = false;
        self.resetAlert({ msg: self.$t('user.alias_failed'), alertState: alertStateEnum.ERROR })
      });
    }
    , prepareNoRowsMessage() {
      if (this.noRowsMessage != null) {
        return this.noRowsMessage
      }
      return this.$t(`entity_selector.${this.formattedEntity}_grid_no_data`)
    }
    , onCellEditingStarted(/** event **/) {
      this.isCellEditing = true;
    }
    , onCellEditingStopped(/** event **/) {
      this.isCellEditing = false;
    }
    , onFilterOver() {
      this.$refs.filter.visible = true;
    }
    , onFilterLeave() {
      this.$refs.filter.visible = false;
    }
    , onFilterTextDropdownHide(bvEvent) {
      if(this.filterTextFocus){
        bvEvent.preventDefault();
      }
    }
    , onFilterSubmit() {
      this.filterTextFocus = false;
      if (this.$refs.filter != null) {
        this.$refs.filter.visible = false;
      }
      this.closePriorityNavDropdown = true; //Signal priorityNavigation to close the dropdown.
      
      this.applyFilter(this.filterText);
    }
    , onFilterClear() {
      this.filterText = '';
      this.applyFilter(this.filterText);
    }
    , onBadgeFilterModified(filter) {
      this.badgeFilterFocus = true; //Pin the badgeFilter when a change is made.
      const removedFilterFields = this.badgeFilters.filter(i => filter.find(j => j.field == i.field) == null).map(i => i.field);
      this.badgeFilters = filter;
      
      for (const f of filter) {
        if (Object.hasOwn(this.badgeFilterFieldValues, f.field)) {
          if (Array.isArray(f.value) && f.value.length > 0) {
            this.badgeFilterFieldValues[f.field].forEach(i => {
              i.checked = f.value.find(j => {
                if (typeof i.text === 'string') {
                  return j.text.localeCompare(i.text, undefined, { sensitivity: 'base' }) == 0
                }
                return j.text == i.text
              }) != null
            });
          } else {
            this.badgeFilterFieldValues[f.field].forEach(i => {
              if (i.checked) {
                i.checked = false;
              }
            });
          }
        }
      }
      //reset checked state for the previously selected but now removed filter
      if (removedFilterFields.length > 0) {
        for (const f of removedFilterFields) {
          if (Object.hasOwn(this.badgeFilterFieldValues, f)) {
            this.badgeFilterFieldValues[f].forEach(i => {
              if (i.checked) {
                i.checked = false;
              }
            });
           
          }
        }
      }
      this.badgeFilterFieldValues = JSON.parse(JSON.stringify(this.badgeFilterFieldValues)); //Force triggering vue reactivity
      this.profileSettings[`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_badgeFilters`] = filter;
      this.updateViewProfile();
      this.previousVScrollPosition = null
      this.gridApi.refreshServerSide({ purge: true })
    }
    , onBadgeFilterDropdownHide(bvEvent) {
      if (this.badgeFilterFocus || this.badgeFilterModalOpened != 'close') {
        bvEvent.preventDefault();
      }
    }
    , onBadgeFilterEnter() {
      if (this.$refs.badgeFilter) {
        this.$refs.badgeFilter.visible = true;
      }
    }
    , onBadgeFilterOver(evt) {
      if (this.$refs.badgeFilter?.$el.id != null && evt.target.closest('.dropdown-toggle') != null && this.badgeFilterModalOpened != 'open' && this.badgeFilterFocus) {
        const id = evt.target.closest('.dropdown-toggle').id;
        if (id != null && id.startsWith(this.$refs.badgeFilter?.$el.id)) {
          this.badgeFilterFocus = false; 
        }
      }
    }
    , onBadgeFilterLeave() {
      if (!this.badgeFilterFocus) {
        this.$refs.badgeFilter.visible = false;
      }
    }
    , onBadgeFilterModalOpened() {
      this.badgeFilterModalOpened = 'open';
      this.badgeFilterFocus = true;
    }
    , onBadgeFilterModalClosed() {
      this.badgeFilterModalOpened = 'signaled-close';
      this.badgeFilterFieldValues = {};
    }
    , toggleBadgeFilterFocus(evt) {
      if (this.badgeFilterModalOpened == 'signaled-close') {
        this.badgeFilterModalOpened = 'close';
      } else if (this.badgeFilterFocus && this.badgeFilterModalOpened == 'close' && (this.$refs.badgeFilter?.$el?.id == null || evt.target.closest(`#${this.$refs.badgeFilter.$el.id}`) == null)) {
        this.badgeFilterFocus = false;
      } else if (!this.badgeFilterFocus && this.$refs.badgeFilter?.$el?.id != null && evt.target.closest(`#${this.$refs.badgeFilter.$el.id}`) != null) {
        this.badgeFilterFocus = true;
      }
    }
    , handleBadgeFilterEscapeKeyDown(e) {
      const evt = e || window.event;
      if (evt.keyCode === 27 && this.badgeFilterFocus) {
        this.badgeFilterFocus = false;
        this.badgeFilterModalOpened = 'close';
        this.closePriorityNavDropdown = true;
      }
    }
    , onPriorityNavMouseOverOrTouchEnd(evt) {
      if ((this.$refs.badgeFilter?.$el.id == null || evt.target.closest(`#${this.$refs.badgeFilter.$el.id}`) == null)) {
        this.badgeFilterFocus = false;
      }
    }
    , onBadgeFilterFetchOptions: debounce(async function(payload) {
      if (this.badgeFilterOptionFetchFunc != null) {
        const values = await this.badgeFilterOptionFetchFunc(payload.field).then(data => {
          return data;
        })
        .catch(e => {
          console.error(e) //eslint-disable-line no-console
          return [];
        });
        
        this.$set(this.badgeFilterFieldValues, payload.field, values);
      } else {
        this.badgeFilterFieldValues[payload.field] = []
      }
    }, 100)
    , onViewOver() {
      this.$refs.view.visible = true;
    }
    , onViewLeave(arg) {
      if (typeof arg?.toElement?.className === 'string' &&
          arg.toElement.className !== 'arrow' &&
          arg.toElement.className && !arg.toElement.className.startsWith('popover')) {
        this.$refs.view.visible = false;
      }
    }
    , toggleCurrentColumnVisibility(toHide) {
      const columnDefs = this.gridApi.getColumnDefs();
      for (const cDef of columnDefs) {
        if (cDef.colId == 'rowSelector' || cDef.colId == 'uuId') {
          cDef.hide = false; //rowSelector is always visible.
          continue;
        }
        cDef.hide = toHide;
      }

      //Rearrange avatarRef column order to expected position if it is not.
      const avatarColIndex = columnDefs.findIndex(i => i.field == 'avatarRef' || i.colId == 'avatarRef');
      if (avatarColIndex != -1) {
        if (this.nonAdmin && avatarColIndex != 0) {
          const tmpCol = columnDefs.splice(avatarColIndex, 1)[0];
          columnDefs.splice(0, 0, tmpCol);
        } else if (!this.nonAdmin && avatarColIndex != 1) {
          const tmpCol = columnDefs.splice(avatarColIndex, 1)[0];
          columnDefs.splice(1, 0, tmpCol);
        }
      }
      
      this.gridApi.setGridOption('columnDefs', columnDefs);
    }
    , showAllColumns() {
      this.toggleCurrentColumnVisibility(false);
    }
    , showNoColumns() {
      this.toggleCurrentColumnVisibility(true);
    }
    , savePreset() {
      this.saveName = null;
      this.saveIndex = -1;
      this.saveProfile = { 
        name: this.saveName, 
        type: `${this.entity}_admin_selector`, 
        sharingMembers: cloneDeep(this.userId),
        editingPermissions: cloneDeep(this.userId),
        columns: this.profileSettings[this.profileKeySelector],
        coloring: this.coloring,
        filterText: cloneDeep(this.filterText),
        badgeFilters: JSON.parse(JSON.stringify(this.badgeFilters)),
        filterPinned: this.filterPinned,
        badgeFilterPinned: this.badgeFilterPinned
      };
      this.promptSaveShow = true;
    }
    , async updateUsers(profile, updateUsers, service) {
      if (updateUsers) {
        const users = updateUsers.split(',');
        for (const user of users) {
          const profileData = await service.list(user)
           .then(response => {
             return response.data[response.data.jobCase];
          });
         
          if (profileData.length > 0) {
            profileData[0][this.profileKeySelector] = profile.columns;
            profileData[0][this.profileKeyColoring] = profile.coloring;
            profileData[0][`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_search`] = profile.filterText;
            profileData[0][`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_badgeFilters`] = profile.badgeFilters;
            profileData[0][`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_filterPinned`] = profile.filterPinned;
            profileData[0][`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_badgeFilterPinned`] = profile.badgeFilterPinned;

            // save the view name in the profile
            profileData[0][`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_view`] = profile.name;
            
            await service.update(profileData, user)
          }
        }
      }
    }
    , confirmSaveOk({ /**name,*/ profile, newDefault, updateUsers, sharing }) {      
      if (newDefault) {
        // find the existing default view and turn it off
        const defaultView = this.views.find(v => v.defaultView);
        if (defaultView) {
          defaultView.defaultView = false;
          viewProfileService.updatePreset([defaultView], this.userId)
          .catch((e) => {
            console.error(e); // eslint-disable-line no-console
          });
        }
      }
      
      this.updateUsers(profile, updateUsers, viewProfileService);
      
      if (this.saveIndex !== -1) {
        this.views.splice(this.saveIndex, 1, profile);
      }
      else {
        this.addViews([profile]);
      }
      
      if (!sharing) {
        // save the view name in the profile
        this.profileSettings[`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_view`] = this.viewName = profile.name;
        this.updateViewProfile({ clearViewName: false });
      }
    }
    , addViews(views) {
      for (const view of views) {
        // if not in the list, add it
        if (view.type === `${this.entity}_admin_selector` &&
            this.views.findIndex((i) => i.uuId === view.uuId) === -1) {
          this.showInfo.push(false);
          this.views.push(view);
          if (this.useDefault && view.defaultView) {
            this.loadViewSettings(view);
          }
          else {
            let params = new URL(document.location.toString()).searchParams;
            if (params.has("view")) {
              let uuId = params.get("view");
              if (view.uuId === uuId) {
                this.loadViewSettings(view);
              }
            }
          }
        }
      }
      
      this.views.sort(function( a, b ) {
        if ( a.name.toLowerCase() < b.name.toLowerCase() ){
          return -1;
        }
        if ( a.name.toLowerCase() > b.name.toLowerCase() ){
          return 1;
        }
        return 0;
      });
    }
    , editPermission(view) {
      if (typeof view.editingPermissions === 'undefined') {
        return true;    
      }
      
      return view.editingPermissions.includes(this.userId);
    }
    , loadViewSettings(view) {
      this.profileSettings[this.profileKeySelector] = JSON.parse(JSON.stringify(view.columns));
      this.loadColumnSettings(this, this.profileSettings[this.profileKeySelector])

      const coloringChanged = JSON.stringify(view.coloring) !== JSON.stringify(this.coloring)
      if (typeof this.entityService.getColorMenuOptions === 'function') {
        const colorMenuOptions = this.entityService.getColorMenuOptions()
        for (const optName in colorMenuOptions) {
          this.$set(this.coloring, optName, view.coloring? view.coloring[optName] : colorMenuOptions[optName])
        }
        this.profileSettings[this.profileKeyColoring] = this.coloring;
      }
      this.filterText = typeof view.filterText !== 'undefined' ? view.filterText : '';
      this.profileSettings[`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_search`] = this.filterText;
      
      this.badgeFilters = Array.isArray(view.badgeFilters)? JSON.parse(JSON.stringify(view.badgeFilters)) : [];
      this.profileSettings[`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_badgeFilters`] = JSON.parse(JSON.stringify(this.badgeFilters));

      this.filterPinned = typeof view.filterPinned !== 'undefined' ? view.filterPinned : false;
      this.profileSettings[`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_filterPinned`] = this.filterPinned;
      this.badgeFilterPinned = typeof view.badgeFilterPinned !== 'undefined' ? view.badgeFilterPinned : false;
      this.profileSettings[`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_badgeFilterPinned`] = this.badgeFilterPinned;

      if (this.entity == 'RESOURCE' && this.nonAdmin) {
        this.bookings = typeof view.bookings !== 'undefined'? view.bookings : false;
        this.profileSettings['resource_selector_bookings'] = this.bookings;
      }
      this.previousVScrollPosition = null
      this.gridApi.refreshServerSide({ purge: true })

      // save the view name in the profile
      this.profileSettings[`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_view`] = this.viewName = view.name;
      
      // Save the new layout after applying it
      this.updateViewProfile({ clearViewName: false });

      if (coloringChanged) {
        this.gridApi.redrawRows();
      }
    }
    , async loadUserProfile() {
      const self = this;
      const views = await this.$store.dispatch('data/presetviewProfileList', self.userId).then((value) => {
        return value;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
      
      this.addViews(views);
    }
    , async loadPublicProfile() {
      if (!localStorage.companyId) {
        const data = await companyService.list({limit: -1, start: 0}).then((response) => {
          return response.data;
        })
        .catch((e) => {
          console.error(e); // eslint-disable-line no-console
          return null;
        });

        if (data != null) {
          const company = data.filter(d => d.type === 'Primary');
          if (company.length > 0) {
            localStorage.companyId = company[0].uuId;
          }
        }
      }

      const views = await this.$store.dispatch('data/viewProfileListPublic', localStorage.companyId).then((value) => {
        return value;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
      
      this.addViews(views);
    }
    , copyColumnSettings(name , profile) {
      const columns = profile.columns;
      this.saveName = `${name} ${this.$t('dataview.copy_text')}`;
      this.saveProfile = { 
        name: `${name} ${this.$t('dataview.copy_text')}`,
        uuId: null,
        type: `${this.entity}_admin_selector`,
        sharedVisibility: 'private',
        sharingMembers: cloneDeep(this.userId),
        editingPermissions: cloneDeep(this.userId),
        columns: columns,
        filterText: cloneDeep(profile.filterText),
        badgeFilters: cloneDeep(profile.badgeFilters),
        coloring: cloneDeep(profile.coloring),
        filterPinned: profile.filterPinned,
        badgeFilterPinned: profile.badgeFilterPinned
      };

      if (this.nonAdmin && this.entity == 'RESOURCE') {
        this.saveProfile.bookings = profile.bookings;
      }
      this.saveIndex = -1;
      this.promptSaveShow = true;
    }
    , shareColumnSettings(index, name, profile) {
      this.saveName = name;
      this.saveProfile = profile;
      this.saveIndex = index;
      this.promptShareShow = true;
    }
    , updateColumnSettings(index, name, profile) {
      this.saveName = name;
      this.saveProfile = { 
        name: this.saveName, 
        uuId: profile.uuId, 
        type: `${this.entity}_admin_selector`, 
        defaultView: profile.defaultView,
        sharedVisibility: cloneDeep(profile.sharedVisibility),
        sharingMembers: cloneDeep(profile.sharingMembers),
        editingPermissions: cloneDeep(profile.editingPermissions),
        columns: this.profileSettings[this.profileKeySelector],
        coloring: this.coloring,
        filterText: cloneDeep(this.filterText),
        badgeFilters: cloneDeep(this.badgeFilters),
        filterPinned: this.filterPinned,
        badgeFilterPinned: this.badgeFilterPinned
      };
      if (this.nonAdmin && this.entity == 'RESOURCE') {
        this.saveProfile.bookings = this.bookings;
      }
      this.saveIndex = index;
      this.promptSaveShow = true;
    }
    , removeColumnSettings(index) {
      this.confirmDeleteViewShow = true;
      this.deleteViewIndex = index;
    }
    , confirmDeleteViewOk() {
      const toRemove = this.views.splice(this.deleteViewIndex, 1);
      viewProfileService.remove([{ uuId: toRemove[0].uuId }], this.userId)
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    }
    , onInfoOver(index) {
      profileService.nodeList(this.views[index].uuId).then((response) => {
        this.views[index].owner = response.data.resultList.filter(v => this.views[index].editingPermissions.includes(v.uuId)).map(r => { return r.name }).join(", ");
        this.$set(this.showInfo, index, true);
      });
    }
    , onInfoLeave(index) {
      this.$set(this.showInfo, index, false);
    }
    , ok() {
      const details = typeof this.entityService.prepareDataForOkEmit == 'function'
        ? this.entityService.prepareDataForOkEmit(this.gridApi.getSelectedNodes())
        : this.gridApi.getSelectedNodes().map(i => ({ uuId: i.data.uuId, name: i.data.name }));

      const ids = details.map(i => i.uuId);
      this.$emit('ok', { ids, details: details, preselected: this.preselected });
    }
    , async onBookings() {
      this.profileSettings['resource_selector_bookings'] = this.bookings;
      this.updateViewProfile();
      
      // reload all lists
      this.gridApi.refreshServerSide({ purge: true });
    }
    , getCompanyRule(entityCompanySelector) {
      if (entityCompanySelector != null && this.$store.state.company && this.$store.state.company.type !== 'Primary' &&
          this.$store.state.company.filterIds) {
        const companies = this.$store.state.company.filterIds;
        const companyRule = [entityCompanySelector, 'within', companies.join('|')];
        return companyRule;
      }
      return null;
    }
    , getDurationConversionOpts() {
      return this.$store.dispatch('data/configSchedule').then(value => {
        this.durationConversionOpts = extractDurationConversionOpts(value);
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    }
    , isEllipsisActive(text) {
      return isEllipsisActive(text, this);
    }
    , onPinBadgeFilter() {
      this.badgeFilterPinned = true;
      this.profileSettings[`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_badgeFilterPinned`] = this.badgeFilterPinned
      this.updateViewProfile();
    }
    , onUnPinBadgeFilter() {
      this.forcePriorityNavRefresh = true;
      this.badgeFilterPinned = false;
      this.profileSettings[`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_badgeFilterPinned`] = this.badgeFilterPinned
      this.updateViewProfile();
    }
    , onPinFilter() {
      this.filterPinned = true;
      this.profileSettings[`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_filterPinned`] = this.filterPinned
      this.updateViewProfile();
    }
    , onUnPinFilter() {
      this.forcePriorityNavRefresh = true;
      this.filterPinned = false;
      this.profileSettings[`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_filterPinned`] = this.filterPinned
      this.updateViewProfile();
    }
    , moveAvatarToFront() {
      if (this.gridApi != null && !this.gridApi.isDestroyed() && this.columnDefs.find(i => i.field == 'avatarRef') != null) {
        this.gridApi.setGridOption('suppressColumnMoveAnimation', true);
        this.gridApi.moveColumns(['avatarRef'], this.nonAdmin? 0 : 1);
        this.gridApi.setGridOption('suppressColumnMoveAnimation', false);
      }
    }
    , enforcePinnedColumnOrders(api) {
      if (api == null || api.isDestroyed()) {
        return;
      }
      const columnState = api.getColumnState();
          
      let needUpdate = false;
      const rowSelectorIndex = columnState.findIndex(i => i.colId == 'rowSelector');
      if (rowSelectorIndex > -1) {
        if (rowSelectorIndex != 0) {
          columnState.splice(0, 0, columnState.splice(rowSelectorIndex, 1)[0]);
          needUpdate = true;
        }
      }

      const avatarRefIndex = columnState.findIndex(i => i.colId == 'avatarRef');
      if (avatarRefIndex > -1) {
        const expectedPosition = rowSelectorIndex == -1? 0 : 1;
        if (avatarRefIndex != expectedPosition) {
          columnState.splice(expectedPosition, 0, columnState.splice(avatarRefIndex, 1)[0]);
          needUpdate = true;
        }
      }

      const mainColIndex = columnState.findIndex(i => i.colId == 'uuId');
      if (mainColIndex > -1) {
        let expectedPosition = 2;
        if (rowSelectorIndex == -1) {
          expectedPosition--;
        }
        if (avatarRefIndex == -1) {
          expectedPosition--;
        }
        if (mainColIndex != expectedPosition) {
          columnState.splice(expectedPosition, 0, columnState.splice(mainColIndex, 1)[0]);
          needUpdate = true;
        }
      }
      
      if (needUpdate) {
        api.applyColumnState({
          state: columnState,
          applyOrder: true
        })
      }
    }
  }
}


</script>

<style lang="scss">
.spreadsheet .ag-row-selected::before,
.spreadsheet .ag-row-selected.ag-row-hover::before {
  background-color: transparent;
}

.spreadsheet .ag-cell-range-selected.ag-cell-focus, .spreadsheet .ag-body-viewport.ag-has-focus .ag-cell-range-single-cell:not(.ag-cell-inline-editing) {
  background-color: var(--ag-range-selection-background-color);
  border: 2px solid  !important;
  border-color: var(--ag-range-selection-border-color) !important; 
  border-style: var(--ag-range-selection-border-style) !important;
}

.bookings-switch {
  cursor: pointer;
  width: 100%;
  text-align: right;
  margin-bottom: 10px;
}
</style>